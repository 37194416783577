import React, { Component, Fragment } from "react";
import { Form } from "react-bootstrap";
import moment from "moment";
import { isLoggedIn } from "../../../../utils/BeeApi";

class EntityComment extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    let { comment } = props;
    this.state = {
      id: comment.id || Date.now(),
      username: comment.username || isLoggedIn().email,
      text: comment.text,
      createdAt: comment.createdAt || moment().toISOString(),
      updatedAt: comment.createdAt || moment().toISOString(),
      read: comment.read || false
    };

    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  toggleEdit() {
    this.setState({ editable: !this.state.editable });
  }

  toggleRead() {
    this.setState({ read: !this.state.read }, () => {
      const { id, username, text, createdAt, updatedAt, read } = this.state;
      this.props.onCommentUpdated({
        id,
        username,
        text,
        createdAt,
        updatedAt,
        read
      });
    });
  }

  handleSubmit() {
    const { id, username, text, read, createdAt } = this.state;
    let updatedAt = moment().toISOString();

    if (!text) this.props.onCommentDeleted(this.props.comment);
    else
      this.props.onCommentUpdated({
        id,
        username,
        text,
        read,
        createdAt,
        updatedAt
      });

    if (this.props.newComment)
      this.setState({
        id: Date.now(),
        username: isLoggedIn().email,
        text: "",
        read: false,
        createdAt: moment().toISOString(),
        updatedAt: moment().toISOString()
      });

    this.toggleEdit();
  }

  handleDelete() {
    this.props.onCommentDeleted(this.props.comment);
  }

  render() {
    const { id, username, text, read, updatedAt, editable } = this.state;

    if (this.props.newComment) {
      return (
        <div className="mt-3 mb-3">
          <Form.Group>
            <Form.Control
              as="textarea"
              rows="3"
              name="text"
              value={text}
              onChange={this.handleInputChange}
              placeholder={"Leave a comment..."}
            />
            <button
              className="btn btn-xs btn-primary mt-2"
              onClick={() => this.handleSubmit()}
            >
              Comment
            </button>
          </Form.Group>
        </div>
      );
    }
    return (
      <blockquote className={read ? "comment-read" : "comment-unread"}>
        <h5>
          <strong>{username}</strong>{" "}
          <span className="float-right">{moment(updatedAt).format("lll")}</span>
        </h5>
        <hr className="mt-1 mb-1" />
        {editable ? (
          <Fragment>
            <Form.Group>
              <Form.Control
                as="textarea"
                rows="3"
                name="text"
                value={text}
                onChange={this.handleInputChange}
                placeholder={"Leave a comment..."}
              />
            </Form.Group>{" "}
            <p className="text-right">
              <button
                className="btn btn-xs btn-link"
                onClick={() => this.toggleEdit()}
              >
                Cancel
              </button>{" "}
              <button
                className="btn btn-xs btn-primary"
                onClick={() => this.handleSubmit()}
              >
                Comment
              </button>
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <p>{text}</p>
            <div>
              <button
                className="btn btn-xs btn-outline-primary ml-1 float-right"
                onClick={() => this.toggleEdit()}
              >
                Edit
              </button>{" "}
              <button
                className="btn btn-xs btn-outline-secondary ml-1 float-right"
                onClick={() => this.handleDelete()}
              >
                Delete
              </button>{" "}
              <Form.Check
                inline
                id={`read-unread-${id}`}
                type="switch"
                name="read"
                checked={!read}
                onChange={() => this.toggleRead()}
                label={<small>Open</small>}
              />{" "}
            </div>
          </Fragment>
        )}
      </blockquote>
    );
  }
}

export default EntityComment;
