import { ContentState, convertToRaw, convertFromHTML } from "draft-js";
import { convertFromHTML as hsConvertFromHTML } from "draft-convert";
import ReactHtmlParser, { processNodes } from "react-html-parser";

import ReactDOMServer from "react-dom/server";

const ConverstionUtils = {
  htmlToContentState(html) {
    const blocksFromHTML = convertFromHTML(html);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return contentState;
  },

  htmlToContentStateHS(html) {
    let contentState = hsConvertFromHTML({
      htmlToStyle: (nodeName, node, currentStyle) => {
        return currentStyle;
      },
      htmlToBlock: (nodeName, node) => {
        if (!node.textContent.trim()) return false;
      }
    })(html, { flat: true });

    let blocks = [];
    contentState.getBlocksAsArray().forEach((block) => {
      if (block.getText().trim()) {
        blocks.push(block);
      }
    });

    return ContentState.createFromBlockArray(blocks);
  },

  htmlToContentStateHS2(html) {
    function transform(node, index) {
      if (node.name === "body" || node.name === "center") {
        return processNodes(node.children, transform);
      }

      if (
        node.name === "div" &&
        [
          "div",
          "p",
          "hr",
          "table",
          "h1",
          "h2",
          "h3",
          "h4",
          "h5",
          "h6",
          undefined
        ].includes(node.children[0]?.name)
      ) {
        return processNodes(node.children, transform);
      }

      // if (node.parent?.name === "td" && node.children) {
      //   return processNodes(node.children, transform);
      // }

      if (
        node.parent?.name === "table" &&
        !["tbody", "thead", "tfoot", "tr", "td", "th", undefined].includes(
          node.children[0]?.name
        )
      ) {
        return processNodes(node.children, transform);
      }
    }

    const parserOptions = { decodeEntities: true, transform };

    const hsOptions = {
      htmlToStyle: (nodeName, node, currentStyle) => {
        return currentStyle;
      },
      htmlToBlock: (nodeName, node) => {
        // Spliting blocks using rich utils.
        const ALIGNMENT_DATA_KEY = "textAlignment";
        const ALIGNMENT_MAP = {
          left: "LEFT",
          center: "CENTER",
          right: "RIGHT",
          justify: "JUSTIFY"
        };

        var alignment =
          node?.style?.textAlign ||
          node?.align ||
          node?.alignContent ||
          node?.alignSelf ||
          "";

        let data = {};
        if (alignment === "left") {
          data = { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["left"] };
        } else if (alignment === "right") {
          data = { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["right"] };
        } else if (alignment === "center") {
          data = { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["center"] };
        } else if (alignment === "justify") {
          data = { [ALIGNMENT_DATA_KEY]: ALIGNMENT_MAP["justify"] };
        }

        if (nodeName === "h1") return { type: "header-one", data: data };
        if (nodeName === "h2") return { type: "header-two", data: data };
        if (nodeName === "h3") return { type: "header-three", data: data };
        if (nodeName === "h4") return { type: "header-four", data: data };
        if (nodeName === "h5") return { type: "header-five", data: data };
        if (nodeName === "h6") return { type: "header-six", data: data };
        if (
          ["table", "thead", "tbody", "tfoot", "th", "tr", "td"].includes(
            nodeName
          )
        )
          return { type: "TABLE", data: { ...data, ...{ node: node } } };
        if (nodeName === "hr")
          return {
            type: "HORIZONTAL_RULE",
            data: { ...data, ...{ node: node } }
          };

        return { data: data };
      },
      htmlToEntity: (nodeName, node, createEntity) => {
        if (nodeName === "table") {
          return createEntity("TABLE", "IMMUTABLE", { node: node.outerHTML });
        }
      }
    };

    var htmlBody = html.match(/<body[^>]*>([\s\S])*<\/body>/gi)[0] || "";
    var htmlString = ReactDOMServer.renderToString(
      ReactHtmlParser(htmlBody, parserOptions)
    );
    return hsConvertFromHTML(hsOptions)(htmlString);
  },

  convertToStructured(html) {
    let contentState = this.htmlToContentStateHS2(html);
    return contentState.hasText() ? convertToRaw(contentState) : null;
  }
};

export default ConverstionUtils;
