import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Route, Switch, Link } from "react-router-dom";
import moment from "moment";
import { Button, Badge, Nav, NavDropdown } from "react-bootstrap";

import { get } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import DealMain from "../DealMain/DealMain";
import DealEdit from "../../Deals/DealEdit/DealEdit";
import TrancheCreate from "../Tranches/TrancheCreate/TrancheCreate";
import Lenders from "../Tranches/Lenders/Lenders";
import CovenantsAmendmentVoting from "../CovenantsAmendmentVoting/CovenantsAmendmentVoting";
import FinancialCovenants from "../FinancialCovenants/FinancialCovenants";
import Tranche from "../Tranches/Tranche";

class PackagingDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleDealUpdated = this._handleDealUpdated.bind(this);
    this.onTranceCreated = this._onTranceCreated.bind(this);
  }

  componentDidMount() {
    this.fetchDeal();
    this.fetchTranches();
    this.fetchTypes();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleDealUpdated() {
    this.fetchDeal();
  }

  fetchTypes() {
    this.setState({
      sync: true,
      error: ""
    });

    const createTrancheTypePromise = get("/tranche-types", {});
    createTrancheTypePromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          error: "",
          types: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createTrancheTypePromise);
  }

  _onTranceCreated(tranche = {}) {
    const { tranches } = this.state;
    let mutableList = [...tranches];

    mutableList.push(tranche);

    this.setState({
      tranches: mutableList
    });
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;
    const createDealPromise = get(`/deals/${dealId}`, {});
    createDealPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createDealPromise);
  }

  fetchTranches() {
    this.setState({
      isLoading: true,
      error: ""
    });
    const { dealId } = this.props;

    const createTranchePromise = get(`deals/${dealId}/tranches`, {});
    createTranchePromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          tranches: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createTranchePromise);
  }

  render() {
    const { deal, isLoading, error, tranches, types } = this.state;

    let location = window.location.pathname;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;
    const company = deal.company;

    return (
      <Fragment>
        <div className="mt-2 mb-2">
          <ul className="list-inline">
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/`}>Dashboard</Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">Deal Packaging</h4>
            </li>
          </ul>
        </div>

        <hr />
        <div className="mt-3 mb-3">
          <p className="float-right">
            {deal.stationStatus.packaging ? (
              <Badge variant="success">In progress</Badge>
            ) : (
                <Badge variant="dark">Not started</Badge>
              )}{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/extraction/${deal.id}`}
            >
              Extraction
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/intelligence/${deal.id}`}
            >
              Intelligence
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/packaging/${deal.id}`}
              className="disabled"
            >
              Packaging
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/delivery/${deal.id}`}
            >
              Delivery
            </Button>{" "}
            <DealEdit deal={deal} onDealEdited={this.handleDealUpdated} />
          </p>
          <h2>
            Deal signing date as of {moment(deal.signingDate).format("LL")}
          </h2>
          <p>
            Parent Borrower:{" "}
            <Link to={`/companies/${company.id}`}>{company.companyName}</Link>
          </p>
          <p className="text-muted">
            Created at <span>{moment(deal.createdAt).format("LLL")}</span>
          </p>
        </div>
        <hr />
        <div className="mt-3 mb-3">
          <h3>Deal Packaging</h3>
          <p>
            Select a section to prepare entry into corresponding BLUES section
          </p>
          <span className="float-right">
            <TrancheCreate
              onTranceCreated={this.onTranceCreated}
              deal={deal}
              types={types}
            />
          </span>
          <Nav className="mr-auto">
            <NavDropdown
              title="Deal Level Information"
              id="nav-dropdown"
              active={location === `/packaging/${deal.id}`}
            >
              <NavDropdown.Item
                as={Link}
                to={`/packaging/${deal.id}`}
                eventKey={deal.id}
                active={location === `/packaging/${deal.id}`}
              >
                Deal Main Page
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`/packaging/${deal.id}/financial-covenants`}
                eventKey={`${deal.id}/financial-covenants/`}
                disabled
              >
                Financial Covenants
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={`/packaging/${deal.id}/covenants-amendment-voting`}
                eventKey={`${deal.id}/covenants-amendment-voting`}
                disabled
              >
                Covenants and Amendment Voting
              </NavDropdown.Item>
            </NavDropdown>
            {tranches &&
              tranches.map((tranche) => {
                return (
                  <NavDropdown
                    key={tranche.id}
                    title={`${tranche.type.name}`}
                    id="nav-dropdown"
                    active={
                      location ===
                      `/packaging/${deal.id}/tranches/${tranche.id}` ||
                      location ===
                      `/packaging/${deal.id}/tranches/${tranche.id}/lenders`
                    }
                  >
                    <NavDropdown.Item
                      as={Link}
                      to={`/packaging/${deal.id}/tranches/${tranche.id}`}
                      eventKey={tranche.id}
                    >
                      Tranche Main Page
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      to={`/packaging/${deal.id}/tranches/${
                        tranche.id
                        }/lenders`}
                      eventKey={`${tranche.id}/lenders`}
                    >
                      Tranche Bank List
                    </NavDropdown.Item>
                    <NavDropdown.Item disabled>
                      Tranche Participants and Segments
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item disabled>
                      Interest and Fees
                    </NavDropdown.Item>
                    <NavDropdown.Item disabled>
                      Perfomance and Pricing
                    </NavDropdown.Item>
                    <NavDropdown.Item disabled>
                      Tranche Options/BBase/Repays
                    </NavDropdown.Item>
                    <NavDropdown.Item disabled>Asia Data</NavDropdown.Item>
                    <NavDropdown.Item disabled>
                      Tranche Ratings
                    </NavDropdown.Item>
                  </NavDropdown>
                );
              })}
          </Nav>
        </div>
        <div className="mt-3 mb-3">
          <Switch>
            <Route
              exact
              path="/packaging/:dealId/financial-covenants"
              render={(props) => (
                <FinancialCovenants dealId={props.match.params.dealId} />
              )}
            />
            <Route
              exact
              path="/packaging/:dealId/covenants-amendment-voting"
              render={(props) => (
                <CovenantsAmendmentVoting dealId={props.match.params.dealId} />
              )}
            />
            <Route
              exact
              path="/packaging/:dealId/tranches/:trancheId"
              render={(props) => (
                <Tranche
                  deal={deal}
                  dealId={props.match.params.dealId}
                  types={types}
                  trancheId={props.match.params.trancheId}
                />
              )}
            />
            <Route
              exact
              path="/packaging/:dealId/tranches/:trancheId/lenders"
              render={(props) => (
                <Lenders
                  deal={deal}
                  dealId={props.match.params.dealId}
                  trancheId={props.match.params.trancheId}
                />
              )}
            />
            <Route render={() => <DealMain dealId={deal.id} deal={deal} />} />
          </Switch>
        </div>
      </Fragment>
    );
  }
}

PackagingDashboard.propTypes = {
  dealId: PropTypes.string.isRequired
};

export default PackagingDashboard;
