import React, { Component, Fragment } from "react";
import { Alert, Row, Col, Card, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      data: "",
      isLoading: false,
      searchData: []
    };
    this.subscribedPromises = [];
    this.handleSearchText = this._handleSearchText.bind(this);
  }

  parseURLParams(params) {
    return new URLSearchParams(params || "");
  }

  componentDidMount() {
    let searchText = this.parseURLParams(this.props.location.search).get("q");
    this.fetchDeal();
    this.setState({ searchText }, () => {
      this.fetchSearch(searchText);
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleSearchText(event) {
    let searchText = event.target.value;
    this.setState({
      searchText,
      searchTextValid: searchText.length > 2 ? true : false
    });
  }

  _handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const { searchText } = this.state;
      if (searchText.length > 2) {
        this.fetchSearch(this.state.searchText);
      }
    }
  };

  fetchSearch(search = null) {
    this.setState({ sync: true, error: "", isSearching: true, searchData: [] });
    const createBankPromise = get("/search/document-structured", {
      params: { q: search, dealId: this.props.dealId }
    });
    createBankPromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          isSearching: false,
          error: "",
          searchData: response.data
        });
      })
      .catch((error) => {
        this.setState({ error: error, sync: false });
      });
    this.subscribedPromises.push(createBankPromise);
  }

  fetchDeal() {
    this.setState({
      isLoading: true,
      error: ""
    });
    const { dealId } = this.props;
    const createDealsPromise = get(`/deals/${dealId}`, {});
    createDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoading: false
        });
      });

    this.subscribedPromises.push(createDealsPromise);
  }

  renderSearchData() {
    const { deal, searchData, isSearching } = this.state;

    if (isSearching) return <Loader />;

    return (
      <Fragment>
        {deal.exhibit.map((exhibit) => {
          return (
            <Fragment key={exhibit.id}>
              <h4>{exhibit.exhibitName}</h4>
              <ul>
                {searchData
                  .filter((result) => result.exhibitId === exhibit.id)
                  .map((result) => {
                    return <li key={result.key}>{result.text}</li>;
                  })}
              </ul>
              <hr />
            </Fragment>
          );
        })}

        {false ? (
          searchData.map((data, ind) => {
            return (
              <div className="highlight" key={ind}>
                <h4>Exhibit: {data.result.exhibitName}</h4>
                {data.highlight.length ? (
                  <ol>
                    {data.highlight.map((highlight, key) => {
                      return (
                        <li className="pb-3" key={key}>
                          <div
                            dangerouslySetInnerHTML={{ __html: highlight }}
                          />
                        </li>
                      );
                    })}
                  </ol>
                ) : (
                  <p>No results found in exhibit</p>
                )}
              </div>
            );
          })
        ) : (
          <Alert variant="info" className="mt-md mb-md">
            <p>Search returned with no results.</p>
          </Alert>
        )}
      </Fragment>
    );
  }

  render() {
    const { isLoading, error, deal } = this.state;

    if (isLoading) return <Loader />;

    if (error) return <ErrorHandler error={error} />;

    if (!deal) return <span />;
    const company = deal.company;

    return (
      <Row>
        <Col xs={12} className="mt-2 mb-2">
          <ul className="list-inline">
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/`}>Dashboard</Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/intelligence/${deal.id}`}>
                  Deal signing date as of{" "}
                  {moment(deal.signingDate).format("LL")}{" "}
                </Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">Search</h4>
            </li>
          </ul>
          <hr />
          <div className="mt-3 mb-3">
            <h2>Intelligence Search</h2>
          </div>
          <Row>
            <Form.Group as={Col} md="12">
              <Form.Control
                onKeyDown={this._handleKeyDown}
                defaultValue={this.state.searchText}
                placeholder="Search by keywords"
                onChange={this.handleSearchText}
              />
            </Form.Group>
          </Row>
          <Card className="mt-2">
            <Card.Header>Results from exhibit(s):</Card.Header>
            <Card.Body>{this.renderSearchData()}</Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }
}

export default Search;
