import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Companies.css";

import Header from "../App/Header/Header";
import CompaniesDashboard from "./CompaniesDashboard/CompaniesDashboard";
import CompanyDetails from "./CompanyDetails/CompanyDetails";

class CompaniesMain extends Component {
  render() {
    return (
      <div className="Main">
        <Header user={this.props.user} title={"Company"} />
        <div className="MainContent">
          <Container>
            <Switch>
              <Route
                exact
                path="/companies"
                render={(props) => <CompaniesDashboard />}
              />
              <Route
                exact
                path="/companies/:companyId"
                render={(props) => (
                  <CompanyDetails companyId={props.match.params.companyId} />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 className="text-center mt-xl mb-xl">
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default CompaniesMain;
