import React, { Component, Fragment } from "react";
import moment from "moment";
import { Alert, Form, Table } from "react-bootstrap";
import { Link } from "react-router-dom";

import { get } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import DealCreate from "../DealCreate/DealCreate";
import DealEdit from "../DealEdit/DealEdit";
import DealDelete from "../DealDelete/DealDelete";
import DealAssignment from "../DealAssignment/DealAssignment";

class Deals extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { query: "" };

    this.handleSearch = this._handleSearch.bind(this);

    this.handleDealCreated = this._handleDealCreated.bind(this);
    this.handleDealUpdated = this._handleDealUpdated.bind(this);
    this.handleDealDeleted = this._handleDealDeleted.bind(this);
  }

  componentDidMount() {
    this.fetchDeals();
    this.fetchCompanies();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleDealCreated(deal) {
    const { deals } = this.state;
    let mutableList = [...deals];
    mutableList.push(deal);
    this.setState({ deals: mutableList });
  }

  _handleDealUpdated(deal) {
    const { deals } = this.state;
    let mutableList = [...deals];
    let index = deals.findIndex((item) => {
      return item.id === deal.id;
    });
    mutableList[index] = deal;
    this.setState({ deals: mutableList });
  }

  _handleDealDeleted(dealId) {
    let { deals } = this.state;
    deals = deals.filter((deal) => deal.id !== dealId);
    this.setState({ deals });
  }

  fetchCompanies() {
    this.setState({ companiesIsLoading: true, companiesError: "" });
    const getCompaniesPromise = get("/companies", {});
    getCompaniesPromise.promise
      .then((response) => {
        this.setState({
          companiesIsLoading: false,
          companiesError: "",
          companies: response.data,
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ companiesError: error, companiesIsLoading: false });
      });
    this.subscribedPromises.push(getCompaniesPromise);
  }

  fetchDeals() {
    this.setState({ isLoading: true, error: "", searchResults: null });
    const getDealsPromise = get("/deals", { params: { limit: 1000 } });
    getDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deals: response.data,
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });
    this.subscribedPromises.push(getDealsPromise);
  }

  _handleSearch(event) {
    let query = event.target.value;
    const { deals } = this.state;
    const searchResults = deals.filter((deal) => {
      let company = deal.company;
      return company.companyName.toLowerCase().search(query.toLowerCase()) ===
        0 || deal.dealAmendId.toLowerCase().search(query.toLowerCase()) === 0
        ? deal
        : null;
    });

    this.setState({ searchResults: searchResults, query: query });
  }

  render() {
    return (
      <div className="mt-3 mb-3">
        <span className="float-right">
          <DealCreate onDealCreated={this.handleDealCreated} />
        </span>
        <h2>Deals</h2>
        {this.renderDeals()}
      </div>
    );
  }

  renderDeals() {
    const {
      query,
      deals,
      searchResults,
      isLoading,
      error,
      companies,
      companiesIsLoading,
      companiesError,
    } = this.state;

    if (isLoading || companiesIsLoading) return <Loader />;
    if (companiesError) return <ErrorHandler error={companiesError} />;
    if (error) return <ErrorHandler error={error} />;
    if (!deals || !companies) return <span />;
    if (!deals.length)
      return <Alert variant="info">There are currently no deals</Alert>;

    let filteredDeals = searchResults || deals;
    return (
      <Fragment>
        <Form.Group>
          <Form.Control
            type="text"
            name="query"
            value={query}
            onChange={this.handleSearch}
            placeholder="Filter deals by company or deal amend ID..."
          />
        </Form.Group>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Amendment ID</th>
              <th>Company</th>
              <th>Signing Date</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Assignee</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredDeals.map((deal) => {
              return (
                deal.company.companyName && (
                  <tr key={deal.id}>
                    <td>
                      <span className="text-muted">{deal.dealAmendId}</span>
                    </td>
                    <td title={deal.company.companyName}>
                      <Link to={`companies/${deal.company.id}`}>
                        {deal.company.companyName.length < 25
                          ? deal.company.companyName
                          : deal.company.companyName.substring(24, 0) + "..."}
                      </Link>
                    </td>
                    <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>
                    <td>{moment(deal.createdAt).format("MM/DD/YYYY")}</td>
                    <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
                    <td>
                      <DealAssignment deal={deal} />
                    </td>
                    <td>
                      <DealEdit
                        deal={deal}
                        onDealUpdated={this.handleDealUpdated}
                      />{" "}
                      <DealDelete
                        deal={deal}
                        onDealDeleted={this.handleDealDeleted}
                      />
                    </td>
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

Deals.propTypes = {};

export default Deals;
