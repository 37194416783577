import React, { Component } from "react";
import { Table } from "react-bootstrap";
import { get } from "../../../utils/DeApi";
import moment from "moment";
import numeral from "numeral";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import TrancheEdit from "./TrancheEdit/TrancheEdit";

class Tranche extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { deal: props.deal };

    this.onTrancheUpdated = this._onTrancheUpdated.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _onTrancheUpdated(tranche) {
    this.setState({
      trancheAmount: tranche.amount,
      typeId: tranche.type.id,
      trancheType: tranche.type.name
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    this.fetchTranch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trancheId !== prevProps.trancheId) {
      this.fetchTranch();
    }
  }

  fetchTranch() {
    this.setState({ isLoading: true, error: "" });

    const { trancheId } = this.props;
    const createTranchePromise = get(`/tranches/${trancheId}`, {});
    createTranchePromise.promise
      .then((response) => {
        this.setState({
          error: "",
          isLoading: false,
          lenders: response.data.lenders,
          dealId: response.data.deals.id,
          tranche: response.data,
          trancheType: response.data.type.name,
          typeId: response.data.type.id,
          trancheAmount: response.data.amount,
          types: this.props.types
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createTranchePromise);
  }

  fetchTypes() {
    this.setState({ isLoading: true, error: "" });

    const createTrancheTypePromise = get("/tranche-types", {});
    createTrancheTypePromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          types: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createTrancheTypePromise);
  }

  render() {
    const {
      isLoading,
      error,
      deal,
      trancheType,
      trancheAmount,
      typeId
    } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    if (!deal) return <span />;
    if (!typeId) return <span />;

    return (
      <div className="mt-3 mb-3">
        <div className="mt-3 mb-3">
          <h4>Tranche Main Page</h4>
        </div>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Tranche Type</th>
              <th>Amount</th>
              <th>Amend & Extend Flag</th>
              <th>Updated At</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{trancheType}</td>
              <td>{numeral(trancheAmount).format("0,0")}</td>
              <td>{this.state.amendEventFlag === "Y" ? "Yes" : "No"}</td>
              <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
              <td>
                <TrancheEdit
                  trancheAmount={trancheAmount}
                  trancheId={this.props.trancheId}
                  typeId={typeId}
                  deal={deal}
                  types={this.props.types}
                  onTrancheUpdated={this.onTrancheUpdated}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  }
}

export default Tranche;
