import React, { Component, Fragment } from "react";
import { Alert, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import { put, get } from "../../../../utils/DeApi";
import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { CopyToClipboard } from "react-copy-to-clipboard";

class DealSourceEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filingDateValid: true,
      sourceFormValid: true,
      secWebpageValid: true
    };
    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleFilingDateChange = this._handleFilingDateChange.bind(this);
    this.handleSourceFormChange = this._handleSourceFormChange.bind(this);
    this.handleSecWebpageChange = this._handleSecWebpageChange.bind(this);
    this.runCommentRule = this._runCommentRule.bind(this);
    this.runExhibitRule = this._runExhibitRule.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    this.setValues();
  }

  _handleSourceFormChange(event) {
    let sourceForm = event.target.value;
    this.setState(
      {
        sourceForm,
        sourceFormValid: sourceForm.length >= 2 ? true : false
      },
      () => {
        this.setRunRules();
      }
    );
  }

  _handleFilingDateChange(event) {
    let filingDate = event.target.value;

    this.setState(
      {
        filingDate,
        filingDateValid: true
      },
      () => {
        this.setRunRules();
      }
    );
  }

  _handleSecWebpageChange(event) {
    let secWebpage = event.target.value;
    this.setState(
      {
        secWebpage,
        secWebpageValid: secWebpage.length >= 2 ? true : false
      },
      () => {
        this.setRunRules();
      }
    );
  }

  submit(event) {
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { filingDate, sourceForm, secWebpage } = this.state;

        this.setState({
          error: ""
        });

        const createPromise = put(`/dealSource/${this.props.dealId}`, {
          filingDate,
          sourceForm,
          secWebpage
        });

        createPromise.promise
          .then(response => {
            this.setState(
              {
                error: ""
              },
              () => {
                event === "comment" ? this.fetchComment() : this.fetchExhibit();
              }
            );
          })
          .catch(error => {
            this.setState({
              error: error,
            });
          });

        this.subscribedPromises.push(createPromise);
      }
    );
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const {
          filingDate,
          filingDateValid,
          sourceForm,
          sourceFormValid,
          secWebpageValid,
          secWebpage
        } = this.state;

        if (filingDateValid && sourceFormValid && secWebpageValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = put(`/dealSource/${this.props.dealId}`, {
            filingDate,
            sourceForm,
            secWebpage
          });

          createPromise.promise
            .then(response => {
              this.setState(
                {
                  isLoading: false,
                  error: "",
                  showModal: false,
                },
                () => {
                  this.props.onDealSourceEdit(response.data);
                }
              );
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  setRunRules() {
    const { filingDateValid, sourceFormValid, secWebpageValid } = this.state;

    if (filingDateValid && sourceFormValid && secWebpageValid) {
      this.setState({ canRunRules: true });
    } else {
      this.setState({ canRunRules: false });
    }
  }

  _runCommentRule() {
    this.submit("comment");
  }

  _runExhibitRule() {
    this.submit("exhibit");
  }

  setValues() {
    const { dealSource } = this.props;
    if (dealSource) {
      this.setState({
        sourceForm: dealSource.sourceForm,
        secWebpage: dealSource.sec_webpage,
        filingDate: dealSource.filingDate,
        exhibitNames: dealSource.exhibit_names,
        sourceComment: dealSource.source_comment
      },
      ()=>{
        this.setRunRules();
      });
    }

  }

  fetchExhibit() {
    this.setState({
      isRunningExhibitRule: true,
      error: ""
    });
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules/exhibit`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isRunningExhibitRule: false,
          error: "",
          exhibitNames: response.data.exhibitNames
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isRunningExhibitRule: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  fetchComment() {
    this.setState({
      isRunningCommentRule: true,
      error: ""
    });
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules/comment`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isRunningCommentRule: false,
          error: "",
          sourceComment: response.data.sourceComment
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isRunningCommentRule: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  render() {
    const { isLoading } = this.state;

    return (
      <Fragment>
        <Button
          className="float-right"
          variant="outline-success"
          onClick={this.toggleModal}
          size="sm"
        >
          Update
        </Button>

        <Modal show={this.state.showModal} size="lg" onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Information Source</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderDealSourceEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderDealSourceEdit() {
    const {
      error,
      success,
      isLoading,
      sync,
      filingDate,
      filingDateValid,
      secWebpage,
      secWebpageValid,
      formSubmitted,
      sourceForm,
      sourceFormValid,
      canRunRules,
      isRunningCommentRule,
      isRunningExhibitRule
    } = this.state;

    if (sync || isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} xs="12">
          <Form.Label>Date (Filing)</Form.Label>
          <Form.Control
            type="date"
            value={filingDate}
            isInvalid={!filingDateValid && formSubmitted}
            onChange={this.handleFilingDateChange}
          />
          <Form.Control.Feedback type="invalid">
            Invalid date
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>Source Specifics (Form)</Form.Label>
          <Form.Control
            as="select"
            isInvalid={!sourceFormValid && formSubmitted}
            defaultValue={sourceForm}
            onChange={this.handleSourceFormChange}
          >
            <option value="">Select Form Type</option>
            <option value="SEC Form 10-Q">SEC Form 10-Q</option>
            <option value="SEC Form 8-K">SEC Form 8-K</option>
            <option value="SEC Form 10-K">SEC Form 10-K</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            Source Specifics form is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>SEC Link</Form.Label>
          <Form.Control
            type="text"
            isInvalid={!secWebpageValid && formSubmitted}
            defaultValue={secWebpage}
            onChange={this.handleSecWebpageChange}
          />
          <Form.Control.Feedback type="invalid">
            SEC Link is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <div>
            <span>Exhibit</span>
            <Button
              disabled={!canRunRules}
              variant="link"
              className="float-right"
              onClick={this.runExhibitRule}
            >
              {isRunningExhibitRule && (
                <Spinner animation="border" size="sm" className="mr-1" />
              )}
              Run Rules
            </Button>
            <CopyToClipboard
              text={this.state.exhibitNames}
              onCopy={() =>
                this.setState({ exhbitCopied: true, commentCopied: false })
              }
            >
              <Button size="sm" variant="link">
                <span className="material-icons md-24 text-muted ml-2">
                  file_copy
                </span>
              </Button>
            </CopyToClipboard>
            {this.state.exhbitCopied && (
              <span className="text-muted">
                <small>Copied.</small>
              </span>
            )}
          </div>
          <Form.Control disabled type="text" value={this.state.exhibitNames} />
        </Form.Group>

        <Form.Group as={Col} md="12">
          <div>
            <span>Comment (Information Source)</span>
            <Button
              disabled={!canRunRules}
              variant="link"
              className="float-right"
              onClick={this.runCommentRule}
            >
              {isRunningCommentRule && (
                <Spinner animation="border" size="sm" className="mr-1" />
              )}
              Run Rules
            </Button>
            <CopyToClipboard
              text={this.state.sourceComment}
              onCopy={() =>
                this.setState({ commentCopied: true, exhbitCopied: false })
              }
            >
              <Button size="sm" variant="link">
                <span className="material-icons md-24 text-muted ml-2">
                  file_copy
                </span>
              </Button>
            </CopyToClipboard>
            {this.state.commentCopied && (
              <span className="text-muted">
                <small>Copied.</small>
              </span>
            )}
          </div>
          <Form.Control disabled type="text" value={this.state.sourceComment} />
          <br />
          <Alert variant="info">
            <p>
              Note: This line should be ADDED to the comment field - do not
              remove any existing comment lines.
            </p>
          </Alert>
        </Form.Group>
        {error &&
          (error => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Deal Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

export default DealSourceEdit;
