import React, { Component, Fragment } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";
import { post, get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { Typeahead } from "react-bootstrap-typeahead";

class DealCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.state = {
      signingDate: new Date(),
      dealAmendId: "",
      dealType: "",
      secFilingLink: "",
      companyId: props.company ? props.company.id : "",
      companies: []
    };

    this.handleDealAmendIdChange = this._handleDealAmendIdChange.bind(this);
    this.handleSigningDateChange = this._handleSigningDateChange.bind(this);
    this.handleDealTypeChange = this._handleDealTypeChange.bind(this);
    this.handleSecFilingLinkChange = this._handleSecFilingLinkChange.bind(this);
    this.handleCompanyNameChange = this._handleCompanyNameChange.bind(this);

    this.toggleModal = this._toggleModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    if (!this.props.company) this.fetchCompanies();
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleCompanyNameChange(event) {
    if (event.length) {
      let companyId = event[0].id;
      this.setState({
        companyId: companyId,
        companyValid: !companyId
      });
    }
  }

  _handleDealAmendIdChange(event) {
    let dealAmendId = event.target.value;
    this.setState({
      dealAmendId: dealAmendId,
      dealAmendIdValid: dealAmendId.length > 2
    });
  }

  _handleSigningDateChange(event) {
    let signingDate = event.target.value;
    this.setState({
      signingDate: signingDate,
      signingDateValid: true
    });
  }

  _handleDealTypeChange(event) {
    let dealType = event.target.value;
    this.setState({ dealType: dealType });
  }

  _handleSecFilingLinkChange(event) {
    let secFilingLink = event.target.value;
    this.setState({ 
      secFilingLink: secFilingLink,
      secFilingLinkValid: true
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const {
      signingDate,
      signingDateValid,
      companyId,
      dealAmendId,
      dealAmendIdValid,
      secFilingLink,
      secFilingLinkValid,
      dealType
    } = this.state;
    this.setState({ formSubmitted: true });
    if (!signingDateValid || !dealAmendIdValid || !companyId || !secFilingLinkValid) return;

    this.setState({ error: "", isLoading: true });
    const createPromise = post("/deals", {
      companyId: companyId,
      signingDate: signingDate,
      dealAmendId: dealAmendId,
      dealType: dealType,
      secFilingLink: secFilingLink
    });

    createPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false,
            deal: response.data
          },
          () => {
            this.props.onDealCreated(response.data);
          }
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createPromise);
  }

  fetchCompanies() {
    this.setState({ isLoading: true, error: "" });
    const createCompaniesPromise = get("/companies", {});
    createCompaniesPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          companies: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createCompaniesPromise);
  }

  render() {
    const { showModal } = this.state;

    return (
      <Fragment>
        <Button variant="primary" onClick={this.toggleModal} size="sm">
          New Deal
        </Button>
        <Modal show={showModal} onHide={this.toggleModal} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>New Deal</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderDealCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderDealCreate() {
    const {
      error,
      success,
      isLoading,
      signingDate,
      signingDateValid,
      dealAmendId,
      dealAmendIdValid,
      formSubmitted,
      companies,
      companyValid,
      secFilingLink,
      secFilingLinkValid,
      dealType
    } = this.state;
    const { company } = this.props;

    if (isLoading) return <Loader />;
    return (
      <Form>
        {company ? (
          <h3 className="mt-3 mb-3">{company.companyName}</h3>
        ) : (
          <Form.Group>
            <Form.Label>Company</Form.Label>
            <Typeahead
              isLoading={isLoading}
              labelKey={(option) => option.companyName}
              onChange={this.handleCompanyNameChange}
              placeholder="Select company..."
              options={companies}
              isInvalid={!companyValid && formSubmitted}
            />
            <Form.Control.Feedback type="invalid">
              Company is required
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Deal Amend ID</Form.Label>
          <Form.Control
            type="text"
            name="dealAmendId"
            value={dealAmendId}
            placeholder="Enter Amend Deal ID..."
            isInvalid={!dealAmendIdValid && formSubmitted}
            onChange={this.handleDealAmendIdChange}
          />
          <Form.Control.Feedback type="invalid">
            Deal Amend ID is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Signing Date</Form.Label>
          <Form.Control
            type="date"
            name="signingDate"
            value={signingDate}
            isInvalid={!signingDateValid && formSubmitted}
            onChange={this.handleSigningDateChange}
          />
          <Form.Control.Feedback type="invalid">
            Date is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Deal Type</Form.Label>
          <Form.Control
            as="select"
            value={dealType}
            onChange={this.handleDealTypeChange}
          >
            <option value="" disabled>
              Select Type of Deal
            </option>
            <option value="IGR">IGR</option>
            <option value="Leveraged">Leveraged</option>
          </Form.Control>
        </Form.Group>
        <Form.Group>
          <Form.Label>Sec Filing Link</Form.Label>
          <Form.Control
            type="text"
            name="secFilingLink"
            value={secFilingLink}
            placeholder="Enter SEC Filing Link..."
            isInvalid={!secFilingLinkValid && formSubmitted}
            onChange={this.handleSecFilingLinkChange}
          />
          <Form.Control.Feedback type="invalid">
            Sec Filing Link is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 400) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>Deal Amend ID already exists.</p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Deal Created Successfully</p>
          </Alert>
        )}
      </Form>
    );
  }
}

export default DealCreate;
