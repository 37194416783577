import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import { put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DealEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    let deal = props.deal;

    this.state = {
      signingDate: deal.signingDate || new Date(),
      signingDateValid: true,
      dealAmendId: deal.dealAmendId || "",
      dealAmendIdValid: true,
      dealType: "",
      secFilingLink: deal.secFilingLink || ""
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.toggleModal = this._toggleModal.bind(this);

    this.handleDealAmendIdChange = this._handleDealAmendIdChange.bind(this);
    this.handleSigningDateChange = this._handleSigningDateChange.bind(this);
    this.handleDealTypeChange = this._handleDealTypeChange.bind(this);
    this.handleSecFilingLinkChange = this._handleSecFilingLinkChange.bind(this);
    
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleDealAmendIdChange(event) {
    let dealAmendId = event.target.value;
    this.setState({
      dealAmendId,
      dealAmendIdValid: dealAmendId.length > 2,
    });
  }

  _handleSigningDateChange(event) {
    let signingDate = event.target.value;
    this.setState({
      signingDate: signingDate,
      signingDateValid: true,
    });
  }

  _handleSecFilingLinkChange(event){
    let secFilingLink = event.target.value;
    this.setState({
      secFilingLink: secFilingLink,
      secFilingLinkValid: true,
    });
  }

  _handleDealTypeChange(event) {
    let dealType = event.target.value;
    this.setState({ dealType: dealType });
  }

  _handleSubmit(event) {
    event.preventDefault();

    this.setState({ formSubmitted: true });
    const {
      signingDate,
      signingDateValid,
      dealAmendId,
      dealAmendIdValid,
      dealType,
      secFilingLink,
      secFilingLinkValid
    } = this.state;

    if (!signingDateValid || !dealAmendIdValid || !secFilingLinkValid) return;
    this.setState({ error: "", isLoading: true });
    let { deal } = this.props;
    const updateDealPromise = put(`/deals/${deal.id}`, {
      signingDate: signingDate,
      dealAmendId: dealAmendId,
      companyId: deal.company.id,
      dealType: dealType,
      secFilingLink: secFilingLink
    });
    updateDealPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false,
            deal: response.data,
          },
          () => {
            this.props.onDealUpdated(response.data);
          }
        );
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoading: false,
        });
      });

    this.subscribedPromises.push(updateDealPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;

    return (
      <Fragment>
        <Button variant="outline-success" onClick={this.toggleModal} size="xs">
          Update
        </Button>

        <Modal show={showModal} onHide={this.toggleModal} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Update Deal</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderDealEdit()} </Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderDealEdit() {
    const {
      error,
      success,
      formSubmitted,
      dealAmendId,
      dealAmendIdValid,
      isLoading,
      signingDate,
      signingDateValid,
      dealType,
      secFilingLink,
      secFilingLinkValid
    } = this.state;

    const { deal } = this.props;
    if (isLoading) return <Loader />;

    return (
      <Fragment>
        <h3>{deal.company.companyName}</h3>
        <Form>
          <Form.Group>
            <Form.Label>Deal Amend ID</Form.Label>
            <Form.Control
              type="text"
              name="dealAmendId"
              value={dealAmendId}
              onChange={this.handleDealAmendIdChange}
              isInvalid={!dealAmendIdValid && formSubmitted}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Signing Date</Form.Label>
            <Form.Control
              type="date"
              name="signingDate"
              value={signingDate}
              onChange={this.handleSigningDateChange}
              isInvalid={!signingDateValid && formSubmitted}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Deal Type</Form.Label>

            <Form.Control
              as="select"
              value={dealType}
              onChange={this.handleDealTypeChange}
            >
              <option value="" disabled>
                Select Type of Deal
              </option>
              <option value="IGR">IGR</option>
              <option value="Leveraged">Leveraged</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>Sec Filing Link</Form.Label>
            <Form.Control
              type="text"
              name="secFilingLink"
              value={secFilingLink}
              onChange={this.handleSecFilingLinkChange}
              isInvalid={!secFilingLinkValid && formSubmitted}
            />
          </Form.Group>
          {error &&
            ((error) => {
              if (error.status === 400) {
                return (
                  <Alert variant="warning" className="mt-md mb-md">
                    <h3>Conflict</h3>
                    <p>Deal Amend ID already exists.</p>
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}
          {success && (
            <Alert variant="success">
              <h4>Success!</h4>
              <p>Exhibit Updated Successfully</p>
            </Alert>
          )}
        </Form>
      </Fragment>
    );
  }
}

DealEdit.propTypes = {
  deal: PropTypes.object.isRequired,
};

export default DealEdit;
