import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import { put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class BankEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      bankName: props.bank?.bankName || "",
      bankNameValid: props.bank?.bankName.length > 2 ? true : false
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.toggleModal = this._toggleModal.bind(this);

    this.handleNameChange = this._handleNameChange.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleNameChange(event) {
    let bankName = event.target.value;
    this.setState({
      bankName: bankName,
      nameValid: bankName.length > 2 ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState({ formSubmitted: true }, () => this.updateBank());
  }

  updateBank() {
    const { bankName, bankNameValid } = this.state;
    if (!bankNameValid) return;

    const { bank, onBankEdited } = this.props;
    this.setState({ error: "", isLoading: true });
    const createPromise = put(`/banks/${bank.id}`, { bankName: bankName });
    createPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false
          },
          () => {
            onBankEdited(response.data);
          }
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;
    return (
      <Fragment>
        <Button variant="outline-success" size="sm" onClick={this.toggleModal}>
          Update
        </Button>
        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Bank</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderBankEdit()}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              disabled={isLoading}
              onClick={this.toggleModal}
            >
              Close
            </Button>
            <Button variant="primary" onClick={this.handleSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderBankEdit() {
    const {
      error,
      isLoading,
      bankName,
      bankNameValid,
      formSubmitted
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={bankName}
            onChange={this.handleNameChange}
            isInvalid={!bankNameValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Name is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>A bank with similar name already exists.</p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
      </Form>
    );
  }
}

BankEdit.propTypes = {
  bank: PropTypes.object.isRequired,
  onBankEdited: PropTypes.func.isRequired
};

export default BankEdit;
