import React from "react";
import {
  CompositeDecorator,
  EditorState,
  RichUtils,
  SelectionState
} from "draft-js";

import EntityDecorator from "./EntityDecorator";

const EntitiesUtils = {
  getDecorators(updateEntity, discardEntity, container) {
    return new CompositeDecorator([
      {
        strategy: findEntity("COMMENT"),
        component: function EntityComponent(props) {
          return (
            <EntityDecorator
              updateEntity={updateEntity}
              discardEntity={discardEntity}
              container={container}
              {...props}
            />
          );
        }
      }
    ]);
  },

  createEntity(editorState, selectionState, data) {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "COMMENT",
      "MUTABLE",
      data
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity
    });

    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  },

  updateEntity(editorState, selectionState, entityKey, data) {
    const contentState = editorState.getCurrentContent();
    const newContentState = contentState.replaceEntityData(entityKey, data);
    const newEditorState = EditorState.set(editorState, {
      currentContent: newContentState
    });

    return RichUtils.toggleLink(newEditorState, selectionState, entityKey);
  },

  deleteEntity(editorState, selectionState) {
    return RichUtils.toggleLink(editorState, selectionState, null);
  },

  getSelectionState(editorState, entityKey, blockKey, start, end) {
    return new SelectionState({
      anchorKey: blockKey,
      anchorOffset: start,
      focusKey: blockKey,
      focusOffset: end,
      isBackward: false,
      hasFocus: false
    });
  }
};

export default EntitiesUtils;

function findEntity(type) {
  return function(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === type
      );
    }, callback);
  };
}
