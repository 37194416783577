import React, { Component, Fragment } from "react";
import moment from "moment";
import { Row, Col, Table, Alert, Badge, Form, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { get, put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ExhibitEdit from "../../Exhibits/ExhibitEdit/ExhibitEdit";
import ExhibitCreate from "../../Exhibits/ExhibitCreate/ExhibitCreate";
import ExhibitDelete from "../../Exhibits/ExhibitDelete/ExhibitDelete";
import DealEdit from "../../Deals/DealEdit/DealEdit";
import DealAssignment from "../../Deals/DealAssignment/DealAssignment";
import DealStatus from "../../Deals/DealStatus/DealStatus";
import DealFeedback from "../../Deals/DealFeedback/DealFeedback";

class ExtractionDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editingFeedback: {}
    };
    this.subscribedPromises = [];

    this.handleExhibitCreated = this._handleExhibitCreated.bind(this);
    this.handleExhibitUpdated = this._handleExhibitUpdated.bind(this);
    this.handleExhibitDeleted = this._handleExhibitDeleted.bind(this);
    this.handleFeedbackCreated = this._handleFeedbackCreated.bind(this);
  }

  componentDidMount() {
    this.fetchDeal();
    this.fetchExhibits();
    this.fetchDealActivities();
    this.fetchDealFeedback();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleFeedbackCreated() {
    this.fetchDealFeedback();
  }

  _handleExhibitCreated(exhibit) {
    const { exhibits } = this.state;
    let mutableList = [...exhibits];
    mutableList.push(exhibit);
    this.setState({ exhibits: mutableList });
  }

  _handleExhibitUpdated(exhibit) {
    const { exhibits } = this.state;
    let mutableList = [...exhibits];
    let index = exhibits.findIndex((item) => {
      return item.id === exhibit.id;
    });
    mutableList[index] = exhibit;
    this.setState({ exhibits: mutableList });
  }

  _handleExhibitDeleted(exhibitId) {
    let { exhibits } = this.state;
    exhibits = exhibits.filter((exhibit) => exhibit.id !== exhibitId);
    this.setState({ exhibits });
  }

  fetchExhibits() {
    this.setState({ exhibitsIsLoading: true, exhibitError: "" });
    const { dealId } = this.props;
    const getExhibitsPromise = get(`/deals/${dealId}/exhibits`);
    getExhibitsPromise.promise
      .then((response) => {
        this.setState({
          exhibitsIsLoading: false,
          exhibitError: "",
          exhibits: response.data
        });
      })
      .catch((error) => {
        this.setState({ exhibitError: error, exhibitsIsLoading: false });
      });
    this.subscribedPromises.push(getExhibitsPromise);
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;
    const getDealsPromise = get(`/deals/${dealId}`);
    getDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getDealsPromise);
  }

  fetchDealActivities() {
    const { dealId } = this.props;
    this.setState({ activitiesIsLoading: true, activitiesError: null });
    const activitiesPromise = get("/deal-activities", {
      params: { deals: [dealId] }
    });
    activitiesPromise.promise
      .then((response) => {
        this.setState({
          dealActivities: response.data,
          activitiesIsLoading: false,
          activitiesError: null
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ activitiesError: error, activitiesIsLoading: false });
      });
    this.subscribedPromises.push(activitiesPromise);
  }

  fetchDealFeedback() {
    const { dealId } = this.props;
    this.setState({ feedbackIsLoading: true, feedbackError: null });
    const feedbackPromise = get("/deal-reviews", {
      params: { dealId: dealId }
    });
    feedbackPromise.promise
      .then((response) => {
        // console.log(response.data);
        this.setState({
          dealFeedback: response.data,
          feedbackIsLoading: false,
          feebackError: null
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ feedbackError: error, feedbackIsLoading: false });
      });
    this.subscribedPromises.push(feedbackPromise);
  }

  extractExhibit(exhibitId) {
    this.setState({ documentIsLoading: true, documentError: "" });
    const extractExhibitsPromise = put(`/exhibits/${exhibitId}/document-raw`);
    extractExhibitsPromise.promise
      .then((response) => {
        return new Promise((resolve) =>
          setTimeout(() => resolve(response), 500)
        );
      })
      .then((response) => {
        this.setState({ documentIsLoading: false, documentError: "" });
      })
      .catch((error) => {
        this.setState({ documentError: error, documentIsLoading: false });
      });

    this.subscribedPromises.push(extractExhibitsPromise);
  }

  render() {
    const { deal, isLoading, error, editingFeedback } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;

    const company = deal.company;
    return (
      <div className="ExtractionDashboard WideView">
        <Row className="WideCanvas">
          <Col xs={12} md={2} className="WideLeft">
            <div className="mt-3 mb-3">
              <h4>Company</h4>
              <p>
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </p>

              <h4>Deal Signing Date</h4>
              <p>
                <Link to={`/extraction/${deal.id}`}>
                  {moment(deal.signingDate).format("LL")}{" "}
                </Link>
              </p>

              <h4>Deal Amend Id </h4>
              <p>{deal.dealAmendId}</p>

              <h4>Deal Type</h4>
              <p>
                {deal.dealType ? (
                  <span className="badge badge-warning">{deal.dealType}</span>
                ) : (
                  <span className="text-muted">--</span>
                )}
              </p>

              <h4>SEC Filing Link</h4>
              <p>
                {deal.secFilingLink ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={deal.secFilingLink}
                  >
                    {deal.secFilingLink}
                  </a>
                ) : (
                  <span className="text-muted">No Link Found</span>
                )}
              </p>

              <hr />
              <div>
                {deal.stationStatus.extraction ? (
                  <Badge variant="success">In progress</Badge>
                ) : (
                  <Badge variant="dark">Not started</Badge>
                )}{" "}
                <DealEdit deal={deal} onDealEdited={this.handleDealUpdated} />{" "}
              </div>
            </div>
          </Col>
          <Col xs={12} sm={8} className="WideCenter">
            <div className="mt-3 mb-3">
              <h2>
                Deal signing date as of {moment(deal.signingDate).format("LL")}
              </h2>
            </div>
            <hr />
            <div className="mt-3 mb-3">
              <span className="float-right">
                <ExhibitCreate
                  onExhibitCreated={this.handleExhibitCreated}
                  deal={deal}
                />
              </span>
              <h3>Exhibits</h3>
              <p>
                Exhibits are from 10-K, 10-Q or 8-K forms that are submitted by
                the borrower company to SEC.
              </p>
              {this.renderExhibits()}
            </div>

            {
              // <hr />
              // <h3>Feedback</h3>
              // <p>Additional Feedback</p>
              //this.renderDealFeedback()
              //<DealFeedback deal={deal} onFeedbackCreated={this.handleFeedbackCreated} editingFeedback={editingFeedback} />
            }
            <hr />
            <h4>Processing Activities</h4>
            {this.renderDealActivity()}
          </Col>
          <Col xs={12} sm={2} className="WideRight">
            <div className="mt-3 mb-3">
              <DealStatus deal={deal} />
            </div>
            <div className="mt-3 mb-3">
              <p className="text-muted mb-0">Assignee</p>
              <DealAssignment deal={deal} highlight={true} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  editFeedback(e, feedback) {
    e.preventDefault();
    this.setState({ editingFeedback: feedback });
  }

  renderDealFeedback() {
    const { dealFeedback, feedbackIsLoading, feedbackError } = this.state;

    if (feedbackIsLoading) return <Loader />;
    if (feedbackError) return <ErrorHandler error={feedbackError} />;
    if (!dealFeedback) return <span />;
    if (!dealFeedback.length)
      return (
        <Alert variant="info">There are currently no Feedback to show.</Alert>
      );
    //console.log(dealFeedback);
    return (
      <Table responsive size="sm">
        <tbody>
          {dealFeedback.map((fb) => {
            return (
              <tr key={fb.id}>
                <td>
                  <p>{fb.body}</p>
                  <p>{fb.username}</p>
                </td>
                <td>
                  <button
                    variant="primary"
                    onClick={(e) => this.editFeedback(e, fb)}
                  >
                    Edit
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderExhibits() {
    const { deal, exhibits, exhibitsIsLoading, exhibitError } = this.state;

    if (exhibitsIsLoading) return <Loader />;
    if (exhibitError) return <ErrorHandler error={exhibitError} />;
    if (!exhibits) return <span />;
    if (!exhibits.length)
      return (
        <Alert variant="info">There are currently no exhibits to show.</Alert>
      );

    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Exhibit</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {exhibits.map((exhibit) => {
            return (
              <tr key={exhibit.id}>
                <td>
                  <Link
                    to={`/extraction/${deal.id}/exhibits/${
                      exhibit.id
                    }/document-raw`}
                  >
                    {exhibit.exhibitName}
                  </Link>
                </td>
                <td>{moment(exhibit.createAt).format("MM/DD/YYYY")}</td>
                <td>{moment(exhibit.updatedAt).format("MM/DD/YYYY")}</td>
                <td>
                  <Link
                    to={`/extraction/${deal.id}/exhibits/${
                      exhibit.id
                    }/document-raw`}
                  >
                    Process exhibit{" "}
                    <span className="material-icons md-18">arrow_forward</span>
                  </Link>
                </td>
                <td className="text-right">
                  <ExhibitEdit
                    exhibit={exhibit}
                    onExhibitUpdated={this.handleExhibitUpdated}
                  />{" "}
                  <ExhibitDelete
                    exhibit={exhibit}
                    onExhibitDeleted={this.handleExhibitDeleted}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderDealActivity() {
    const { dealActivities, activitiesIsLoading, activitiesError } = this.state;

    if (activitiesIsLoading) return <Loader />;
    if (activitiesError) return <ErrorHandler error={activitiesError} />;
    if (!dealActivities) return <span />;
    if (!dealActivities.length)
      return (
        <Alert variant="info">There are currently no activities to show.</Alert>
      );

    return (
      <ul>
        {dealActivities.map((activity) => {
          return (
            <li key={activity.id} className="mt-2 mb-2">
              {activity.username || activity.checkedoutBy}{" "}
              <span className="text-muted">
                started on{" "}
                {moment
                  .utc(activity.checkedoutAt)
                  .local()
                  .format("lll")}
                .{" "}
              </span>
              <Fragment>
                {activity.checkedinAt ? (
                  <span className="text-muted">
                    Completed on{" "}
                    {moment
                      .utc(activity.checkedinAt)
                      .local()
                      .format("lll")}
                  </span>
                ) : (
                  <Fragment>
                    <span className="material-icons md-16">lock</span>{" "}
                    <span className="text-muted">Incomplete.</span>
                  </Fragment>
                )}
              </Fragment>
            </li>
          );
        })}
      </ul>
    );
  }
}

ExtractionDashboard.propTypes = {
  dealId: PropTypes.string.isRequired
};

export default ExtractionDashboard;
