import React, { Component, Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Modal,
  Form,
  Alert,
  Card,
  Spinner,
  Badge
} from "react-bootstrap";
import "./IntelligenceDashboard.css";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import { get, put } from "../../../utils/DeApi";
import moment from "moment";
import { Link, Redirect } from "react-router-dom";

class IntelligenceDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      searchData: [],
      canSearch: false
    };
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleSearchText = this._handleSearchText.bind(this);
    this.handleSigningDateChange = this._handleSigningDateChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  componentDidMount() {
    this.fetchDeal();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  _handleSigningDateChange(event) {
    let signingDate = event.target.value;
    this.setState({
      signingDate,
      signingDateValid: signingDate.length > 2 ? true : false
    });
  }

  _handleSearchText(event) {
    let searchText = event.target.value;
    this.setState({
      searchText,
      searchTextValid: searchText.length > 2 ? true : false
    });
  }

  _handleKeyDown = (event) => {
    if (event.key === "Enter") {
      this.fetchSearch(this.state.searchText);
    }
  };

  _handleSubmit(event) {
    event.preventDefault();

    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { signingDate, signingDateValid, deal } = this.state;

        if (signingDateValid) {
          this.setState({
            error: ""
            // isLoading: true
          });

          const createPromise = put(`/deals/${this.state.deal.id}`, {
            signingDate,
            companyId: deal.company.id
          });

          createPromise.promise
            .then((response) => {
              this.setState({
                isLoading: false,
                error: "",
                showModal: false,
                deal: response.data
              });
            })
            .catch((error) => {
              this.setState({ error: error, isLoading: false });
            });
          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  fetchSearch(search = null) {
    if (search.length > 2) {
      this.setState(
        {
          sync: true,
          error: "",
          searchText: search,
          isSearching: true,
          searchData: []
        },
        () => {
          this.setState({ redirect: true });
        }
      );
    }
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });

    const { dealId } = this.props;
    const createDealPromise = get(`/deals/${dealId}`, {});
    createDealPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data,
          canSearch: response.data.exhibit.length ? true : false,
          signingDate: response.data.signingDate,
          signingDateValid: true
        });
      })
      .catch((error) => {
        this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createDealPromise);
  }

  indexExhibits(exhibit) {
    this.setState({ documentIsIndexing: true, error: "" });
    const createIndexingPromise = put("/search/document-structured", {
      exhibitId: exhibit.id
    });
    createIndexingPromise.promise
      .then((response) => {
        this.setState({ documentIsIndexing: false, error: "" });
      })
      .catch((error) => {
        this.setState({ error: error, documentIsIndexing: false });
      });

    this.subscribedPromises.push(createIndexingPromise);
  }

  renderSearch() {
    const { deal, canSearch, error, documentIsIndexing } = this.state;

    if (error) return <ErrorHandler error={error} />;
    return (
      <Fragment>
        <h3>
          Intelligence
          <Button
            className="float-right"
            variant="outline-success"
            disabled={!canSearch}
            size="sm"
            onClick={() => {
              deal.exhibit.forEach((exhibit) => this.indexExhibits(exhibit));
            }}
          >
            {documentIsIndexing && (
              <Spinner animation="border" size="sm" className="mr-1" />
            )}
            Parse Exhibits
          </Button>
        </h3>
        <p>Discover key deal information using relevant keywords</p>
        {!canSearch ? (
          <Alert variant="info" className="mt-md mb-md">
            <p>
              No exhibits found in the deal, please add an exhibit to search.
            </p>
          </Alert>
        ) : (
          <Fragment>
            <Row>
              <Form.Group as={Col} md="12">
                <Form.Control
                  onKeyDown={this._handleKeyDown}
                  disabled={!canSearch}
                  placeholder="Search by keywords"
                  onChange={this.handleSearchText}
                />
              </Form.Group>
            </Row>
            <Card>
              <Card.Header>Search by fixed terms:</Card.Header>
              <Card.Body>
                <div>
                  <h4>
                    Filing Purpose
                    <Button
                      variant="link"
                      disabled={!canSearch}
                      onClick={() => {
                        this.fetchSearch(`"use of proceeds purpose"~50`);
                      }}
                    >
                      Find
                    </Button>
                  </h4>
                  <p className="smallFont">
                    Shows the results of amendment OR commitment OR origination
                    OR amended & restated
                  </p>
                </div>
                <div>
                  <h4>
                    Base Rate
                    <Button
                      variant="link"
                      disabled={!canSearch}
                      onClick={() => {
                        this.fetchSearch(`"base rate means"`);
                      }}
                    >
                      Find
                    </Button>
                  </h4>
                  <p className="smallFont">Shows the results of base rate</p>
                </div>
                <div>
                  <h4>
                    Commitment Fees
                    <Button
                      variant="link"
                      disabled={!canSearch}
                      onClick={() => {
                        this.fetchSearch(`"commitment fee" AND pay`);
                      }}
                    >
                      Find
                    </Button>
                  </h4>
                  <p className="smallFont">
                    Shows the results of administrative agent
                  </p>
                </div>
              </Card.Body>
            </Card>
          </Fragment>
        )}
      </Fragment>
    );
  }

  renderDealEdit() {
    const {
      error,
      success,
      deal,
      signingDateValid,
      formSubmitted,
      isLoading
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>Company Name</Form.Label>
          <Form.Control type="text" value={deal.company.companyName} disabled />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>Signing Date</Form.Label>
          <Form.Control
            type="date"
            defaultValue={deal.signingDate}
            onChange={this.handleSigningDateChange}
            isInvalid={!signingDateValid && formSubmitted}
            placeholder="Enter signing date"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Signing Date is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Deal updated Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }

  render() {
    const {
      deal,
      isLoading,
      error,
      showModal,
      redirect,
      searchText
    } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    if (redirect) {
      return (
        <Redirect to={`/intelligence/${deal.id}/search?q=${searchText}`} />
      );
    }

    if (!deal) return <span />;
    const company = deal.company;

    return (
      <Row>
        <Col xs={12} className="mt-2 mb-2">
          <ul className="list-inline">
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/`}>Dashboard</Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">Deal Intelligence</h4>
            </li>
          </ul>
          <hr />
          <div className="mt-3 mb-3">
            <p className="float-right">
              {deal.stationStatus.intelligence ? (
                <Badge variant="success">In progress</Badge>
              ) : (
                <Badge variant="dark">Not started</Badge>
              )}{" "}
              <Button
                variant="outline-primary"
                size="sm"
                href={`/extraction/${deal.id}`}
              >
                Extraction
              </Button>{" "}
              <Button
                variant="outline-primary"
                size="sm"
                href={`/intelligence/${deal.id}`}
                disabled
              >
                Intelligence
              </Button>{" "}
              <Button
                variant="outline-primary"
                size="sm"
                href={`/packaging/${deal.id}`}
              >
                Packaging
              </Button>{" "}
              <Button
                variant="outline-primary"
                size="sm"
                href={`/delivery/${deal.id}`}
              >
                Delivery
              </Button>{" "}
              <Button
                size="sm"
                variant="outline-success"
                onClick={this.toggleModal}
              >
                Update Deal
              </Button>
            </p>
            <h2>
              Deal signing date as of {moment(deal.signingDate).format("LL")}
            </h2>
            <p>
              Parent Borrower:{" "}
              <Link to={`/companies/${company.id}`}>{company.companyName}</Link>
            </p>
            <p className="text-muted">
              Created at <span>{moment(deal.createdAt).format("LLL")}</span>
            </p>
            <p />
          </div>
          <hr />
          {this.renderSearch()}
        </Col>
        {showModal && (
          <Modal show={showModal} onHide={this.toggleModal}>
            <Modal.Header closeButton>
              <Modal.Title>Update Deal</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.renderDealEdit()}</Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={this.toggleModal}>
                Close
              </Button>
              <Button
                variant="primary"
                disabled={isLoading}
                onClick={this.handleSubmit}
              >
                Update
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Row>
    );
  }
}

export default IntelligenceDashboard;
