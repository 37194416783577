import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";
import "./Packaging.scss";

import Header from "../App/Header/Header";

import PackagingDashboard from "./PackagingDashboard/PackagingDashboard";

class Packaging extends Component {
  render() {
    return (
      <div className="Main Packaging">
        <Header user={this.props.user} title={"Packaging"} />
        <div className="MainContent">
          <Container>
            <Switch>
              <Route
                path="/packaging/:dealId"
                render={(props) => (
                  <PackagingDashboard dealId={props.match.params.dealId} />
                )}
              />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

export default Packaging;
