import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import {
  Container,
  Col,
  Row,
  Button,
  Alert,
  Form,
  Card
} from "react-bootstrap";

import "./ForgotPassword.css";
import { isLoggedIn, put } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import { isLoggedIn as isLoggedInDE } from "../../../utils/DeApi";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);

    const loggedIn = isLoggedInDE() && isLoggedIn();
    let email = loggedIn ? loggedIn.email : "";
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.state = {
      email: email,
      emailValid: pattern.test(email),
      error: "",
      loggedIn: loggedIn,
      success: false
    };

    this.subscribedPromises = [];
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    this.setState({
      email: email,
      emailValid: pattern.test(email)
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      () => {
        if (this.state.emailValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = put("/login", {
            action: "forgot",
            email: this.state.email
          });
          createPromise.promise
            .then(response => {
              this.setState({
                isLoading: false,
                success: true,
                data: response
              });
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  renderForgotPasswordForm() {
    const { email, emailValid, success, error, formSubmitted } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="mt-sm mb-md text-center">
          <h2 className="pre-title">Request New Password</h2>
          <p>We&#x27;ll email you instructions to set your password.</p>
        </div>
        <hr />
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={this.handleEmailChange}
            placeholder="Enter email"
            isInvalid={!emailValid && formSubmitted}
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            This should be a valid email address
          </Form.Control.Feedback>
        </Form.Group>
        {error && <ErrorHandler error={error} />}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>
              Please follow the instructions sent to the email you provided to
              reset your password.
            </p>
          </Alert>
        )}
        <Button className="mb-4" type="submit" color="primary" size="lg" block>
          Send Instructions
        </Button>
        {/* <hr /> */}
        <p className="text-center">
          <Link to="/login">Cancel</Link>
        </p>
      </form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;
    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Container fluid className="ForgotPassword mt-5">
        <Row>
          <Col md={{ span: 4, offset: 4 }} sm={{ span: 4, offset: 4 }}>
            <div className="text-center mt-3 mb-3">
              <span className="lkm-logo">LOAN KNOWLEDGE MILL</span>
            </div>
            <Card className="mt-2">
              <Card.Body>{this.renderForgotPasswordForm()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default ForgotPassword;
