import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";

class DashboardSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      companies: [],
      search: [],
      redirect: false,
      companyId: null,
    };
    this.subscribedPromises = [];

    this.searchCompany = this._searchCompany.bind(this);
    this.renderRedirect = this._renderRedirect.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _searchCompany(companies) {
    companies.length &&
      this.setState({
        companyId: companies[0].id,
        redirect: true,
      });
  }

  _renderRedirect() {
    if (this.state.redirect) {
      return <Redirect to={`/companies/${this.state.companyId}`} />;
    }
  }

  render() {
    const { companies } = this.props;

    if (!companies) return <span />

    return (
      <div>
        {this.renderRedirect()}
        <Typeahead
          labelKey={(option) => option.companyName}
          onChange={this.searchCompany}
          placeholder="Search for a company..."
          options={companies}
          id="1231"
        />
      </div>
    );
  }
}

export default DashboardSearch;
