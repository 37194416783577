import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Alert, Modal, Button, Form } from "react-bootstrap";

import { post } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EntityCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleNameChange = this._handleNameChange.bind(this);
    this.handleDocumentSectionChange = this._handleDocumentSectionChange.bind(this);
    this.handlebluesSectionChange = this._handlebluesSectionChange.bind(this);
    this.handleBluesCommentChange = this._handleBluesCommentChange.bind(this);

    this.toggleModal = this._toggleModal.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleNameChange(event) {
    let entityName = event.target.value;
    this.setState({
      entityName,
      entityNameValid: entityName.length > 2 ? true : false
    });
  }

  _handleDocumentSectionChange(event) {
    let documentSection = event.target.value;
    this.setState({
      documentSection,
      documentSectionValid: documentSection.length > 2 ? true : false
    });
  }

  _handlebluesSectionChange(event) {
    let bluesSection = event.target.value;
    this.setState({
      bluesSection,
      bluesSectionValid: bluesSection.length > 2 ? true : false
    });
  }

  _handleBluesCommentChange(event) {
    let bluesComment = event.target.value;
    this.setState({
      bluesComment,
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      () => {
        this.createEntity();
      }
    );
  }

  createEntity() {
    const {
      entityName,
      entityNameValid,
      bluesSection,
      bluesSectionValid,
      documentSection,
      documentSectionValid,
      bluesComment
    } = this.state;
    
    const { onEntityCreated } = this.props;

    if (entityNameValid && bluesSectionValid && documentSectionValid) {
      this.setState({ error: "", isLoading: true });
      const createEntityPromise = post("/credit-agreement-entity", {
        entityName,
        documentSection,
        bluesSection,
        bluesComment
      });
      createEntityPromise.promise
        .then((response) => {
          this.setState(
            {
              isLoading: false,
              error: "",
              showModal: false
            },
            () => {
              onEntityCreated(response.data);
            }
          );
        })
        .catch((error) => {
          !error.isCanceled && this.setState({ error: error, isLoading: false });
        });

      this.subscribedPromises.push(createEntityPromise);
    }
  }

  render() {
    const { isLoading, showModal } = this.state;
    return (
      <Fragment>
        <Button size="sm" variant="primary" onClick={this.toggleModal}>
          New Entity
        </Button>

        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create Entity</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderEntityCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderEntityCreate() {
    const {
      error,
      entityName,
      entityNameValid,
      formSubmitted,
      isLoading,
      documentSection,
      documentSectionValid,
      bluesSection,
      bluesSectionValid,
      bluesComment
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={entityName}
            onChange={this.handleNameChange}
            isInvalid={!entityNameValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Name is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Document Section</Form.Label>
          <Form.Control
            type="text"
            value={documentSection}
            onChange={this.handleDocumentSectionChange}
            isInvalid={!documentSectionValid && formSubmitted}
            placeholder="Enter document section"
          />
          <Form.Control.Feedback type="invalid">
            Document Section is required
          </Form.Control.Feedback>
        </Form.Group><Form.Group>
          <Form.Label>Blues Section</Form.Label>
          <Form.Control
            type="text"
            value={bluesSection}
            onChange={this.handlebluesSectionChange}
            isInvalid={!bluesSectionValid && formSubmitted}
            placeholder="Enter Blues section"
          />
          <Form.Control.Feedback type="invalid">
            Blues Section is required
          </Form.Control.Feedback>
        </Form.Group><Form.Group>
          <Form.Label>Blues Commment</Form.Label>
          <Form.Control
            type="text"
            value={bluesComment}
            onChange={this.handleBluesCommentChange}
            placeholder="Enter Blues comment"
          />
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>An entity with same name already exists.</p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
      </Form>
    );
  }
}
EntityCreate.propTypes = {
  onEntityCreated: PropTypes.func.isRequired
};

export default EntityCreate;
