import React, { Component, Fragment } from "react";
import { Alert, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import { put, get } from "../../../../utils/DeApi";
import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { CopyToClipboard } from "react-copy-to-clipboard";

class DealAmendmentEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleLtDateChange = this._handleLtDateChange.bind(this);
    this.handleAmendEventChange = this._handleAmendEventChange.bind(this);
    this.handleDefaultSpreadChange = this._handleDefaultSpreadChange.bind(this);
    this.runLtDateRule = this._runLtDateRule.bind(this);
    this.runSpreadRule = this._runSpreadRule.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
    this.handleLtDealChange = this._handleLtDealChange.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    this.setValues();
  }

  _handleDefaultSpreadChange(event) {
    let defaultSpread = event.target.value;
    this.setState({ defaultSpread });
  }

  _handleLtDateChange(event) {
    let ltDate = event.target.value;

    this.setState(
      {
        ltDate,
        ltDateValid: true
      }
    );
  }

  _handleAmendEventChange(event) {
    let amendEvent = event.target.value;
    this.setState({ amendEvent });
  }

  _handleLtDealChange(event) {
    let ltDealFlag = event.target.value;
    this.setState({ ltDealFlag });
  }

  submit(event) {
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { ltDate, defaultSpread, amendEvent, ltDealFlag } = this.state;

        this.setState({
          error: ""
        });

        const createPromise = put(`/dealMeta/${this.props.dealId}`, {
          ltDate,
          defaultSpread,
          amendEvent,
          ltDealFlag
        });

        createPromise.promise
          .then(response => {
            if (event === "amend") {
              this.fetchAmend();
            } else if (event === "spread") {
              this.fetchSpreadComment();
            } else {
              this.fetchLtDate();
            }
          }
          )
          .catch(error => {
            this.setState({
              error: error,
              isLoading: false
            });
          });

        this.subscribedPromises.push(createPromise);
      }
    );
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { ltDate, amendEvent, defaultSpread, ltDealFlag } = this.state;

        this.setState({
          error: "",
          isLoading: true
        });

        const createPromise = put(`/dealMeta/${this.props.dealId}`, {
          ltDate,
          amendEvent,
          defaultSpread,
          ltDealFlag
        });

        createPromise.promise
          .then(response => {
            this.setState(
              {
                isLoading: false,
                error: "",
                showModal: false,
              },
              () => {
                this.props.onDealAmendmentEdit(response.data);
              }
            );
          })
          .catch(error => {
            this.setState({
              error: error,
              isLoading: false
            });
          });

        this.subscribedPromises.push(createPromise);
      }
    );
  }

  _runLtDateRule() {
    this.submit("ltDate");
  }

  _runSpreadRule() {
    this.submit("spread");
  }

  setValues() {
    const { dealAmendment } = this.props;
    if (dealAmendment) {
      this.setState({
        dealAmendment,
        ltDate: dealAmendment.LtDate,
        amendEvent: dealAmendment.amendEvent,
        defaultSpread: dealAmendment.defaultSpread,
        defaultSpreadComment: dealAmendment.defaultSpreadComment,
        ltDealFlag: dealAmendment.LtDealFlag,
        amendEventFlag: dealAmendment.amendExtendFlag
      });
    }
  }

  fetchAmend() {
    this.setState({
      isLoading: true,
      error: ""
    });
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules/amendEvent`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isLoading: false,
          error: "",
          amendEventFlag: response.data.amendFlag
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isLoading: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  fetchLtDate() {
    this.setState({
      isRunningLtRule: true,
      error: ""
    });
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules/ltDate`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isRunningLtRule: false,
          error: ""
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isRunningLtRule: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  fetchSpreadComment() {
    this.setState({
      isRunningSpreadRule: true,
      error: ""
    });
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules/spreadComment`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isRunningSpreadRule: false,
          error: "",
          defaultSpreadComment: response.data.defaultSpreadComment
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isRunningSpreadRule: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  render() {
    const { isLoading, dealAmendment } = this.state;

    if (!dealAmendment) return <span />

    return (
      <Fragment>
        <Button
          className="float-right"
          variant="outline-success"
          onClick={this.toggleModal}
          size="sm"
        >
          Update
        </Button>

        <Modal show={this.state.showModal} size="lg" onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Deal Amendment</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderDealAmendmentEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderDealAmendmentEdit() {
    const {
      error,
      success,
      isLoading,
      sync,
      ltDate,
      amendEvent,
      defaultSpread,
      ltDealFlag,
      isRunningLtRule,
      isRunningSpreadRule
    } = this.state;

    if (sync || isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>LT Deal Flag</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="Y"
              label="Yes"
              name="yes"
              onChange={this.handleLtDealChange}
              checked={ltDealFlag === "Y"}
            />
            <Form.Check
              inline
              type="radio"
              value="N"
              label="No"
              name="no"
              onChange={this.handleLtDealChange}
              checked={ltDealFlag === "N"}
            />
          </div>
        </Form.Group>
        <Form.Group as={Col} xs="12">
          <Form.Label>LT Date</Form.Label>
          <CopyToClipboard
            text={this.state.ltDate}
            onCopy={() =>
              this.setState({ dateCopied: true, commentCopied: false })
            }
          >
            <Button size="sm" variant="link">
              <span className="material-icons md-24 text-muted ml-2">
                file_copy
              </span>
            </Button>
          </CopyToClipboard>
          {this.state.dateCopied && (
            <span className="text-muted">
              <small>Copied.</small>
            </span>
          )}
          <Form.Control
            type="date"
            value={ltDate}
            onChange={this.handleLtDateChange}
          />
          <span>
            <Button
              disabled={!this.state.ltDate || !this.state.ltDealFlag}
              variant="link"
              className="float-right"
              onClick={this.runLtDateRule}
            >
              {isRunningLtRule && (
                <Spinner animation="border" size="sm" className="mr-1" />
              )}
              Run Rules
            </Button>
          </span>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>Amend Event</Form.Label>
          <Form.Control
            as="select"
            value={amendEvent}
            onChange={this.handleAmendEventChange}
          >
            <option value="">Select Amend Event</option>
            <option value="Originations">Originations</option>
            <option value="Commitment Letter">Commitment Letter</option>
            <option value="Amendment">Amendment</option>
            <option value="A & R (Amendment and Restated)">
              A & R (Amendment and Restated)
            </option>
          </Form.Control>
          <Alert variant="primary" className="md mt-2 mb-md">
            Amend Event field is needed for a rule in another Section
          </Alert>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>Default Spread</Form.Label>
          <Form.Control
            type="number"
            defaultValue={defaultSpread}
            onChange={this.handleDefaultSpreadChange}
          />
        </Form.Group>
        <Form.Group as={Col} md="12">
          <div>
            <span>Default Spread Comment</span>
            <Button
              disabled={!this.state.defaultSpread}
              variant="link"
              className="float-right"
              onClick={this.runSpreadRule}
            >
              {isRunningSpreadRule && (
                <Spinner animation="border" size="sm" className="mr-1" />
              )}
              Run Rules (Obsolete)
            </Button>
            <CopyToClipboard
              text={this.state.defaultSpreadComment}
              onCopy={() =>
                this.setState({ commentCopied: true, dateCopied: false })
              }
            >
              <Button size="sm" variant="link">
                <span className="material-icons md-24 text-muted ml-2">
                  file_copy
                </span>
              </Button>
            </CopyToClipboard>
            {this.state.commentCopied && (
              <span className="text-muted">
                <small>Copied.</small>
              </span>
            )}
          </div>
          <Form.Control
            disabled
            type="text"
            value={this.state.defaultSpreadComment}
          />
        </Form.Group>
        {error &&
          (error => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Deal Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

export default DealAmendmentEdit;
