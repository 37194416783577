import React, { Component, Fragment } from "react";
import { Table, Button, Alert, Badge } from "react-bootstrap";
import { get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ExhibitEntities extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchStructured();
    this.fetchEntities();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.activeTab === "entities" &&
      prevProps.activeTab !== "entities"
    ) {
      this.fetchStructured();
    }
  }

  fetchEntities() {
    const createEntityPromise = get("credit-agreement-entity");
    this.setState({ structuredError: "", structuredIsLoading: true });

    createEntityPromise.promise
      .then((response) => {
        this.setState({ entities: response.data });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ structuredError: error, structuredIsLoading: false });
      });
    this.subscribedPromises.push(createEntityPromise);
  }

  fetchStructured() {
    const { exhibit } = this.props;

    this.setState({
      structuredIsLoading: true,
      structuredError: "",
      structured: null
    });

    const fetchStructuredPromise = get(
      `/exhibits/${exhibit.id}/document-structured`
    );
    fetchStructuredPromise.promise
      .then((response) => {
        this.setState({
          structuredIsLoading: false,
          structuredError: "",
          structured: JSON.parse(
            JSON.stringify(response.data, (key, value) =>
              value === null ? "" : value
            )
          )
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ structuredError: error, structuredIsLoading: false });
      });

    this.subscribedPromises.push(fetchStructuredPromise);
  }

  render() {
    const { structured, structuredError, structuredIsLoading } = this.state;

    if (structuredIsLoading) return <Loader />;
    if (structuredError) return <ErrorHandler error={structuredError} />;
    if (!structured || !structured?.documentStructured)
      return (
        <Alert variant="info" className="text-center">
          <p className="mb-0">
            There are currently no annotated entitites in this document.
          </p>
        </Alert>
      );

    const documentStructured = structured.documentStructured;
    const entityMap = documentStructured.entityMap;

    var blocksWithEntities = [];
    if (entityMap) {
      documentStructured.blocks.forEach((block, index) => {
        if (block.entityRanges && block.entityRanges.length) {
          block.entityRanges.forEach((entityRange) => {
            var blockWithEntity = { ...block };
            blockWithEntity.selection = block.text.slice(
              entityRange.offset,
              entityRange.offset + entityRange.length
            );
            try {
              blockWithEntity.entity = entityMap[entityRange.key];
            } catch (e) {
              blockWithEntity.entity = null;
            }
            blocksWithEntities.push(blockWithEntity);
          });
        }
      });
    }

    return (
      <div className="document-entities">
        <p className="text-right">
          <Button
            variant="outline-success"
            size="sm"
            onClick={() => this.fetchStructured()}
          >
            <span className="material-icons mr-1">refresh</span>Refresh
            Annotations
          </Button>
        </p>

        {!blocksWithEntities.length ? (
          <Alert variant="info" className="text-center">
            <p className="mb-0">
              There are currently no annotated entitites in this document.
            </p>
          </Alert>
        ) : (
          <Table size="sm">
            <thead>
              <tr bg="dark">
                <th>Index</th>
                <th>Selection</th>
                <th>Name</th>
                <th>Value</th>
                <th>Confidence</th>
              </tr>
            </thead>
            <tbody>
              {blocksWithEntities.map((block, index) => {
                return (
                  <tr key={index}>
                    <td>{1 + index}</td>
                    <td>
                      {block.selection ? (
                        block.selection
                      ) : (
                        <span className="text-muted">{block.text}</span>
                      )}
                    </td>
                    <td>{this.renderEntityName(block.entity)}</td>
                    <td>
                      {block.entity ? (
                        <code>
                          <h5 className="mb-0">
                            {block.entity.data && block.entity.data.comment}
                          </h5>
                        </code>
                      ) : (
                        ""
                      )}
                    </td>
                    <td>
                      {block.entity ? (
                        <small
                          className={`text-capitalize badge badge-success badge-pill badge-${block
                            .entity.data?.confidenceLevel || "medium"}`}
                        >
                          {block.entity.data?.confidenceLevel || "medium"}
                        </small>
                      ) : (
                        <span />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
    );
  }

  renderEntityName(blockEntity) {
    let data = blockEntity ? blockEntity.data : null;
    let { entities } = this.state;

    if (!data || !entities) return <span />;
    let labels = data.selectedEntity || [];

    let findEntity = (label) => {
      entities.find((entity) => label.id === entity.id);
    };
    return (
      <Fragment>
        {labels.map((label) => (
          <Fragment key={label.id}>
            <Badge variant="primary">
              {findEntity(label)?.entityName || label.entityName}
            </Badge>{" "}
          </Fragment>
        ))}
        {data.entityName && <Badge variant="default">{data.entityName}</Badge>}
      </Fragment>
    );
  }
}

export default ExhibitEntities;
