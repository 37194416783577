import React, { Component, Fragment } from "react";
import moment from "moment";
import { Row, Col, Table, Alert } from "react-bootstrap";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { get, put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import DealAssignment from "../../Deals/DealAssignment/DealAssignment";
import DealStatus from "../../Deals/DealStatus/DealStatus";

class DealFactChecking extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];
  }

  componentDidMount() {
    this.fetchDeal();
    this.fetchExhibits();
    this.fetchDealActivities();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchExhibits() {
    this.setState({ exhibitsIsLoading: true, exhibitError: "" });
    const { dealId } = this.props;
    const getExhibitsPromise = get(`/deals/${dealId}/exhibits`);
    getExhibitsPromise.promise
      .then((response) => {
        this.setState({
          exhibitsIsLoading: false,
          exhibitError: "",
          exhibits: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ exhibitError: error, exhibitsIsLoading: false });
      });
    this.subscribedPromises.push(getExhibitsPromise);
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;
    const getDealsPromise = get(`/deals/${dealId}`);
    getDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getDealsPromise);
  }

  fetchDealActivities() {
    const { dealId } = this.props;
    this.setState({ activitiesIsLoading: true, activitiesError: null });
    const activitiesPromise = get("/deal-activities", {
      params: { deals: [dealId] }
    });
    activitiesPromise.promise
      .then((response) => {
        this.setState({
          dealActivities: response.data,
          activitiesIsLoading: false,
          activitiesError: null
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ activitiesError: error, activitiesIsLoading: false });
      });
    this.subscribedPromises.push(activitiesPromise);
  }

  extractExhibit(exhibitId) {
    this.setState({
      documentIsLoading: true,
      documentError: ""
    });
    const extractExhibitsPromise = put(`/exhibits/${exhibitId}/document-raw`);
    extractExhibitsPromise.promise
      .then((response) => {
        return new Promise((resolve) =>
          setTimeout(() => resolve(response), 500)
        );
      })
      .then((response) => {
        this.setState({
          documentIsLoading: false,
          documentError: ""
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            documentError: error,
            documentIsLoading: false
          });
      });

    this.subscribedPromises.push(extractExhibitsPromise);
  }

  render() {
    const { deal, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;

    const company = deal.company;

    return (
      <div className="DealFactChecking WideView">
        <Row className="WideCanvas">
          <Col xs={12} md={2} className="WideLeft">
            <div className="mt-3 mb-3">
              <h4>Company</h4>
              <p>
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </p>

              <h4>Deal Signing Date</h4>
              <p>
                <Link to={`/extraction/${deal.id}`}>
                  {moment(deal.signingDate).format("LL")}{" "}
                </Link>
              </p>

              <h4>Deal Amend Id </h4>
              <p>{deal.dealAmendId}</p>

              <h4>Deal Type</h4>
              <p>
                {deal.dealType ? (
                  <span className="badge badge-warning">{deal.dealType}</span>
                ) : (
                  <span className="text-muted">--</span>
                )}
              </p>

              <h4>SEC Filing Link</h4>
              <p>
                {deal.secFilingLink ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={deal.secFilingLink}
                  >
                    {deal.secFilingLink}
                  </a>
                ) : (
                  <span className="text-muted">No Link Found</span>
                )}
              </p>
            </div>
          </Col>
          <Col xs={12} sm={8} className="WideCenter">
            <div className="mt-3 mb-3">
              <h2>
                Deal signing date as of {moment(deal.signingDate).format("LL")}
              </h2>
            </div>
            <hr />
            <div className="mt-3 mb-3">
              <h3>Exhibits</h3>
              <p>
                Exhibits are from 10-K, 10-Q or 8-K forms that are submitted by
                the borrower company to SEC.
              </p>
              {this.renderExhibits()}
            </div>
            <hr />
            <h4>Processing Activities</h4>
            {this.renderDealActivity()}
          </Col>
          <Col xs={12} sm={2} className="WideRight">
            <div className="mt-3 mb-3">
              <DealStatus deal={deal} />
            </div>
            <div className="mt-3 mb-3">
              <p className="text-muted mb-0">Assignee</p>
              <DealAssignment deal={deal} highlight={true} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderExhibits() {
    const { deal, exhibits, exhibitsIsLoading, exhibitError } = this.state;

    if (exhibitsIsLoading) return <Loader />;
    if (exhibitError) return <ErrorHandler error={exhibitError} />;
    if (!exhibits) return <span />;
    if (!exhibits.length)
      return (
        <Alert variant="info">There are currently no exhibits to show.</Alert>
      );

    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Exhibit No.</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Actions</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {exhibits.map((exhibit) => {
            return (
              <tr key={exhibit.id}>
                <td>
                  <Link to={`/fact-checking/${deal.id}/exhibits/${exhibit.id}`}>
                    {exhibit.exhibitName}
                  </Link>
                </td>
                <td>{moment(exhibit.createAt).format("MM/DD/YYYY")}</td>
                <td>{moment(exhibit.updatedAt).format("MM/DD/YYYY")}</td>
                <td>
                  <Link to={`/fact-checking/${deal.id}/exhibits/${exhibit.id}`}>
                    Process exhibit{" "}
                    <span className="material-icons md-18">arrow_forward</span>
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  renderDealActivity() {
    const { dealActivities, activitiesIsLoading, activitiesError } = this.state;

    if (activitiesIsLoading) return <Loader />;
    if (activitiesError) return <ErrorHandler error={activitiesError} />;
    if (!dealActivities) return <span />;
    if (!dealActivities.length)
      return (
        <Alert variant="info">There are currently no activities to show.</Alert>
      );

    return (
      <ul>
        {dealActivities.map((activity) => {
          return (
            <li key={activity.id} className="mt-2 mb-2">
              {activity.username || activity.checkedoutBy}{" "}
              <span className="text-muted">
                started on {moment(activity.checkedoutAt).format("lll")}.{" "}
              </span>
              <Fragment>
                {activity.checkedinAt ? (
                  <span className="text-muted">
                    Completed on {moment(activity.checkedinAt).format("lll")}
                  </span>
                ) : (
                  <Fragment>
                    <span className="material-icons md-16">lock</span>{" "}
                    <span className="text-muted">Incomplete.</span>
                  </Fragment>
                )}
              </Fragment>
            </li>
          );
        })}
      </ul>
    );
  }
}

DealFactChecking.propTypes = {
  dealId: PropTypes.string.isRequired
};

export default DealFactChecking;
