import React, { Component } from "react";
import "draft-js/dist/Draft.css";
import "./ExhibitAnnotation.scss";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  DefaultDraftBlockRenderMap
} from "draft-js";
import Immutable from "immutable";
import { Alert, Badge, Button, Card } from "react-bootstrap";

import { get, put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import EntitiesUtils from "./Controls/EntitiesUtils";
import ConversionUtils from "./Controls/ConversionUtils";
import BlockStylesUtils from "./Controls/BlockStylesUtils";
import AnnotatorControl from "./Controls/AnnotatorControl";

class ExhibitAnnotation extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.annotatorContainer = React.createRef();

    this.handleOnChange = this._handleOnChange.bind(this);
    this.handleOnFocus = this._handleOnFocus.bind(this);
    this.handleOnEdit = this._handleOnEdit.bind(this);

    this.handleCreateEntity = this._handleCreateEntity.bind(this);
    this.handleDiscardEntity = this._handleDiscardEntity.bind(this);
    this.handleUpdateEntity = this._handleUpdateEntity.bind(this);
    this.handleCancelEditEntity = this._handleCancelEditEntity.bind(this);

    this.handleEditEntity = this._handleEditEntity.bind(this);
    this.handleDeleteEntity = this._handleDeleteEntity.bind(this);
    this.handleAnnotatorShown = this._handleAnnotatorShown.bind(this);

    this.handleUpdateStructured = debounce(
      () => {
        this.updateDocumentStructured();
      },
      300,
      { maxWait: 1000 }
    );
    this.handleInitializeEditorState = debounce(
      () => {
        this._handleInitializeEditorState();
      },
      1000,
      { maxWait: 5000 }
    );
    this.handleConvertToStructured = debounce(
      () => {
        this._handleConvertToStructured();
      },
      1000,
      { maxWait: 5000 }
    );
  }

  _handleOnEdit(editorState) {
    if (!this.state.toggleEntity && !this.state.annotatorShown) {
      this.setState({ editorState: editorState });
    }
  }

  _handleOnChange(editorState) {
    this.handleUpdateStructured();
    this.setState({ editorState: editorState });
  }

  _handleOnFocus() {
    this.refs.annotator.focus();
  }

  _handleCreateEntity(selectionState, data) {
    this.handleOnChange(
      EntitiesUtils.createEntity(this.state.editorState, selectionState, data)
    );
  }

  _handleAnnotatorShown(shown) {
    this.setState({ annotatorShown: shown });
  }

  _handleDiscardEntity(selectionState) {
    this.setState({ toggleEntity: false });
    this.handleOnChange(
      EntitiesUtils.deleteEntity(this.state.editorState, selectionState)
    );
  }

  _handleUpdateEntity(selectionState, entityKey, data) {
    this.setState({ toggleEntity: false });
    this.handleOnChange(
      EntitiesUtils.updateEntity(
        this.state.editorState,
        selectionState,
        entityKey,
        data
      )
    );
  }

  _handleCancelEditEntity() {
    this.setState({ toggleEntity: false });
  }

  // For decorator
  _handleDeleteEntity(entityKey, blockKey, start, end) {
    const { editorState } = this.state;
    const selectionState = EntitiesUtils.getSelectionState(
      editorState,
      entityKey,
      blockKey,
      start,
      end
    );
    this.handleOnChange(
      EntitiesUtils.deleteEntity(editorState, selectionState)
    );
  }

  // For decorator
  _handleEditEntity(entityKey, blockKey, start, end) {
    const { editorState } = this.state;
    const selectionState = EntitiesUtils.getSelectionState(
      editorState,
      entityKey,
      blockKey,
      start,
      end
    );

    this.setState({
      editorState: EditorState.acceptSelection(editorState, selectionState),
      toggleEntity: true
    });
  }

  componentDidMount() {
    this.fetchStructured();
    this.fetchEntityList();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  updateDocumentStructured() {
    const { editorState } = this.state;
    const { exhibit } = this.props;

    if (!editorState) return;

    this.setState({ saving: true, savingError: false });
    const contentState = editorState.getCurrentContent();
    const documentStructured = contentState.hasText()
      ? convertToRaw(contentState)
      : null;

    const updatePromise = put(`/exhibits/${exhibit.id}/document-structured`, {
      documentStructured: documentStructured
    });

    updatePromise.promise
      .then((response) => {
        this.setState({ saving: false, savingError: false });
      })
      .catch((error) => {
        this.setState({ saving: false, savingError: true });
      });

    this.subscribedPromises.push(updatePromise);
  }

  fetchEntityList() {
    const createEntityPromise = get("credit-agreement-entity");

    this.setState({
      structuredError: "",
      structuredIsLoading: true
    });

    createEntityPromise.promise
      .then((response) => {
        this.setState({
          entities: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            structuredError: error,
            structuredIsLoading: false
          });
      });
    this.subscribedPromises.push(createEntityPromise);
  }

  fetchStructured() {
    this.setState({ structuredIsLoading: true, structuredError: "" });
    const { exhibit } = this.props;
    const createBlockPromise = get(
      `/exhibits/${exhibit.id}/document-structured`
    );
    createBlockPromise.promise
      .then((response) => {
        this.setState({
          structuredIsLoading: false,
          structuredError: "",
          structured: JSON.parse(
            JSON.stringify(response.data, (key, value) =>
              value === null ? "" : value
            )
          )
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ structuredError: error, structuredIsLoading: false });
      });
    this.subscribedPromises.push(createBlockPromise);
  }

  loadAnnotator() {
    this.setState({ annotatorIsLoading: true }, () => {
      this.handleInitializeEditorState();
    });
  }

  _handleInitializeEditorState() {
    const { structured } = this.state;
    const decorator = EntitiesUtils.getDecorators(
      this.handleEditEntity,
      this.handleDeleteEntity,
      this.annotatorContainer
    );
    let editorState = EditorState.createEmpty(decorator);
    let documentStructured = structured.documentStructured;
    if (!documentStructured) return;

    editorState = EditorState.createWithContent(
      convertFromRaw(documentStructured),
      decorator
    );
    this.setState({ editorState: editorState, annotatorIsLoading: false });
  }

  convertToStructured() {
    this.setState({ structuredIsLoading: true, structuredError: "" }, () => {
      this.handleConvertToStructured();
    });
  }

  _handleConvertToStructured() {
    const { exhibit } = this.props;

    let documentStructured = ConversionUtils.convertToStructured(
      exhibit.documentRaw
    );
    const updatePromise = put(`/exhibits/${exhibit.id}/document-structured`, {
      documentStructured: documentStructured
    });

    updatePromise.promise
      .then((response) => {
        let structured = response.data;
        structured.documentStructured = documentStructured;
        this.setState(
          {
            structuredIsLoading: false,
            structuredError: "",
            structured: structured
          },
          () => this.loadAnnotator()
        );
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            structuredError: error,
            structuredIsLoading: false
          });
      });

    this.subscribedPromises.push(updatePromise);
  }

  render() {
    const {
      saving,
      structured,
      structuredIsLoading,
      structuredError,
      editorState,
      annotatorIsLoading,
      annotatorShown,
      toggleEntity,
      entities
    } = this.state;

    if (structuredIsLoading)
      return (
        <div>
          <Loader />
          <p className="text-center">
            Converting document to a structured format for annotating ...
          </p>
        </div>
      );
    if (structuredError) return <ErrorHandler error={structuredError} />;
    if (!structured) return <span />;
    if (!structured.documentStructured)
      return (
        <div className="mt-3 mb-3">
          <Alert variant="info" className="text-center">
            <p>
              The exhibit content has not been processed into a structured
              format yet.
            </p>
            <Button
              variant="primary"
              size="sm"
              onClick={() => this.convertToStructured()}
            >
              Convert To structured
            </Button>
          </Alert>
        </div>
      );
    if (annotatorIsLoading)
      return (
        <div>
          <Loader />
          <p className="text-center">Loading annotation component ...</p>
        </div>
      );

    if (!editorState) {
      return (
        <div className="mt-3 mb-3 text-center">
          <Alert variant="info" className="text-center">
            <p>
              The annotator provides ability to select text and provide an note.
            </p>
            <Button variant="primary" onClick={() => this.loadAnnotator()}>
              <span className="material-icons mr-1">text_format</span>
              Load Document Annotator
            </Button>
            <br />
            <Button
              variant="link"
              size="sm"
              onClick={() => this.convertToStructured()}
            >
              Reset
            </Button>
          </Alert>
        </div>
      );
    }

    return (
      <div className="ExhibitAnnotation">
        {editorState && (
          <div className="RichEditorControls-root ml-1">
            <AnnotatorControl
              editorState={editorState}
              onCreateEntity={this.handleCreateEntity}
              onDiscardEntity={this.handleDiscardEntity}
              onUpdateEntity={this.handleUpdateEntity}
              onCancelEditEntity={this.handleCancelEditEntity}
              entities={entities}
              toggleEntity={toggleEntity}
              onAnnotatorShown={this.handleAnnotatorShown}
            />
          </div>
        )}
        {saving && (
          <div className="SavingStatus pt-1 pl-1 pr-1 pb-1">
            <Badge variant="warning">Saving...</Badge>
          </div>
        )}
        <div className="text-right">
          <Button
            variant="outline-danger"
            size="xs"
            onClick={() => this.convertToStructured()}
          >
            <span className="material-icons mr-1">error_outline</span>Reset and
            refresh document
          </Button>{" "}
        </div>
        {editorState && (
          <Card
            className={`mt-3 mb-3 ${(annotatorShown || toggleEntity) &&
              `annotator-backdrop`}`}
            key="editor"
          >
            <Card.Body
              className="document-annotation"
              ref={this.annotatorContainer}
            >
              <div className="RichEditor-editor">
                <Editor
                  editorState={editorState}
                  onChange={this.handleOnEdit}
                  handleKeyCommand={() => "handled"}
                  handleBeforeInput={() => "handled"}
                  handlePastedText={() => true}
                  blockStyleFn={BlockStylesUtils.getBlockStyle}
                  blockRendererFn={BlockRenderer}
                  blockRenderMap={extendedBlockRenderMap}
                  container={this.annotatorContainer}
                  ref="annotator"
                  style={
                    this.state.annotatorShown ? { pointerEvents: "none" } : {}
                  }
                />
              </div>
            </Card.Body>
          </Card>
        )}
      </div>
    );
  }
}

ExhibitAnnotation.propTypes = {
  exhibit: PropTypes.object.isRequired
};

export default ExhibitAnnotation;

class TableBlock extends React.Component {
  render() {
    const { block, contentState } = this.props;
    const entityKey = block.getEntityAt(0);
    const data = entityKey ? contentState.getEntity(entityKey).getData() : {};

    return (
      <div className="mt-3 mb-3">
        {data?.node ? (
          <div dangerouslySetInnerHTML={{ __html: data?.node }} />
        ) : (
          <div className="alert alert-secondary">{block.getText()}</div>
        )}
      </div>
    );
  }
}

function BlockRenderer(contentBlock) {
  const type = contentBlock.getType();
  if (type === "TABLE") {
    return {
      component: TableBlock,
      editable: false
    };
  }
}

const blockRenderMap = Immutable.Map({
  "header-one": {
    element: "h1"
  },
  "header-two": {
    element: "h2"
  },
  "header-three": {
    element: "h3"
  },
  "header-four": {
    element: "h4"
  },
  "header-five": {
    element: "h5"
  },
  "header-six": {
    element: "h6"
  },
  "unordered-list-item": {
    element: "li",
    wrapper: "ul"
  },
  "ordered-list-item": {
    element: "li",
    wrapper: "ol"
  },
  blockquote: {
    element: "blockquote"
  },
  code: {
    element: "pre"
  },
  atomic: {
    element: "figure"
  },
  unstyled: {
    element: "div"
  },
  TABLE: {
    element: "div"
  },

  HORIZONTAL_RULE: {
    element: "div",
    wrapper: (
      <div>
        <hr />
      </div>
    )
  }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);
