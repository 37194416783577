import React, { Fragment } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Popover, Overlay, Button, Badge } from "react-bootstrap";

class EntityDecorator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleEdit = this._handleEdit.bind(this);
    this.handleDelete = this._handleDelete.bind(this);

    this.handleShow = this._handleShow.bind(this);
    this.handleHide = this._handleHide.bind(this);

    this.decoratorRef = React.createRef();
  }

  _handleShow() {
    this.setState({ showDecorator: true });
  }

  _handleHide() {
    this.setState({ showDecorator: false });
  }

  _handleDelete() {
    const { discardEntity, entityKey, blockKey, start, end } = this.props;
    discardEntity(entityKey, blockKey, start, end);
    this.handleHide();
  }

  _handleEdit() {
    const { updateEntity, entityKey, blockKey, start, end } = this.props;
    updateEntity(entityKey, blockKey, start, end);
    this.handleHide();
  }

  render() {
    const { contentState, entityKey, children } = this.props;
    const { showDecorator } = this.state;

    const entity = contentState.getEntity(entityKey);
    const entityData = entity.getData() || {};

    let comment = entityData.comment || "";
    let confidenceLevel = entityData.confidenceLevel;
    let selectedEntity = entityData.selectedEntity;
    let entityName = entityData.entityName || "";
    let comments = entityData.comments || [];

    let hasUnreadComment = comments.filter((item) => !item.read);
    return (
      <span
        className={`EntityDecorator confidence-${confidenceLevel} ${
          hasUnreadComment.length ? "has-unread-comment" : ""
        }`}
      >
        {!!hasUnreadComment.length && (
          <span className="unread-count-badge">{hasUnreadComment.length}</span>
        )}
        <span
          className="comment-entity"
          onClick={this.handleShow}
          ref={this.decoratorRef}
        >
          {children}
        </span>
        <Overlay
          container={this.decoratorRef.current}
          target={this.decoratorRef.current}
          show={showDecorator}
          onHide={this.handleHide}
          placement="bottom"
          containerPadding={20}
          rootClose={true}
          rootCloseEvent={"click"}
        >
          <Popover
            placement="bottom"
            id={`entity-${entityKey}`}
            className="EntityDecoratorPopover"
          >
            <Popover.Content>
              {!!selectedEntity &&
                selectedEntity.map((entity) => (
                  <Badge key={entity.id} variant="primary" className="mr-1">
                    {entity.entityName}
                  </Badge>
                ))}
              <Badge variant="default">{entityName}</Badge>
              {comment && <p className="mb-1">{comment}</p>}
              {!selectedEntity?.length && !comment && !entityName ? (
                <code>n/a</code>
              ) : (
                <span />
              )}
              {!!comments.length && (
                <Fragment>
                  <hr className="mt-1 mb-1" />
                  <h5>
                    <strong>Comments</strong>
                  </h5>
                </Fragment>
              )}
              {comments.map((item) => (
                <blockquote
                  key={item.id}
                  className={item.read ? "comment-read" : "comment-unread"}
                >
                  <strong>{item.username}</strong>{" "}
                  <span className="float-right">
                    {moment(item.updatedAt).format("lll")}
                  </span>
                  <hr className="mt-1 mb-1" />
                  <p>{item.text}</p>
                </blockquote>
              ))}
              <hr className="mt-1 mb-1" />
              <p className="mb-0 text-right">
                <Button onClick={this.handleDelete} size="xs" variant="link">
                  Discard
                </Button>{" "}
                <Button onClick={this.handleEdit} size="xs" variant="primary">
                  Edit
                </Button>
              </p>
            </Popover.Content>
          </Popover>
        </Overlay>
      </span>
    );
  }
}

EntityDecorator.propTypes = {
  container: PropTypes.object.isRequired,
  updateEntity: PropTypes.func.isRequired,
  discardEntity: PropTypes.func.isRequired
};

export default EntityDecorator;
