import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import IntelligenceDashboard from "./IntelligenceDashboard/IntelligenceDashboard";
import { Container } from "react-bootstrap";
import Header from "../App/Header/Header";
import Search from "./Search/Search";

class Intelligence extends Component {
  render() {
    return (
      <div className="Main">
        <Header user={this.props.user} title={"Intelligence"} />
        <div className="MainContent">
          <Container>
            <Switch>
              <Route
                exact
                path="/intelligence/:dealId"
                render={props => (
                  <IntelligenceDashboard dealId={props.match.params.dealId} />
                )}
              />
              <Route
                exact
                path="/intelligence/:dealId/search"
                render={props => (
                  <Search dealId={props.match.params.dealId} {...props} />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 className="text-center mt-xl mb-xl">
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default Intelligence;
