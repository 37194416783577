import React, { Component, Fragment } from "react";
import { Alert, Col, Button, Form, Modal } from "react-bootstrap";
import { post } from "../../../../../utils/DeApi";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "../../../../Loader/Loader";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import BankCreate from "../../../../Banks/BankCreate/BankCreate";

class LenderCreate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bankNameValid: false,
      leadArrangerValid: false,
      agentOnlyValid: false,
      agentCoAgentValid: false,
      success: false,
    };

    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleBankNameChange = this._handleBankNameChange.bind(this);
    this.handlePrimaryRoleChange = this._handlePrimaryRoleChange.bind(this);
    this.handleTrancheChange = this._handleTrancheChange.bind(this);
    this.handleBankCommitmentMinChange = this._handleBankCommitmentMinChange.bind(
      this
    );
    this.handleBankCommitmentMaxChange = this._handleBankCommitmentMaxChange.bind(
      this
    );
    this.handleLeadArrangerChange = this._handleLeadArrangerChange.bind(this);
    this.handleAgentOnlyChange = this._handleAgentOnlyChange.bind(this);
    this.handleAgentCoAgentChange = this._handleAgentCoAgentChange.bind(this);
    this.handleSecondaryRoles = this._handleSecondaryRolesChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
    this.onBankCreated = this._onBankCreated.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    this.setValues();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trancheId !== prevProps.trancheId) {
      this.setValues();
    }
  }

  setValues() {
    const { trancheType, trancheAmount, trancheId, deal, banks, roles } = this.props;
    if (deal) {
      this.setState({
        deal,
        banks,
        roles,
        trancheType,
        trancheAmount,
        trancheId
      });
    }
  }

  _handleBankNameChange(banks) {
    banks.length
      ? this.setState({
        bankName: banks[0].bankName,
        bankNameValid: banks[0].bankName.length >= 2 ? true : false
      })
      : this.setState({
        bankName: null,
        bankNameValid: false
      });
  }

  _onBankCreated(bank = {}) {
    const { banks } = this.state;
    let mutableList = [...banks];
    let index = banks.findIndex((item) => {
      return item.id === bank.id;
    });

    if (index < 0) mutableList.unshift(bank);
    else mutableList[index] = bank;

    this.setState({
      banks: mutableList
    });
  }

  _handleBankCommitmentMinChange(event) {
    let bankCommitmentMin = event.target.value;
    this.setState({
      bankCommitmentMin: bankCommitmentMin
    });
  }

  _handleBankCommitmentMaxChange(event) {
    let bankCommitmentMax = event.target.value;
    this.setState({
      bankCommitmentMax: bankCommitmentMax
    });
  }

  _handleLeadArrangerChange(event) {
    let leadArranger = event.target.value;
    this.setState({
      leadArranger: leadArranger,
      leadArrangerValid:
        leadArranger === "y" || leadArranger === "n" ? true : false
    });
  }

  _handleAgentOnlyChange(event) {
    let agentOnly = event.target.value;
    this.setState({
      agentOnly: agentOnly,
      agentOnlyValid: agentOnly === "y" || agentOnly === "n" ? true : false
    });
  }

  _handleAgentCoAgentChange(event) {
    let agentCoAgent = event.target.value;
    this.setState({
      agentCoAgent: agentCoAgent,
      agentCoAgentValid:
        agentCoAgent === "y" || agentCoAgent === "n" ? true : false
    });
  }

  _handleTrancheChange(event) {
    let trancheId = event.target.value;
    this.setState({ trancheId });
  }

  _handlePrimaryRoleChange(roles) {
    roles.length
      ? this.setState({
        primaryRoleId: roles[0].id,
        primaryRoleValid: roles[0].id.length >= 2
      })
      : this.setState({
        primaryRoleId: null,
        primaryRoleValid: false
      });
  }

  _handleSecondaryRolesChange(roles) {
    let secondaryRoles = [];
    roles.forEach((role) => {
      secondaryRoles.push(role.id);
    });

    this.setState({
      secondaryRoles
    });
  }



  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const {
          bankName,
          bankNameValid,
          primaryRoleId,
          bankCommitmentMin,
          leadArranger,
          trancheId,
          agentOnly,
          agentCoAgent,
          secondaryRoles,
          primaryRoleValid
        } = this.state;
        if (bankNameValid && primaryRoleValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = post("/lenders", {
            trancheId,
            bankName,
            bankCommitmentMin,
            bankCommitmentMax: bankCommitmentMin,
            leadArranger: leadArranger,
            agentOnly,
            agentCoAgent,
            primaryRoleId,
            secondaryRoles
          });

          createPromise.promise
            .then((response) => {
              this.setState(
                {
                  isLoading: false,
                  error: "",
                  showModal: false,
                },
                () => {
                  this.props.onLenderCreated(response.data);
                }
              );
            })
            .catch((error) => {
              this.setState({
                error: error,
                isLoading: false
              });
            });

          this.subscribedPromises.push(createPromise);
        } else {
          console.log("Issue");
        }
      }
    );
  }

  render() {
    const { isLoading, deal } = this.state;

    if (!deal) return <span />;

    return (
      <Fragment>
        <Button
          className="float-right mb-3"
          variant="primary"
          onClick={this.toggleModal}
          size="sm"
        >
          New Lender
        </Button>

        <Modal show={this.state.showModal} size="lg" onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create Lender</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderLenderCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderLenderCreate() {
    const {
      error,
      success,
      isLoading,
      bankNameValid,
      bankCommitmentMin,
      primaryRoleValid,
      trancheType,
      trancheAmount,
      formSubmitted,
      banks,
      roles
    } = this.state;

    if (isLoading) return <Loader />;
    
    if (!banks) return <span />;

    if (error) return <ErrorHandler error={error} />;

    return (
      <Form.Row>
        <Col xs={12}>
          <div className="float-right">
            <BankCreate
              onBankCreated={this.onBankCreated}
            />
          </div>
        </Col>
        <Form.Group as={Col} md="6">
          <Form.Label>Tranche</Form.Label>
          <div>
            {trancheType} {trancheAmount}
          </div>
        </Form.Group>

        <Form.Group as={Col} xs="6">
          <Form.Label>Bank Name</Form.Label>
          <Typeahead
            id="1234"
            labelKey={(option) => option.bankName}
            onChange={this.handleBankNameChange}
            isInvalid={!bankNameValid && formSubmitted}
            options={banks}
            placeholder="Search Bank"
          />
          <Form.Control.Feedback type="invalid">
            Bank is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Bank Commitment Min</Form.Label>
          <Form.Control
            type="text"
            value={bankCommitmentMin}
            placeholder="Enter Bank Commitment"
            onChange={this.handleBankCommitmentMinChange}
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Bank Commitment Max</Form.Label>
          <Form.Control
            disabled
            type="text"
            value={bankCommitmentMin}
            onChange={this.handleBankCommitmentMaxChange}
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Primary Role</Form.Label>
          <Typeahead
            id="987622"
            labelKey={(option) => option.title}
            onChange={this.handlePrimaryRoleChange}
            isInvalid={!primaryRoleValid && formSubmitted}
            options={roles.filter((role) => {
              return role.isPrimary === 1;
            })}
            placeholder="Search Primary Role..."
          />
          <Form.Control.Feedback type="invalid">
            Primary Role is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Secondary Role</Form.Label>
          <Typeahead
            id="9876221"
            multiple
            labelKey={(option) => option.title}
            onChange={this.handleSecondaryRoles}
            isInvalid={!primaryRoleValid && formSubmitted}
            options={roles.filter((role) => {
              return role.isSecondary === 1;
            })}
            placeholder="Search Secondary Role..."
          />
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Lead Arranger</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="lead-arranger"
              onChange={this.handleLeadArrangerChange}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="lead-arranger"
              onChange={this.handleLeadArrangerChange}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Agent Only</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="agent-only"
              onChange={this.handleAgentOnlyChange}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="agent-only"
              onChange={this.handleAgentOnlyChange}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Agent Co Agent</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="agent-co-agent"
              onChange={this.handleAgentCoAgentChange}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="agent-co-agent"
              onChange={this.handleAgentCoAgentChange}
            />
          </div>
        </Form.Group>

        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Job Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

export default LenderCreate;
