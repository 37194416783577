import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import ExtractionDashboard from "./ExtractionDashboard/ExtractionDashboard";
import ExhibitDetails from "./ExhibitDetails/ExhibitDetails";
import { Container } from "react-bootstrap";
import Header from "../App/Header/Header";

class Extraction extends Component {
  render() {
    return (
      <div className="Main">
        <Header fluid={true} user={this.props.user} title={"Extraction"} />
        <div className="MainContent">
          <Container fluid>
            <Switch>
              <Route
                exact
                path="/extraction/:dealId"
                render={(props) => (
                  <ExtractionDashboard dealId={props.match.params.dealId} />
                )}
              />
              <Route
                exact
                path="/extraction/:dealId/exhibits/:exhibitId/document-raw"
                component={(props) => (
                  <ExhibitDetails
                    dealId={props.match.params.dealId}
                    exhibitId={props.match.params.exhibitId}
                  />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 className="text-center mt-xl mb-xl">
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default Extraction;
