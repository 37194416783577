import React, { Component, Fragment } from "react";
import { Col, Table, Alert, Button, Row, Spinner } from "react-bootstrap";
import numeral from "numeral";
import { get } from "../../../../utils/DeApi";

import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import LenderCreate from "./LenderCreate/LenderCreate";
import LenderEdit from "./LenderEdit/LenderEdit";

class Lenders extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    this.onLenderCreated = this._onLenderCreated.bind(this);
    this.onLenderUpdated = this._onLenderUpdated.bind(this);
  }

  componentDidMount() {
    const { deal } = this.props;
    if (deal) {
      this.setState({ deal });
    }
    this.fetchTranche();
    this.fetchBanks();
    this.fetchLenderRoles();
  }

  componentDidUpdate(prevProps) {
    if (this.props.trancheId !== prevProps.trancheId) {
      this.fetchTranche();
    }
  }

  fetchBanks() {
    this.setState({
      sync: true,
      error: ""
    });

    const createBankPromise = get("/banks", {});
    createBankPromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          error: "",
          banks: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createBankPromise);
  }

  fetchLenderRoles() {
    this.setState({
      sync: true,
      error: ""
    });

    const createLenderRolePromise = get("/lender-roles", {});
    createLenderRolePromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          error: "",
          roles: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createLenderRolePromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _onLenderCreated(lender = {}) {
    const { lenders } = this.state;
    let mutableList = [...lenders];

    mutableList.push(lender);

    this.setState({
      lenders: mutableList
    });
  }

  _onLenderUpdated(lender = {}) {
    const { lenders } = this.state;
    let mutableList = [...lenders];
    let index = lenders.findIndex((item) => {
      return item.id === lender.id;
    });

    mutableList[index] = lender;

    this.setState({
      lenders: mutableList
    });
  }

  fetchTranche() {
    this.setState({
      sync: true,
      isLoading: true,
      error: ""
    });
    const { trancheId } = this.props;
    const createTranchePromise = get(`/tranches/${trancheId}`, {});
    createTranchePromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          isLoading: false,
          error: "",
          lenders: response.data.lenders,
          trancheType: response.data.type.name,
          typeId: response.data.type.id,
          trancheAmount: response.data.amount
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createTranchePromise);
  }

  runRules() {
    this.setState({
      sync: true,
      isRunningRules: true,
      flagMessage: null,
      error: ""
    });
    const { trancheId } = this.props;
    const runRulePromise = get(`/tranches/${trancheId}/rules/leadFlags`, {});
    runRulePromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          isRunningRules: false,
          error: "",
          flagMessage: response.data
        }, () => {
          this.fetchTranche();
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(runRulePromise);
  }

  renderLenders() {
    const {
      error,
      isLoading,
      lenders,
      deal,
      trancheAmount,
      trancheType,
      roles,
      banks
    } = this.state;

    if (isLoading)
      return (
        <Col xs={12}>
          <Loader />
        </Col>
      );
    if (error)
      return (
        <Col xs={12}>
          <ErrorHandler error={error} />
        </Col>
      );

    return (
      <Fragment>
        <Col xs={12} />
        {lenders.length ? (
          <Col xs={12}>
            <Table striped responsive size="sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Bank Name</th>
                  <th>Primary Role</th>
                  <th>Secondary Roles</th>
                  <th>Min. Bank Commitment</th>
                  <th>Max. Bank Commitment</th>
                  <th>Lead Arranger</th>
                  <th>Agent Only</th>
                  <th>Agent/Co Agent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {lenders.map((lender) => {
                  return (
                    <tr key={lender.id}>
                      <td>{lender.id}</td>
                      <td>{lender.bankName}</td>
                      <td>{lender.primaryRole.title}</td>
                      <td>
                        {lender.secondaryRole.length > 0 &&
                          lender.secondaryRole.map((role) => (
                            <div key={role.id}>{role.title}</div>
                          ))}
                      </td>
                      <td>{numeral(lender.bankCommitmentMin).format("0,0")}</td>
                      <td>{numeral(lender.bankCommitmentMax).format("0,0")}</td>
                      <td>{lender.leadArranger === "y" ? "Yes" : "No"}</td>
                      <td>{lender.agentOnly === "y" ? "Yes" : "No"}</td>
                      <td>{lender.agentCoAgent === "y" ? "Yes" : "No"}</td>
                      <td>
                        <LenderEdit
                          lender={lender}
                          deal={deal}
                          onLenderUpdated={this.onLenderUpdated}
                          trancheAmount={trancheAmount}
                          trancheType={trancheType}
                          trancheId={this.props.trancheId}
                          roles={roles}
                          banks={banks}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        ) : (
            <Col xs={12}>
              <Alert variant="info">
                <p>No Lender founds</p>
              </Alert>
            </Col>
          )}
      </Fragment>
    );
  }

  render() {
    const {
      flagMessage,
      isRunningRules,
      banks,
      trancheAmount,
      deal,
      roles,
      lenders,
      trancheType
    } = this.state;

    if (!deal) return <span />;

    if (!roles) return <span />;

    if (!lenders) return <span />;

    if (!banks) return <span />;

    return (
      <Fragment>
        <Row>
          <Col xs="12" className="mb-2">
            <LenderCreate
              deal={deal}
              onLenderCreated={this.onLenderCreated}
              trancheAmount={trancheAmount}
              trancheType={trancheType}
              trancheId={this.props.trancheId}
              roles={roles}
              banks={banks}
            />{" "}
            <Button
              variant="outline-success"
              className="float-right mr-1"
              size="sm"
              disabled={!lenders.length}
              onClick={() => {
                this.runRules();
              }}
            >
              {isRunningRules && (
                <Spinner animation="border" size="sm" className="mr-1" />
              )}
              Run Lead Flag Rule
            </Button>
            <h4>Tranche Bank List</h4>
          </Col>
          <Col xs="12">
            {flagMessage && (
              <Alert
                show={flagMessage.length}
                variant="success"
                dismissible
                onClose={() => {
                  this.setState({ flagMessage: null });
                }}
              >
                <ul>
                  {flagMessage.map((bank) => {
                    return (
                      <li>
                        {bank.bankName}
                        {" - "}
                        {bank.response}
                      </li>
                    );
                  })}
                </ul>
              </Alert>
            )}
          </Col>
          {this.renderLenders()}
        </Row>
      </Fragment>
    );
  }
}

export default Lenders;
