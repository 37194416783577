import React, { Component, Fragment } from "react";
import "./Entities.scss";
import Dialog from "react-bootstrap-dialog";
import moment from "moment";

import {
  Alert,
  Table,
  Button,
  Col,
  Row,
  Container,
  Form
} from "react-bootstrap";

import { get, destroy } from "../../utils/DeApi";

import Header from "../App/Header/Header";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

import EntityCreate from "./EntityCreate/EntityCreate";
import EntityEdit from "./EntityEdit/EntityEdit";

class Entities extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { query: "" };

    this.handleEntityCreated = this._handleEntityCreated.bind(this);
    this.handleEntityEdited = this._handleEntityEdited.bind(this);
    this.handleEntityDeleted = this._handleEntityDeleted.bind(this);

    this.handleSearch = this._handleSearch.bind(this);
  }

  componentDidMount() {
    this.fetchEntities();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleEntityCreated(entity = {}) {
    const { entities } = this.state;
    let mutableList = [...entities];
    mutableList.push(entity);

    this.setState({
      entities: mutableList
    });
  }

  _handleEntityEdited(entity = {}) {
    const { entities } = this.state;
    let mutableList = [...entities];
    let index = entities.findIndex((item) => {
      return item.id === entity.id;
    });
    mutableList[index] = entity;

    this.setState({
      entities: mutableList
    });
  }

  _handleEntityDeleted(entityId) {
    let { entities } = this.state;
    entities = entities.filter((entity) => entity.id !== entityId);

    this.setState({
      entities
    });
  }

  _handleSearch(event) {
    let query = event.target.value;
    const { entities } = this.state;
    const searchResults = entities.filter((item) => {
      return item.entityName.toLowerCase().search(query.toLowerCase()) === 0
        ? item
        : null;
    });

    this.setState({ searchResults: searchResults, query: query });
  }

  onDelete(entityId) {
    this.dialog.show({
      title: "Delete",
      body: "Are you sure?",
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          this.deleteEntity(entityId);
        })
      ]
    });
  }

  fetchEntities() {
    this.setState({ isLoading: true, error: "", searchResults: null });

    const getEntitiesPromise = get("/credit-agreement-entity");
    getEntitiesPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          entities: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getEntitiesPromise);
  }

  deleteEntity(entityId) {
    const deleteEntityPromise = destroy(`/credit-agreement-entity/${entityId}`);

    deleteEntityPromise.promise
      .then(() => {
        this.handleEntityDeleted(entityId);
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(deleteEntityPromise);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="Main">
        <Header user={user} title={"Entites"} />
        <div className="MainContent Entities">
          <Container>
            <Row>
              <Col xs="12" className="mb-3 mt-3">
                <span className="float-right">
                  <EntityCreate onEntityCreated={this.handleEntityCreated} />
                </span>
                <h2 className="mb-4">Entities</h2>
                {this.renderEntities()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderEntities() {
    const { query, entities, searchResults, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!entities)
      return <Alert variant="info">There are currently no entities.</Alert>;

    let filteredEntities = searchResults || entities;
    return (
      <Fragment>
        <Form.Group>
          <Form.Control
            type="text"
            name="query"
            value={query}
            onChange={this.handleSearch}
            placeholder="Filter..."
          />
        </Form.Group>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Entity Name</th>
              <th>Document Section</th>
              <th>Blues Section</th>
              <th>Blues Comment</th>

              <th>Updated At</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredEntities.map((entity) => {
              return (
                <tr key={entity.id}>
                  <td>{entity.entityName}</td>
                  <td>{entity.documentSection}</td>
                  <td>{entity.bluesSection}</td>
                  <td>{entity.bluesComment}</td>
                  <td>{moment(entity.updatedAt).format("MM/DD/YYYY")}</td>
                  <td className="text-right text-no-wrap">
                    <EntityEdit
                      entity={entity}
                      onEntityEdited={this.handleEntityEdited}
                    />{" "}
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => this.onDelete(entity.id)}
                    >
                      Delete
                    </Button>
                    <Dialog
                      ref={(component) => {
                        this.dialog = component;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

export default Entities;
