import React, { Component, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import "./App.css";
import Login from "./Login/Login";
import { isLoggedIn } from "../../utils/BeeApi";
import { isLoggedIn as isLoggedInDE } from "../../utils/DeApi";
import ForgotPassword from "./ForgotPassword/ForgotPassword";
import ResetPassword from "./ResetPassword/ResetPassword";
import Dashboard from "../Dashboard/Dashboard";
import Logout from "./Logout/Logout";
import Extraction from "../Extraction/Extraction";
import Packaging from "../Packaging/Packaging";
import Delivery from "../Delivery/Delivery";
import Intelligence from "../Intelligence/Intelligence";
import Banks from "../Banks/Banks";
import Deals from "../Deals/Deals";
import Companies from "../Companies/Companies";
import Entities from "../Entities/Entities";
import FactChecking from "../FactChecking/FactChecking";
import Knowledge from "../Knowledge/Knowledge";

/**
 * Handles application level routing.
 * It sets a middleware for private and public routes.
 */
class App extends Component {
  render() {
    return (
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/login-help" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/fact-checking" component={FactChecking} />
          <PrivateRoute path="/banks" component={Banks} />
          <PrivateRoute path="/companies" component={Companies} />
          <PrivateRoute path="/extraction" component={Extraction} />
          <PrivateRoute path="/packaging" component={Packaging} />
          <PrivateRoute path="/delivery" component={Delivery} />
          <PrivateRoute path="/intelligence" component={Intelligence} />
          <PrivateRoute path="/deals" component={Deals} />
          <PrivateRoute path="/entities" component={Entities} />
          <PrivateRoute path="/knowledge" component={Knowledge} />

          <Route component={NoMatch} />
        </Switch>
      </Router>
    );
  }
}

/**
 * Middleware for private routes.
 */
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      let user = isLoggedIn();
      if (user && isLoggedInDE()) {
        if (user.categories.includes("5f21d963d2c77")) {
          return <FactChecking {...props} user={user} />;
        }
        return <Component {...props} user={user} />;
      }
      return (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      );
    }}
  />
);

/**
 * 404: No match route
 */
const NoMatch = ({ location }) => (
  <div className="text-center mt-5 mb-5">
    <code>
      <h3>No match for {location.pathname}</h3>
      <hr />
      <p>The link is broken or the page has been moved.</p>
      <p>
        <a href="/">Got to dashboard</a>
      </p>
    </code>
  </div>
);

// Scroll To Top on Navigation
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default App;
