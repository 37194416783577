import React, { Component } from "react";
import { Form, Spinner, Button } from "react-bootstrap";

import { post, put } from "../../../utils/DeApi";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DealFeedback extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
        formSubmitted: true,
        body: props.editingFeedback.hasOwnProperty("id") ? props.editingFeedback.body : "",
        editingFeedback: props.editingFeedback.hasOwnProperty("id") ? props.editingFeedback : {}
    };
    //console.log(props.editingFeedback.hasOwnProperty("id"));
    this.handleBodyChange = this._handleBodyChange.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
  }

  componentDidUpdate(preProps, preState)
  {
    if(preProps.editingFeedback.id != this.props.editingFeedback.id)
    {
      //console.log(preProps.editingFeedback.id)
      this.setState({
        body: this.props.editingFeedback.body,
        editingFeedback: this.props.editingFeedback
      });
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleBodyChange(event) {
    let body = event.target.value;
    this.setState({
      body: body,
      bodyValid: body.length > 2
    });
  }
  
  _handleSubmit(event) {
    event.preventDefault();

    const {
      body,
      bodyValid, 
      editingFeedback
    } = this.state;

    if(editingFeedback.hasOwnProperty("id"))
    {
        this.updateFeedback();
        return;
    }
    let { deal } = this.props;
    
    this.setState({ formSubmitted: true });
    if (!bodyValid) return;

    this.setState({ error: "" });
    const createPromise = post("/deal-reviews", {
      dealId: deal,
      body: body,
      status: "ok"
    });

    createPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            dealFeedback: response.data,
            body: ""
          }
        );
        this.props.onFeedbackCreated()
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error});
      });

    this.subscribedPromises.push(createPromise);
  }

  updateFeedback()
  {
    const {
      body,
      bodyValid, 
      editingFeedback
    } = this.state;

    this.setState({ formSubmitted: true });
    if (!bodyValid) return;

    let { deal } = this.props;

    this.setState({ error: "" });
    const createPromise = put(`/deal-reviews/${editingFeedback.id}`, {
      body: body,
      status: "ok",
      dealId: deal
    });

    createPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            dealFeedback: response.data,
            body: ""
          }
        );
        this.props.onFeedbackCreated()
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error});
      });

    this.subscribedPromises.push(createPromise);
  }

  render() {
    const { error, isLoading, bodyValid, formSubmitted, body, editingFeedback } = this.state;
   // const { deal } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Spinner size="xs" />;

    let buttonText = editingFeedback.hasOwnProperty("id") ? "Update" : "Create";

      return(
        <Form>
        <Form.Group>
          <Form.Control
            as="textarea"
            rows={3}
            name="body"
            value={body}
            placeholder="Write Feedback"
            isInvalid={!bodyValid && formSubmitted}
            onChange={this.handleBodyChange}
          />
          <Form.Control.Feedback type="invalid">
            Feeback is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Button variant="primary" onClick={this.handleSubmit}>
                {buttonText}
          </Button>
        </Form.Group>
        </Form>
      )
  }
}

export default DealFeedback;
