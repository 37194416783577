import React, { Component } from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./CompanyDetails.css";
import moment from "moment";

import { get } from "../../../utils/DeApi";

import CompanyDeals from "../CompanyDeals/CompanyDeals";
import CompanyEdit from "../CompanyEdit/CompanyEdit";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleCompanyUpdated = this._handleCompanyUpdated.bind(this);
    this.handleDealCreated = this._handleDealCreated.bind(this);
  }

  _handleCompanyUpdated(company) {
    this.setState({ company: company });
  }

  _handleDealCreated(deal) {
    this.fetchCompany();
  }

  componentDidMount() {
    this.fetchCompany();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCompany() {
    this.setState({ isLoading: true, error: "" });
    const { companyId } = this.props;
    const getCompanyPromise = get(`/companies/${companyId}`);
    getCompanyPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          company: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          isLoading: false
        });
      });

    this.subscribedPromises.push(getCompanyPromise);
  }

  render() {
    let { company, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!company) return <span />;

    return (
      <Row>
        <Col xs={12} className="mt-2 mb-2">
          <ul className="list-inline">
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to="/">Dashboard</Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">Company Overview</h4>
            </li>
          </ul>
          <hr />
          <div className="mt-3 mb-3">
            <span className="float-right mr-1">
              <CompanyEdit
                company={company}
                label={"Manage Company"}
                onCompanyUpdated={this.handleCompanyUpdated}
              />
            </span>
            <h3>{company.companyName}</h3>
          </div>
          <Row>
            <Col xs={6} sm={4} lg={3}>
              <span className="text-muted">CIK</span>
              <p>
                {company.cik}{" "}
                <small>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.sec.gov/cgi-bin/browse-edgar?CIK=${
                      company.cik
                    }`}
                  >
                    SEC{" "}
                    <span className="material-icons md-18">open_in_new</span>
                  </a>
                </small>
              </p>
            </Col>

            <Col xs={6} sm={4} lg={3}>
              <span className="text-muted">Created At</span>
              <p>{moment(company.createdAt).format("LLL")}</p>
            </Col>
            <Col xs={6} sm={4} lg={3} />
            <Col xs={6} sm={4} lg={3} />
            <Col xs={6} sm={4} lg={3} />
          </Row>
          <hr />
          <div className="mt-3 mb-3">
            <CompanyDeals company={company} />
          </div>
        </Col>
      </Row>
    );
  }
}

export default CompanyDetails;
