import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import DeliveryDashboard from "./DeliveryDashbooard/DeliveryDashboard";
import { Container } from "react-bootstrap";
import Header from "../App/Header/Header";

class Delivery extends Component {
  render() {
    return (
      <div className="Main">
        <Header user={this.props.user} title={"Delivery"} />
        <div className="MainContent">
          <Container>
            <Switch>
              <Route
                exact
                path="/delivery/:dealId"
                render={props => (
                  <DeliveryDashboard dealId={props.match.params.dealId} />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 className="text-center mt-xl mb-xl">
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default Delivery;
