import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EntityAnnotator from "./EntityAnnotator";
import { Overlay, Popover } from "react-bootstrap";
import getFragmentFromSelection from "draft-js/lib/getFragmentFromSelection";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";

import Loader from "../../../Loader/Loader";

class AnnotatorControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showAnnotator: false };

    this.handleShow = this._handleShow.bind(this);
    this.handleHide = this._handleHide.bind(this);

    this.handleCreateAnnotation = this._handleCreateAnnotation.bind(this);
    this.handleDiscardAnnotation = this._handleDiscardAnnotation.bind(this);
    this.handleUpdateAnnotation = this._handleUpdateAnnotation.bind(this);
    this.handleDeleteAnnotation = this._handleDeleteAnnotation.bind(this);

    this.controlRef = React.createRef();
  }

  _handleShow() {
    const selectedText = this.getSelectedText(this.props.editorState);
    if (selectedText.trim()) {
      this.setState({ showAnnotator: true }, () => {
        this.handleCreateAnnotation();
        this.props.onAnnotatorShown(true);
      });
    }
  }

  _handleHide() {
    this.setState({ showAnnotator: false }, () => {
      this.props.onAnnotatorShown(false);
      this.handleDiscardAnnotation();
    });
  }

  _handleCreateAnnotation() {
    const { editorState, onCreateEntity } = this.props;
    let entityData = this.getEntityDataAtSelection(editorState);
    if (!entityData || isEmpty(entityData)) {
      onCreateEntity(editorState.getSelection(), null);
    }
  }

  _handleDiscardAnnotation() {
    const { editorState, onDiscardEntity, onCancelEditEntity } = this.props;
    const entityData = this.getEntityDataAtSelection(editorState);
    if (!entityData || isEmpty(entityData)) {
      onDiscardEntity(editorState.getSelection());
    } else onCancelEditEntity();
  }

  _handleUpdateAnnotation(entityKey, data) {
    const { editorState, onUpdateEntity, onDiscardEntity } = this.props;
    this.setState({ showAnnotator: false }, () => {
      if (!data || isEmpty(data)) {
        onDiscardEntity(editorState.getSelection());
      } else {
        onUpdateEntity(editorState.getSelection(), entityKey, data);
      }
      this.props.onAnnotatorShown(false);
    });
  }

  _handleDeleteAnnotation() {
    this.setState({ showAnnotator: false }, () => {
      this.props.onDiscardEntity(this.props.editorState.getSelection());
      this.props.onAnnotatorShown(false);
    });
  }

  render() {
    const { editorState, toggleEntity } = this.props;
    const { showAnnotator } = this.state;
    const selectedText = this.getSelectedText(editorState).trim();

    return (
      <Fragment>
        <span className="RichEditor-controls" ref={this.controlRef}>
          {selectedText ? (
            <div
              className="btn btn-primary btn-sm"
              onMouseDown={this.handleShow}
            >
              <span>
                <i className="material-icons md-18">comment</i> Annotate
                Selection
              </span>
            </div>
          ) : (
            <div className="btn btn-primary btn-sm disabled">
              <span>
                <i className="material-icons md-18">comment</i> Annotate
                Selection
              </span>
            </div>
          )}
        </span>
        <Overlay
          container={this.controlRef.current}
          target={this.controlRef.current}
          show={showAnnotator || toggleEntity}
          onHide={this.handleHide}
          placement="bottom"
          containerPadding={20}
          rootClose={false}
        >
          <Popover id="popover-contained">
            <Popover.Content>{this.renderEntityAnnotator()}</Popover.Content>
          </Popover>
        </Overlay>
      </Fragment>
    );
  }

  renderEntityAnnotator() {
    const { editorState, entities } = this.props;
    const entityKey = this.getEntityKeyAtSelection(editorState);
    const entityData = this.getEntityDataAtSelection(editorState);
    const selectedText = this.getSelectedText(editorState).trim();

    if (!entityKey) return <Loader />;

    return (
      <EntityAnnotator
        selection={editorState.getSelection()}
        selectedText={selectedText}
        entityKey={entityKey}
        entityData={entityData}
        entities={entities}
        onUpdateEntity={this.handleUpdateAnnotation}
        onDeleteEntity={this.handleDeleteAnnotation}
        onHide={this.handleHide}
      />
    );
  }

  getSelectedBlockElement() {
    var selection = window.getSelection();
    if (selection.rangeCount === 0) return null;
    var node = selection.getRangeAt(0).startContainer;
    do {
      if (node.getAttribute && node.getAttribute("data-block") === "true")
        return node;
      node = node.parentNode;
    } while (node != null);
    return null;
  }

  getSelectedText(editorState) {
    let selectedText = "";
    let selection = editorState.getSelection();
    const selectedFragment = getFragmentFromSelection(editorState);
    if (selectedFragment) {
      // Skip annotation of table elements.
      if (selectedFragment.find((x) => x.getType() === "TABLE"))
        selectedText = "";
      else selectedText = selectedFragment.map((x) => x.getText()).join("\n");
    } else {
      var anchorKey = selection.getAnchorKey();
      var currentContent = editorState.getCurrentContent();
      var currentContentBlock = currentContent.getBlockForKey(anchorKey);
      var start = selection.getStartOffset();
      var end = selection.getEndOffset();

      selectedText = currentContentBlock.getText().slice(start, end);
    }
    return selectedText;
  }

  getEntityKeyAtSelection(editorState) {
    const selection = editorState.getSelection();
    const startKey = selection.getStartKey();
    const startOffset = selection.getStartOffset();
    const blockWithLinkAtBeginning = editorState
      .getCurrentContent()
      .getBlockForKey(startKey);
    return blockWithLinkAtBeginning.getEntityAt(startOffset);
  }

  getEntityDataAtSelection(editorState) {
    const entityKey = this.getEntityKeyAtSelection(editorState);
    if (!entityKey) return {};
    const entity = editorState.getCurrentContent().getEntity(entityKey);
    const entityData = entity.getData();
    return isPlainObject(entityData) ? entityData : {};
  }
}

AnnotatorControl.propTypes = {
  editorState: PropTypes.object.isRequired,
  onCreateEntity: PropTypes.func.isRequired,
  onDiscardEntity: PropTypes.func.isRequired,
  onUpdateEntity: PropTypes.func.isRequired
};

export default AnnotatorControl;
