import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Badge, Form, Row, Col } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import EntityComment from "./EntityComment";

class EntityAnnotator extends React.Component {
  constructor(props) {
    super(props);

    let entityData = props.entityData || {};
    this.state = {
      entityKey: props.entityKey,
      comment: entityData.comment || "",
      confidenceLevel: entityData.confidenceLevel || "high",
      selectedEntity: entityData.selectedEntity || [],
      comments: entityData.comments || []
    };

    this.handleUpdate = this._handleUpdate.bind(this);
    this.handleDelete = this._handleDelete.bind(this);
    this.handleCancel = this._handleCancel.bind(this);

    this.handleInputChange = this._handleInputChange.bind(this);
    this.handleEntityNameChange = this._handleEntityNameChange.bind(this);
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({ [name]: value });
  }

  _handleEntityNameChange(entities) {
    entities.length
      ? this.setState({ selectedEntity: entities })
      : this.setState({ selectedEntity: [] });
  }

  _handleUpdate() {
    const {
      entityKey,
      comment,
      confidenceLevel,
      selectedEntity,
      comments
    } = this.state;
    this.props.onUpdateEntity(entityKey, {
      selectedEntity: selectedEntity,
      confidenceLevel: confidenceLevel,
      comment: comment,
      comments: comments
    });
  }

  _handleDelete() {
    this.props.onDeleteEntity(this.props.selection);
  }

  _handleCancel() {
    this.props.onHide();
  }

  render() {
    const { selectedText, entities } = this.props;
    const { comment, confidenceLevel, selectedEntity } = this.state;
    return (
      <div className="EntityAnnotator text-left">
        <code>
          "
          {selectedText.length > 150
            ? selectedText.substring(0, 100) +
              " ... " +
              selectedText.substring(
                selectedText.length - 50,
                selectedText.length
              )
            : selectedText}
          "
        </code>

        <hr />
        <Form>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Form.Label>Entity Name</Form.Label>
            </Col>
            <Col sm={9}>
              <Typeahead
                id="entity-select-input"
                name="entityName"
                multiple
                renderMenuItemChildren={(option) => (
                  <Badge variant="primary">{option.entityName}</Badge>
                )}
                labelKey={(option) => option.entityName}
                onChange={this.handleEntityNameChange}
                options={entities}
                defaultSelected={selectedEntity}
                placeholder="Search Entity"
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={3}>
              <Form.Label>Entity Value</Form.Label>
            </Col>
            <Col sm={9}>
              <Form.Control
                size="sm"
                as="textarea"
                rows="1"
                name="comment"
                value={comment}
                onChange={this.handleInputChange}
                placeholder={"Enter entity value..."}
              />
            </Col>
          </Form.Group>
          <Row>
            <Col sm={3}>
              <Form.Label>Confidence</Form.Label>
            </Col>
            <Col sm={9}>
              <Form.Group className="mb-0">
                <Form.Check inline id="confidence-low">
                  <Form.Check.Input
                    name="confidenceLevel"
                    type="radio"
                    value="low"
                    onChange={this.handleInputChange}
                    checked={confidenceLevel === "low"}
                  />
                  <Form.Check.Label>
                    <small className="badge badge-success badge-low badge-pill">
                      Low
                    </small>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check inline id="confidence-medium">
                  <Form.Check.Input
                    name="confidenceLevel"
                    type="radio"
                    value="medium"
                    onChange={this.handleInputChange}
                    checked={confidenceLevel === "medium"}
                  />
                  <Form.Check.Label>
                    <small className="badge badge-success badge-medium badge-pill">
                      Medium
                    </small>
                  </Form.Check.Label>
                </Form.Check>
                <Form.Check inline id="confidence-high">
                  <Form.Check.Input
                    name="confidenceLevel"
                    type="radio"
                    value="high"
                    onChange={this.handleInputChange}
                    checked={confidenceLevel === "high"}
                  />
                  <Form.Check.Label>
                    <small className="badge badge-success badge-high badge-pill">
                      High
                    </small>
                  </Form.Check.Label>
                </Form.Check>
              </Form.Group>
            </Col>
          </Row>
        </Form>
        <hr />

        {this.renderComments()}

        <hr />
        <div className="text-right mt-3 mb-3">
          <div
            onMouseDown={this.handleCancel}
            className="btn btn-link btn-sm mr-1"
          >
            Cancel
          </div>
          <div
            onMouseDown={this.handleDelete}
            className="btn btn-secondary btn-sm mr-1"
          >
            Discard
          </div>
          <div
            onMouseDown={this.handleUpdate}
            className="btn btn-success btn-sm"
          >
            Submit
          </div>
        </div>
      </div>
    );
  }

  renderComments() {
    const { comments } = this.state;
    return (
      <Fragment>
        <h5>Comments</h5>
        {comments.map((comment, index) => (
          <EntityComment
            key={index}
            comment={comment}
            onCommentUpdated={(item) => this.updateComment(item)}
            onCommentDeleted={(item) => this.deleteComment(item)}
          />
        ))}
        <div className="new-comment">
          <EntityComment
            newComment={true}
            comment={{}}
            onCommentUpdated={(item) => this.createComment(item)}
            onCommentDeleted={(item) => this.deleteComment(item)}
          />
        </div>
      </Fragment>
    );
  }

  createComment(comment) {
    const { comments } = this.state;
    this.setState({ comments: comments.concat([comment]) });
  }

  updateComment(comment) {
    const { comments } = this.state;
    comments.forEach((item, index) => {
      if (item.id === comment.id) return comments.splice(index, 1, comment);
    });

    this.setState({ comments: comments });
  }

  deleteComment(comment) {
    const { comments } = this.state;
    comments.forEach((item, index) => {
      if (item.id === comment.id) comments.splice(index, 1);
    });
    this.setState({ comments: comments });
  }
}

EntityAnnotator.propTypes = {
  selection: PropTypes.object.isRequired,
  selectedText: PropTypes.string.isRequired,

  entityKey: PropTypes.string.isRequired,
  entityData: PropTypes.object.isRequired,

  entities: PropTypes.array.isRequired,

  onUpdateEntity: PropTypes.func.isRequired,
  onDeleteEntity: PropTypes.func.isRequired
};

export default EntityAnnotator;
