import React, { Component } from "react";
import { Form, Spinner } from "react-bootstrap";

import { put } from "../../../utils/DeApi";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DealStatus extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleStatusChange = this._handleStatusChange.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleStatusChange(event) {
    const factCheckingStatus = event.target.value;
    const { deal } = this.props;
    deal.factCheckingStatus = factCheckingStatus;
    if (factCheckingStatus) {
      this.setState({ isLoading: true, error: null });
      const updatePromise = put(`/deals/${deal.id}/fact-checking-status`, {
        factCheckingStatus,
      });
      updatePromise.promise
        .then((response) => {
          return new Promise((resolve) =>
            setTimeout(() => resolve(response), 500)
          );
        })
        .then((response) => {
          this.setState({ deal: deal, isLoading: false, error: null });
        })
        .catch((error) => {
          this.setState({ error: error, isLoading: false });
        });
      this.subscribedPromises.push(updatePromise);
    }
  }

  render() {
    const { error, isLoading } = this.state;
    const { deal } = this.props;

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Spinner size="xs" />;

    return (
      <div className="DealFactChecking">
        <span className="text-muted">Status</span>

        <Form>
          <Form.Group>
            <Form.Control
              as="select"
              size="sm"
              value={deal.factCheckingStatus || ""}
              onChange={this.handleStatusChange}
            >
              <option value="" disabled>
                Select Status
              </option>
              <option value="closed">Unprocessed</option>
              <option value="opened">Proccessed</option>
              <option value="accepted">Passed Fact-checking</option>
              <option value="rejected">Failed Fact-checking</option>
            </Form.Control>
          </Form.Group>
        </Form>
      </div>
    );
  }
}

export default DealStatus;
