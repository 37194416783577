import React from "react";
import { Spinner } from "react-bootstrap";
import "./Loader.css";

let Loader = props => {
  let className = props.className ? props.className : "Loader pt-5 pb-5";
  return (
    <div className={className}>
      <Spinner animation="border" variant="secondary" />
    </div>
  );
};

export default Loader;
