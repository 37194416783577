import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Button, Badge } from "react-bootstrap";

import { get } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import "./DeliveryDashboard.css";
import DealEdit from "../../Deals/DealEdit/DealEdit";

class DeliveryDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchDeal();
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;
    const createDealPromise = get(`/deals/${dealId}`, {});
    createDealPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createDealPromise);
  }

  render() {
    const { deal, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;
    const company = deal.company;

    return (
      <Fragment>
        <div className="mt-2 mb-2">
          <ul className="list-inline">
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/`}>Dashboard</Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </h4>
            </li>
            <li className="list-inline-item">/</li>
            <li className="list-inline-item">
              <h4 className="mt-0 mb-0">Deal Packaging</h4>
            </li>
          </ul>
        </div>

        <hr />
        <div className="mt-3 mb-3">
          <p className="float-right">
            {deal.stationStatus.packaging ? (
              <Badge variant="success">In progress</Badge>
            ) : (
                <Badge variant="dark">Not started</Badge>
              )}{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/extraction/${deal.id}`}
            >
              Extraction
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/intelligence/${deal.id}`}
            >
              Intelligence
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/packaging/${deal.id}`}
            >
              Packaging
            </Button>{" "}
            <Button
              variant="outline-primary"
              size="sm"
              as={Link}
              to={`/delivery/${deal.id}`}
              className="disabled"
            >
              Delivery
            </Button>{" "}
            <DealEdit deal={deal} onDealEdited={this.handleDealUpdated} />
          </p>
          <h2>
            Deal signing date as of {moment(deal.signingDate).format("LL")}
          </h2>
          <p>
            Parent Borrower:{" "}
            <Link to={`/companies/${company.id}`}>{company.companyName}</Link>
          </p>
          <p className="text-muted">
            Created at <span>{moment(deal.createdAt).format("LLL")}</span>
          </p>
        </div>
        <hr />
        <div className="mt-3 mb-3">
          <h3>
            Deal Delivery - Coming Soon
          </h3>
        </div>
      </Fragment>
    )
  }
}

export default DeliveryDashboard;
