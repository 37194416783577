import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import { Table, Alert, Row, Col, Card, Container } from "react-bootstrap";

import { get } from "../../utils/DeApi";

import Header from "../App/Header/Header";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

import DashboardSearch from "./DashboardSearch/DashboardSearch";
import CompanyCreate from "../Companies/CompanyCreate/CompanyCreate";
import DealAssignment from "../Deals/DealAssignment/DealAssignment";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { query: "" };

    this.handleCompanyCreated = this._handleCompanyCreated.bind(this);
  }

  componentDidMount() {
    this.fetchDeals();
    this.fetchCompanies();
    this.fetchAnalytics();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleCompanyCreated(company) {
    this.setState({ company: company });
  }

  fetchAnalytics() {
    this.setState({
      analyticsIsLoading: true,
      analyticsError: ""
    });

    const getAnalyticsPromise = get("/analytics");
    getAnalyticsPromise.promise
      .then((response) => {
        this.setState({
          analyticsIsLoading: false,
          analyticsError: "",
          analytics: response.data.analytics
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ analyticsError: error, analyticsIsLoading: false });
      });

    this.subscribedPromises.push(getAnalyticsPromise);
  }

  fetchDeals() {
    this.setState({ dealsIsLoading: true, dealsError: "" });
    const getDealsPromise = get("/deals", { params: { limit: 10 } });
    getDealsPromise.promise
      .then((response) => {
        this.setState({
          dealsIsLoading: false,
          dealsError: "",
          deals: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ dealsError: error, dealsIsLoading: false });
      });

    this.subscribedPromises.push(getDealsPromise);
  }

  fetchCompanies() {
    this.setState({
      isLoading: true,
      error: ""
    });

    const getCompaniesPromise = get("/companies", {});
    getCompaniesPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          companies: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getCompaniesPromise);
  }

  render() {
    const { companies, isLoading, error, company } = this.state;

    if (company) return <Redirect to={`companies/${company.id}`} />;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <div className="Main">
        <Header user={this.props.user} title={"Dashboard"} />
        <div className="MainContent">
          <Container>
            <Row>
              <Col xs={12} className="mt-2 mb-2">
                <ul className="list-inline">
                  <li className="list-inline-item">
                    <h4 className="mt-0 mb-0">
                      <Link to="/">Dashboard</Link>
                    </h4>
                  </li>
                </ul>
                <hr />
                <div className="mt-3 mb-3">
                  <CompanyCreate onCompanyCreated={this.handleCompanyCreated} />
                  <h2>Company Search.</h2>
                  <p>
                    Search for the company's official name. Create new company
                    for non exisiting companies.
                  </p>
                  <DashboardSearch companies={companies} />
                </div>
                {this.renderAnalytics()}
                <hr className="mt-3 mb-3" />
                <h3 className="mt-3 mb-3">Recent Deals</h3>
                {this.renderDeals()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderAnalytics() {
    const { analytics, analyticsError, analyticsIsLoading } = this.state;

    if (analyticsIsLoading) return <Loader />;
    if (analyticsError) return <ErrorHandler error={analyticsError} />;
    if (!analytics) return <span />;

    return (
      <Card className="mt-3 mb-3">
        <Card.Body>
          <Row>
            <Col xs={12} sm={4}>
              <i className="material-icons text-secondary">storage</i>{" "}
              <strong>{analytics.companies ? analytics.companies : 0}</strong>{" "}
              companies
            </Col>
            <Col xs={12} sm={4}>
              <i className="material-icons text-primary">list</i>{" "}
              <strong>{analytics.deals ? analytics.deals : 0}</strong> deals
            </Col>
            <Col xs={12} sm={4}>
              <i className="material-icons text-success">assignment </i>{" "}
              <strong>{analytics.exhibits ? analytics.exhibits : 0}</strong>{" "}
              exhibits
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }

  renderDeals() {
    const { deals, dealsIsLoading, dealsError } = this.state;

    if (dealsIsLoading) return <Loader />;
    if (dealsError) return <ErrorHandler error={dealsError} />;
    if (!deals) return <span />;

    if (!deals.length)
      return <Alert variant="info">There are currently no deals</Alert>;

    return (
      <Table responsive size="sm">
        <thead>
          <tr>
            <th>Amend ID</th>
            <th>Company</th>
            <th>Signing Date</th>
            <th>Type</th>
            <th>Created At</th>
            <th>Updated At</th>
            <th>Processing</th>
            <th>Assignee</th>
          </tr>
        </thead>
        <tbody>
          {deals.map((deal) => {
            return (
              deal.company.companyName && (
                <tr key={deal.id}>
                  <td>
                    <span className="text-muted">{deal.dealAmendId}</span>
                  </td>

                  <td title={deal.company.companyName}>
                    <Link to={`companies/${deal.company.id}`}>
                      {deal.company.companyName.length < 25
                        ? deal.company.companyName
                        : deal.company.companyName.substring(24, 0) + "..."}
                    </Link>
                  </td>
                  <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>

                  <td>
                    {deal.dealType && (
                      <span className="badge badge-warning">
                        {deal.dealType}
                      </span>
                    )}
                  </td>

                  <td>{moment(deal.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
                  <td>
                    {deal.stationStatus.extraction ? (
                      <Link to={`/extraction/${deal.id}`}>
                        <span className="badge badge-success badge-pill">
                          Resume
                        </span>
                      </Link>
                    ) : (
                      <Link to={`/extraction/${deal.id}`}>
                        <span className="badge badge-dark badge-pill">
                          Start
                        </span>
                      </Link>
                    )}
                  </td>
                  <td>
                    <DealAssignment deal={deal} />
                  </td>
                </tr>
              )
            );
          })}
        </tbody>
      </Table>
    );
  }
}

Dashboard.propTypes = {};

export default Dashboard;
