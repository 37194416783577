import React, { Component, Fragment } from "react";
import moment from "moment";
import { Link } from "react-router-dom";

import {
  Alert,
  Table,
  Button,
  Row,
  Col,
  Form,
  Modal,
  Spinner
} from "react-bootstrap";

import { get } from "../../../utils/DeApi";
import { get as beeGet } from "../../../utils/BeeApi";

import DealAssignment from "../../Deals/DealAssignment/DealAssignment";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class FactCheckingDashboard extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { factCheckingStatus: "opened" };

    this.handleStatusChange = this._handleStatusChange.bind(this);
  }

  _handleStatusChange(event) {
    const factCheckingStatus = event.target.value;

    if (factCheckingStatus) {
      this.setState({ factCheckingStatus: factCheckingStatus }, () =>
        this.fetchDeals()
      );
    }
  }

  componentDidMount() {
    this.fetchDeals();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchDeals() {
    const { factCheckingStatus } = this.state;
    this.setState({ isLoading: true, error: "" });
    const getDealsPromise = get("/deals", {
      params: { factCheckingStatus: factCheckingStatus }
    });

    getDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deals: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getDealsPromise);
  }

  fetchHelp() {
    this.setState({ helpIsLoading: true, helpError: null });
    const getHelpPromise = beeGet("contents/5fa9b65cc84fb");
    getHelpPromise.promise
      .then((response) => {
        this.setState({
          helpIsLoading: false,
          helpError: null,
          help: response.data,
          showHelp: true
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ helpIsLoading: false, helpError: null });
      });
    this.subscribedPromises.push(getHelpPromise);
  }

  render() {
    const { factCheckingStatus } = this.state;

    return (
      <div className="ExtractionDashboard WideView">
        <Row className="WideCanvas">
          <Col xs={12} md={2} className="WideLeft">
            <div className="mt-3 mb-3">
              <span className="float-right ml-sm" />
              <ul className="list-inline">
                <li className="list-inline-item">
                  <h4 className="mt-0 mb-0">Fact Checking Dashboard</h4>
                </li>
              </ul>
            </div>
          </Col>
          <Col xs={12} md={8} className="WideCenter">
            <div className="mt-3 mb-3">
              <div className="d-flex float-right">
                <span className="pt-1 pb-1 pr-1 pl-1">
                  Fact checking status:
                </span>
                <Form>
                  <Form.Group>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={factCheckingStatus}
                      onChange={this.handleStatusChange}
                    >
                      <option value="closed">Unprocessed</option>
                      <option value="opened">Proccessed</option>
                      <option value="accepted">Passed Fact-checking</option>
                      <option value="rejected">Failed Fact-checking</option>
                    </Form.Control>
                  </Form.Group>
                </Form>
              </div>
              <div className="mt-3 mb-3">
                <h3>Deals</h3>
                <p>
                  List of deals with annotated exhibits for the selected status
                </p>
                {this.renderDeals()}
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} className="WideRight">
            <div className="mt-3 mb-3">
              <Button
                onClick={() => {
                  this.fetchHelp();
                }}
                variant="link"
                size="sm"
              >
                Guidelines <span className="material-icons md-18">help</span>
              </Button>
              {this.renderHelp()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderDeals() {
    const { deals, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deals) return <span />;
    if (!deals.length)
      return (
        <Alert variant="info">There are currently no deals to review.</Alert>
      );

    return (
      <Fragment>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Amendment ID</th>
              <th>Company</th>
              <th>Signing Date</th>
              <th>Type</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Assignee</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {deals.map((deal) => {
              return (
                <tr key={deal.id}>
                  <td>
                    <span className="text-muted">{deal.dealAmendId}</span>
                  </td>
                  <td title={deal.company.companyName}>
                    {deal.company.companyName.length < 25
                      ? deal.company.companyName
                      : deal.company.companyName.substring(24, 0) + "..."}
                  </td>
                  <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>
                  <td>
                    {deal.dealType && (
                      <span className="badge badge-warning">
                        {deal.dealType}
                      </span>
                    )}
                  </td>
                  <td>{moment(deal.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
                  <td>
                    <DealAssignment deal={deal} />
                  </td>
                  <td>
                    <Link to={`/fact-checking/${deal.id}`}>
                      Review{" "}
                      <span className="material-icons md-18">
                        arrow_forward
                      </span>
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }

  renderHelp() {
    const { help, showHelp, helpIsLoading, helpError } = this.state;

    if (helpIsLoading) return <Spinner />;
    if (helpError) return <ErrorHandler error={helpError} />;
    if (!help) return;

    return (
      <Fragment>
        <Modal
          show={showHelp}
          size="lg"
          onHide={() => this.setState({ showHelp: false })}
        >
          <Modal.Header closeButton>
            <Modal.Title>{help.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: help.contentBody }} />
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="link"
              onClick={() => this.setState({ showHelp: false })}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default FactCheckingDashboard;
