import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { Table, Alert, Form, Button, Badge } from "react-bootstrap";
import Dialog from "react-bootstrap-dialog";
import moment from "moment";

import { get } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import CompanyCreate from "../CompanyCreate/CompanyCreate";
import CompanyEdit from "../CompanyEdit/CompanyEdit";
import "./CompaniesDashboard.css";

class Companies extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      query: ""
    };

    this.handleCompanyCreated = this._handleCompanyCreated.bind(this);
    this.handleCompanyUpdated = this._handleCompanyUpdated.bind(this);
    this.handleCompanyDeleted = this._handleCompanyDeleted.bind(this);

    this.handleSearch = this._handleSearch.bind(this);
    this.toggleStatus = this._toggleStatus.bind(this);
  }

  componentDidMount() {
    this.fetchCompanies();
  }
  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleCompanyCreated(company) {
    const { companies } = this.state;
    let mutableList = [...companies];
    company.status = 1;
    mutableList.push(company);

    this.setState({
      companies: mutableList
    });
  }

  _handleCompanyUpdated(company) {
    const { companies } = this.state;
    let mutableList = [...companies];
    let index = companies.findIndex((item) => {
      return item.id === company.id;
    });
    mutableList[index] = company;

    this.setState({
      companies: mutableList
    });
  }

  _handleCompanyDeleted(companyId) {
    let { companies } = this.state;
    companies = companies.filter((company) => company.id !== companyId);

    this.setState({
      companies
    });
  }

  _toggleStatus(companyId) {
    const updateCompanyPromise = get(`/companies/${companyId}/toggleStatus`);
    updateCompanyPromise.promise
      .then((response) => {
        let { companies } = this.state;
        companies.map((company) => {
          if (company.id === companyId) {
            company.status = company.status === 1 ? 0 : 1;
          }
          return company;
        });
        this.setState({
          isLoading: false,
          error: "",
          companies: companies
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(updateCompanyPromise);
  }

  _handleSearch(event) {
    let query = event.target.value;
    const { companies } = this.state;
    const searchResult = companies.filter((item) => {
      return item.companyName.toLowerCase().search(query.toLowerCase()) === 0
        ? item
        : null;
    });

    this.setState({ searchResults: searchResult, query: query });
  }

  toggleModal(companyId) {
    this.dialog.show({
      title: "Deactive Company",
      body: "Are you sure?",
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          this.toggleStatus(companyId);
        })
      ]
    });
  }

  fetchCompanies() {
    this.setState({ isLoading: true, error: "", searchResults: null });
    const getCompaniesPromise = get("/companies");
    getCompaniesPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          companies: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getCompaniesPromise);
  }

  render() {
    return (
      <div>
        <span className="float-right">
          <CompanyCreate onCompanyCreated={this.handleCompanyCreated} />
        </span>
        <h2 className="mb-3 mt-3">Companies</h2>
        <div className="mt-3 mb-3">{this.renderCompanies()}</div>
      </div>
    );
  }

  renderCompanies() {
    const { query, companies, searchResults, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!companies) return <span />;
    if (!companies.length)
      return <Alert variant="info">There are currently no companies</Alert>;

    let filteredCompanies = searchResults || companies;
    return (
      <Fragment>
        <Form.Group>
          <Form.Control
            type="text"
            name="query"
            value={query}
            onChange={this.handleSearch}
            placeholder="Filter..."
          />
        </Form.Group>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Company Name</th>
              <th>CIK</th>
              <th>Status</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredCompanies.map((company) => {
              return (
                <tr key={company.id}>
                  <td>{company.id}</td>
                  <td>
                    <Link to={`companies/${company.id}`}>
                      {company.companyName.length < 25
                        ? company.companyName
                        : company.companyName.substring(24, 0) + "..."}
                    </Link>
                  </td>
                  <td>{company.cik}</td>
                  <td>
                    {company.status ? (
                      <Badge variant="success">Active</Badge>
                    ) : (
                        <Badge variant="danger">Inactive</Badge>
                      )}
                  </td>
                  <td>{moment(company.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(company.updatedAt).format("MM/DD/YYYY")}</td>
                  <td>
                    <CompanyEdit
                      company={company}
                      onCompanyUpdated={this.handleCompanyUpdated}
                    />{" "}
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => {
                        this.toggleModal(company.id);
                      }}
                    >
                      {company.status === 1 ? "Deactivate" : "Activate"}
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Dialog
          ref={(component) => {
            this.dialog = component;
          }}
        />
      </Fragment>
    );
  }
}

Companies.propTypes = {};

export default Companies;
