import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "react-bootstrap";

import FactCheckingDashboard from "./FactCheckingDashboard/FactCheckingDashboard";
import FactCheckingHeader from "./FactCheckingHeader/FactCheckingHeader";
import DealFactChecking from "./DealFactChecking/DealFactChecking";
import ExhibitDetails from "./ExhibitDetails/ExhibitDetails";

class FactChecking extends Component {
  render() {
    return (
      <div className="Main">
        <FactCheckingHeader user={this.props.user} title={"Deals Review"} />
        <div className="MainContent">
          <Container fluid>
            <Switch>
              <Redirect path="/" exact to="/fact-checking" />
              <Route
                exact
                path="/fact-checking"
                render={() => <FactCheckingDashboard />}
              />
              <Route
                exact
                path="/fact-checking/:dealId"
                render={(props) => (
                  <DealFactChecking dealId={props.match.params.dealId} />
                )}
              />
              <Route
                exact
                path="/fact-checking/:dealId/exhibits/:exhibitId"
                render={(props) => (
                  <ExhibitDetails
                    dealId={props.match.params.dealId}
                    exhibitId={props.match.params.exhibitId}
                  />
                )}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div className="text-center mt-5 mb-5">
    <code>
      <h3>No match for {location.pathname}</h3>
      <hr />
      <p>The link is broken or the page has been moved.</p>
      <p>
        <a href="/">Got to dashboard</a>
      </p>
    </code>
  </div>
);

export default FactChecking;
