import React, { Component, Fragment } from "react";
import "./FinancialCovenants.css";

class FinancialCovenants extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
  }

  componentDidMount() { }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  render() {
    return (
      <Fragment>
        <h4>
          Financial Covenants
        </h4>
        <div>
          Coming Soon...
        </div>
      </Fragment>
    );
  }
}

export default FinancialCovenants;
