import React, { Component, Fragment } from "react";
import { Alert, Col, Button, Form, Modal } from "react-bootstrap";
import { put } from "../../../../../utils/DeApi";
import { Typeahead } from "react-bootstrap-typeahead";
import Loader from "../../../../Loader/Loader";
import ErrorHandler from "../../../../ErrorHandler/ErrorHandler";
import BankCreate from "../../../../Banks/BankCreate/BankCreate";

class LenderEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleBankNameChange = this._handleBankNameChange.bind(this);
    this.handlePrimaryRoleChange = this._handlePrimaryRoleChange.bind(this);
    this.handleBankCommitmentMinChange = this._handleBankCommitmentMinChange.bind(
      this
    );
    this.handleBankCommitmentMaxChange = this._handleBankCommitmentMaxChange.bind(
      this
    );
    this.handleLeadArrangerChange = this._handleLeadArrangerChange.bind(this);
    this.handleAgentOnlyChange = this._handleAgentOnlyChange.bind(this);
    this.handleAgentCoAgentChange = this._handleAgentCoAgentChange.bind(this);
    this.handleSecondaryRoles = this._handleSecondaryRolesChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
    this.onBankCreated = this._onBankCreated.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  fetchLender() {
    const { lender, banks, roles, trancheType, trancheAmount, trancheId, deal } = this.props;
    if (lender) {
      let roleIds = lender.secondaryRole.map((role) => {
        return role.id;
      });
      this.setState({
        deal,
        lender,
        banks,
        roles,
        bankName: lender.bankName,
        leadArranger: lender.leadArranger,
        primaryRoleId: lender.primaryRole.id,
        bankCommitmentMin: lender.bankCommitmentMin,
        agentOnly: lender.agentOnly,
        agentCoAgent: lender.agentCoAgent,
        primaryRoleValid: true,
        bankNameValid: true,
        trancheId,
        trancheAmount,
        trancheType,
        secondaryRoles: roleIds,
      });
    }
  }

  componentDidMount() {
    this.fetchLender();
  }

  _onBankCreated(bank = {}) {
    const { banks } = this.state;
    let mutableList = [...banks];
    let index = banks.findIndex((item) => {
      return item.id === bank.id;
    });

    if (index < 0) mutableList.unshift(bank);
    else mutableList[index] = bank;

    this.setState({
      banks: mutableList,
    });
  }

  _handleBankNameChange(banks) {
    banks.length
      ? this.setState({
        bankName: banks[0].bankName,
        bankNameValid: banks[0].bankName.length >= 2 ? true : false,
      })
      : this.setState({
        bankName: null,
        bankNameValid: false,
      });
  }

  _handleBankCommitmentMinChange(event) {
    let bankCommitmentMin = event.target.value;
    this.setState({
      bankCommitmentMin,
    });
  }

  _handleBankCommitmentMaxChange(event) {
    let bankCommitmentMax = event.target.value;
    this.setState({
      bankCommitmentMax,
    });
  }

  _handleLeadArrangerChange(event) {
    let leadArranger = event.target.value;
    this.setState({
      leadArranger: leadArranger,
      leadArrangerValid:
        leadArranger === "y" || leadArranger === "n" ? true : false,
    });
  }

  _handleAgentOnlyChange(event) {
    let agentOnly = event.target.value;
    this.setState({
      agentOnly: agentOnly,
      agentOnlyValid: agentOnly === "y" || agentOnly === "n" ? true : false,
    });
  }

  _handleAgentCoAgentChange(event) {
    let agentCoAgent = event.target.value;
    this.setState({
      agentCoAgent: agentCoAgent,
      agentCoAgentValid:
        agentCoAgent === "y" || agentCoAgent === "n" ? true : false,
    });
  }

  _handlePrimaryRoleChange(roles) {
    roles.length
      ? this.setState({
        primaryRoleId: roles[0].id,
        primaryRoleValid: roles[0].id.length >= 2,
      })
      : this.setState({
        primaryRoleId: null,
        primaryRoleValid: false,
      });
  }

  _handleSecondaryRolesChange(roles) {
    let secondaryRoles = [];
    roles.forEach((role) => {
      secondaryRoles.push(role.id);
    });

    this.setState({
      secondaryRoles,
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true,
      },
      (state, props) => {
        const {
          bankNameValid,
          bankName,
          bankCommitmentMin,
          leadArranger,
          agentOnly,
          trancheId,
          agentCoAgent,
          primaryRoleId,
          secondaryRoles,
          primaryRoleValid,
        } = this.state;
        const { lender } = this.props;

        if (bankNameValid && primaryRoleValid) {
          this.setState({
            error: "",
            isLoading: true,
          });

          const createPromise = put(`/lenders/${lender.id}`, {
            bankName,
            bankCommitmentMin,
            bankCommitmentMax: bankCommitmentMin,
            leadArranger,
            agentOnly,
            agentCoAgent,
            trancheId,
            primaryRoleId,
            secondaryRoles,
          });

          createPromise.promise
            .then((response) => {
              this.setState(
                {
                  isLoading: false,
                  error: "",
                  showModal: false,
                  tranche: response.data,
                },
                () => {
                  this.props.onLenderUpdated(response.data);
                }
              );
            })
            .catch((error) => {
              this.setState({
                error: error,
                isLoading: false,
              });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  render() {
    const { isLoading, deal, banks } = this.state;

    if (!deal) return <span />;

    if (!banks) return <span />;

    return (
      <Fragment>
        <Button
          variant="outline-success"
          onClick={this.toggleModal}
          size="sm"
        >
          Update
        </Button>

        <Modal show={this.state.showModal} size="lg" onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Lender</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderLenderEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderLenderEdit() {
    const {
      error,
      success,
      isLoading,
      bankNameValid,
      bankCommitmentMin,
      trancheType,
      trancheAmount,
      formSubmitted,
      bankName,
      primaryRoleValid,
      leadArranger,
      primaryRoleId,
      agentOnly,
      agentCoAgent,
      secondaryRoles,
      banks,
      roles,
    } = this.state;

    if (isLoading) return <Loader />;

    if (error) return <ErrorHandler error={error} />;

    return (
      <Form.Row>
        <Col xs={12}>
          <div className="float-right">
            <BankCreate
              onBankCreated={this.onBankCreated}
            />
          </div>
        </Col>
        <Form.Group as={Col} md="6">
          <Form.Label>Tranche</Form.Label>
          <div>
            {trancheType} {trancheAmount}
          </div>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label>Bank Name</Form.Label>
          <Typeahead
            id="9876"
            labelKey={(option) => option.bankName}
            onChange={this.handleBankNameChange}
            isInvalid={!bankNameValid && formSubmitted}
            options={banks}
            placeholder="Search Bank"
            selected={banks.filter((bank) => {
              return bank.bankName === bankName;
            })}
          />
          <Form.Control.Feedback type="invalid">
            Invalid Bank Name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Bank Commitment Min</Form.Label>
          <Form.Control
            type="text"
            value={bankCommitmentMin}
            onChange={this.handleBankCommitmentMinChange}
          />
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Bank Commitment Max</Form.Label>
          <Form.Control type="text" value={bankCommitmentMin} disabled />
          <Form.Control.Feedback type="invalid">
            Invalid Bank Name
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Primary Role</Form.Label>
          <Typeahead
            id="987611"
            labelKey={(option) => option.title}
            onChange={this.handlePrimaryRoleChange}
            isInvalid={!primaryRoleValid && formSubmitted}
            options={roles.filter((role) => {
              return role.isPrimary === 1;
            })}
            placeholder="Search Primary Role..."
            selected={roles.filter((role) => {
              return role.id === primaryRoleId;
            })}
          />
          <Form.Control.Feedback type="invalid">
            Primary Role is required
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} md="6">
          <Form.Label>Secondary Role</Form.Label>
          <Typeahead
            id="9876221"
            multiple
            labelKey={(option) => option.title}
            onChange={this.handleSecondaryRoles}
            isInvalid={!primaryRoleValid && formSubmitted}
            options={roles.filter((role) => {
              return role.isSecondary === 1;
            })}
            selected={roles.filter((role) => {
              return secondaryRoles.includes(role.id);
            })}
            placeholder="Search Secondary Role..."
          />
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Lead Arranger</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="lead-arranger"
              onChange={this.handleLeadArrangerChange}
              defaultChecked={leadArranger === "y"}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="lead-arranger"
              onChange={this.handleLeadArrangerChange}
              defaultChecked={leadArranger === "n"}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Agent Only</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="agent-only"
              onChange={this.handleAgentOnlyChange}
              defaultChecked={agentOnly === "y"}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="agent-only"
              onChange={this.handleAgentOnlyChange}
              defaultChecked={agentOnly === "n"}
            />
          </div>
        </Form.Group>

        <Form.Group as={Col} md="4">
          <Form.Label>Agent Co Agent</Form.Label>
          <div>
            <Form.Check
              inline
              type="radio"
              value="y"
              label="Yes"
              name="agent-co-agent"
              onChange={this.handleLeadArrangerChange}
              defaultChecked={agentCoAgent === "y"}
            />
            <Form.Check
              inline
              type="radio"
              value="n"
              label="No"
              name="agent-co-agent"
              onChange={this.handleLeadArrangerChange}
              defaultChecked={agentCoAgent === "n"}
            />
          </div>
        </Form.Group>

        {
          error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)
        }
        {
          success && (
            <Alert variant="success">
              <h4>Success!</h4>
              <p>Lender Created Successfully</p>
            </Alert>
          )
        }
      </Form.Row >
    );
  }
}

export default LenderEdit;
