import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Col, Button, Form, Modal } from "react-bootstrap";

import { put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ExhibitEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    const exhibit = props.exhibit;
    this.state = {
      dealId: exhibit.deal.id,
      documentUrl: exhibit.documentUrl,
      documentUrlValid: exhibit.documentUrl.length > 2 ? true : false,
      exhibitName: exhibit.exhibitName,
      exhibitNameValid: exhibit.exhibitName.length > 2 ? true : false,
    };

    this.handleExhibitNameChange = this._handleExhibitNameChange.bind(this);
    this.handleDocumentUrlChange = this._handleDocumentUrlChange.bind(this);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {}

  _handleExhibitNameChange(event) {
    let exhibitName = event.target.value;
    this.setState({
      exhibitName: exhibitName,
      exhibitNameValid: exhibitName.length > 2 ? true : false,
    });
  }

  _handleDocumentUrlChange(event) {
    let documentUrl = event.target.value;
    this.setState({
      documentUrl,
      documentUrlValid: documentUrl.length > 2 ? true : false,
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState({
      formSubmitted: true,
    });

    const {
      dealId,
      exhibitNameValid,
      documentUrlValid,
      documentUrl,
      exhibitName,
    } = this.state;

    const { exhibit, onExhibitUpdated } = this.props;
    if (!exhibitNameValid && !documentUrlValid) return;

    this.setState({ error: "", isLoading: true });
    const updateExhibitPromise = put(`/exhibits/${exhibit.id}`, {
      exhibitName,
      documentUrl,
      dealId,
    });

    updateExhibitPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false,
            exhibit: response.data,
          },
          () => {
            onExhibitUpdated(response.data);
          }
        );
      })
      .catch((error) => {
        this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(updateExhibitPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;

    return (
      <Fragment>
        <Button variant="outline-success" size="xs" onClick={this.toggleModal}>
          Update
        </Button>
        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3>Update Exhibit</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderExhibitEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderExhibitEdit() {
    const {
      error,
      success,
      isLoading,
      exhibitName,
      exhibitNameValid,
      documentUrl,
      documentUrlValid,
      formSubmitted,
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} xs="12">
          <Form.Label>Exhibit Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={exhibitName}
            onChange={this.handleExhibitNameChange}
            isInvalid={!exhibitNameValid && formSubmitted}
            placeholder="Enter Exhibit Name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Exhibit name is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="12">
          <Form.Label>Document URL</Form.Label>
          <Form.Control
            type="text"
            defaultValue={documentUrl}
            onChange={this.handleDocumentUrlChange}
            isInvalid={!documentUrlValid && formSubmitted}
            placeholder="Enter Exhibit Name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Document URL is required
          </Form.Control.Feedback>
        </Form.Group>

        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Exhibit Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

ExhibitEdit.propTypes = {
  exhibit: PropTypes.object.isRequired,
};

export default ExhibitEdit;
