import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Alert, Modal, Button, Form } from "react-bootstrap";

import { post } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class BankCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      bankName: "",
      bankNameValid: false,
      showModal: false
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleNameChange = this._handleNameChange.bind(this);

    this.toggleModal = this._toggleModal.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleNameChange(event) {
    let bankName = event.target.value;
    this.setState({
      bankName: bankName,
      bankNameValid: bankName.length > 2 ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      () => {
        this.createBank();
      }
    );
  }

  createBank() {
    const { bankName, bankNameValid } = this.state;
    const { onBankCreated } = this.props;
    if (!bankNameValid) return;

    this.setState({ error: "", isLoading: true });
    const createBankPromise = post("/banks", {
      bankName: bankName
    });
    createBankPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false
          },
          () => {
            onBankCreated(response.data);
          }
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createBankPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;
    return (
      <Fragment>
        <Button size="sm" variant="primary" onClick={this.toggleModal}>
          New Bank
        </Button>

        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create Bank</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderBankCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderBankCreate() {
    const { error, name, bankNameValid, formSubmitted, isLoading } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={this.handleNameChange}
            isInvalid={!bankNameValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Name is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>A bank with same name already exists.</p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
      </Form>
    );
  }
}
BankCreate.propTypes = {
  onBankCreated: PropTypes.func.isRequired
};

export default BankCreate;
