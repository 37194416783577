import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";

import Header from "../App/Header/Header";
import DealsDashboard from "./DealsDashboard/DealsDashboard";

class Deals extends Component {
  render() {
    return (
      <div className="Main">
        <Header user={this.props.user} title={"Deals"} />
        <div className="MainContent">
          <Container>
            <Switch>
              <Route
                exact
                path="/deals"
                render={(props) => <DealsDashboard />}
              />
              <Route component={NoMatch} />
            </Switch>
          </Container>
        </div>
      </div>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 className="text-center mt-xl mb-xl">
      No match for <code>{location.pathname}</code>
    </h3>
  </div>
);

export default Deals;
