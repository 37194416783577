import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Col, Button, Form, Modal } from "react-bootstrap";

import { post } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ExhibitCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      exhibitName: "",
      documentUrl: "",
      success: false,
      showModal: false
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleDocumentUrlChange = this._handleDocumentUrlChange.bind(this);
    this.handleExhibitNameChange = this._handleExhibitNameChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleExhibitNameChange(event) {
    let exhibitName = event.target.value;
    this.setState({
      exhibitName: exhibitName,
      exhibitNameValid: exhibitName.length > 2 ? true : false
    });
  }

  _handleDocumentUrlChange(event) {
    let documentUrl = event.target.value;
    this.setState({
      documentUrl: documentUrl,
      documentUrlValid: documentUrl.length > 2 ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState({ formSubmitted: true });

    const {
      exhibitName,
      documentUrlValid,
      exhibitNameValid,
      documentUrl
    } = this.state;

    if (!documentUrlValid || !exhibitNameValid) return;

    this.setState({ error: "", isLoading: true });
    const { deal, onExhibitCreated } = this.props;
    const createPromise = post("/exhibits", {
      dealId: deal.id,
      exhibitName: exhibitName,
      documentUrl: documentUrl
    });

    createPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false,
            exhibit: response.data
          },
          () => {
            onExhibitCreated(response.data);
          }
        );
      })
      .catch((error) => {
        this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;

    return (
      <Fragment>
        <Button variant="outline-primary" size="xs" onClick={this.toggleModal}>
          New Exhibit
        </Button>
        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>New Exhibit</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderExhibitCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderExhibitCreate() {
    const {
      error,
      success,
      isLoading,
      sync,
      documentUrlValid,
      exhibitNameValid,
      formSubmitted
    } = this.state;

    if (sync || isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} xs="12">
          <Form.Label>Exhibit Name</Form.Label>
          <Form.Control
            type="text"
            onChange={this.handleExhibitNameChange}
            isInvalid={!exhibitNameValid && formSubmitted}
            placeholder="Enter Exhibit Name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Exhibit name is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="12">
          <Form.Label>Document</Form.Label>
          <Form.Control
            type="text"
            onChange={this.handleDocumentUrlChange}
            isInvalid={!documentUrlValid && formSubmitted}
            placeholder="Enter Document Url"
          />
          <Form.Control.Feedback type="invalid">
            Document URL is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Exhibit Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

ExhibitCreate.propTypes = {
  deal: PropTypes.object.isRequired
};

export default ExhibitCreate;
