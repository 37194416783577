import React, { Component, Fragment } from "react";
import "./DealMain.scss";
import moment from "moment";
import Dialog from "react-bootstrap-dialog";
import { Button, Table } from "react-bootstrap";

import { get } from "../../../utils/DeApi";
import { get as beeGet } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import DealSourceEdit from "./DealSource/DealSourceEdit";
import DealAmendmentEdit from "./DealAmendment/DealAmendmentEdit";
import { CopyToClipboard } from "react-copy-to-clipboard";

class DealMain extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.subscribedPromises = [];

    this.runRules = this._runRules.bind(this);
    this.fetchHelp = this._fetchHelp.bind(this);
    this.onDealSourceEdit = this._onDealSourceEdit.bind(this);
    this.onDealAmendmentEdit = this._onDealAmendmentEdit.bind(this);
  }

  componentDidMount() {
    this.fetchDeal();
    this.fetchTranches();
    this.fetchTypes();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _onDealSourceEdit(dealSource = {}) {
    const { deal } = this.state;
    deal.dealSource = dealSource;
    this.setState({
      deal,
      sourceForm: dealSource.sourceForm,
      filingDate: dealSource.filingDate,
      exhibit_names: dealSource.exhibit_names,
      secWebpage: dealSource.sec_webpage,
      source_comment: dealSource.source_comment
    });
  }

  _onDealAmendmentEdit(dealAmendment = {}) {
    const { deal } = this.state;
    deal.dealMeta = dealAmendment;
    this.setState({
      deal,
      ltDate: dealAmendment.LtDate,
      amendEvent: dealAmendment.amendEvent,
      amendExtendFlag: dealAmendment.amendExtendFlag,
      defaultSpread: dealAmendment.defaultSpread,
      defaultSpreadComment: dealAmendment.defaultSpreadComment,
      ltDealFlag: dealAmendment.ltDealFlag
    });
  }

  _runRules() {
    const { dealId } = this.props;
    const createRulePromise = get(`/deals/${dealId}/rules`, {});
    createRulePromise.promise
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  fetchDeal() {
    const { dealId } = this.props;
    const createDealPromise = get(`/deals/${dealId}`, {});
    createDealPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data,
          sourceForm: response.data.dealSource
            ? response.data.dealSource.sourceForm
            : "",
          secWebpage: response.data.dealSource
            ? response.data.dealSource.sec_webpage
            : "",
          filingDate: response.data.dealSource
            ? response.data.dealSource.filingDate
            : "",
          exhibit_names: response.data.dealSource
            ? response.data.dealSource.exhibit_names
            : "",
          source_comment: response.data.dealSource
            ? response.data.dealSource.source_comment
            : "",
          ltDate: response.data.dealMeta ? response.data.dealMeta.LtDate : "",
          amendEvent: response.data.dealMeta
            ? response.data.dealMeta.amendEvent
            : "",
          amendExtendFlag: response.data.dealMeta
            ? response.data.dealMeta.amendExtendFlag
            : "",
          defaultSpread: response.data.dealMeta
            ? response.data.dealMeta.defaultSpread
            : "",
          defaultSpreadComment: response.data.dealMeta
            ? response.data.dealMeta.defaultSpreadComment
            : "",
          ltDealFlag: response.data.dealMeta
            ? response.data.dealMeta.LtDealFlag
            : ""
        });
      })
      .then((res) => {
        let { sourceForm, secWebpage, filingDate } = this.state;
        if (sourceForm && secWebpage && filingDate) {
          this.setState({ canRunRules: true });
        }
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createDealPromise);
  }

  showHelp() {
    const { content, title, description } = this.state;
    this.dialog.show({
      body: (
        <div>
          <span className="text-muted">
            <b>{description}</b>
          </span>
          <hr />
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
      ),
      title: title,
      bsSize: "lg",
      actions: [Dialog.OKAction()]
    });
  }

  _fetchHelp(field) {
    this.setState({
      sync: true,
      error: ""
    });

    let id = "";

    if (field === "dealMain") {
      id = "5e1231fc184f8";
    } else if (field === "tranche") {
      id = "5e1232ff48fc8";
    } else {
      id = "5e123351cc069";
    }

    const createHelpPromise = beeGet(`contents/${id}`, {});
    createHelpPromise.promise
      .then((response) => {
        this.setState(
          {
            sync: false,
            error: "",
            content: response.data.contentBody,
            title: response.data.title,
            description: response.data.description
          },
          () => {
            this.showHelp();
          }
        );
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createHelpPromise);
  }

  fetchTypes() {
    this.setState({
      sync: true,
      error: ""
    });

    const createTrancheTypePromise = get("/tranche-types", {});
    createTrancheTypePromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          error: "",
          types: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createTrancheTypePromise);
  }

  fetchJobs = () => {
    this.setState({
      sync: true,
      error: ""
    });
    const { dealId } = this.props;
    const createJobPromise = get(`/deals/${dealId}/jobs`, {});
    createJobPromise.promise
      .then((response) => {
        this.setState({
          sync: false,
          error: "",
          jobs: response.data
        });
      })
      .catch((error) => {
        this.setState({
          error: error,
          sync: false
        });
      });

    this.subscribedPromises.push(createJobPromise);
  };

  fetchTranches() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;

    const createTranchePromise = get(`deals/${dealId}/tranches`, {});
    createTranchePromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          tranches: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(createTranchePromise);
  }

  render() {
    const { deal, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;

    return (
      <div className="DealMainPage">
        <div className="mt-3 mb-3">
          <Dialog
            ref={(component) => {
              this.dialog = component;
            }}
          />
          <h4>
            Deal Main Page
            <Button
              className="ml-1"
              onClick={() => {
                this.fetchHelp("dealMain");
              }}
              variant="link"
              size="sm"
            >
              Guidelines <span className="material-icons md-18">help</span>
            </Button>
          </h4>
        </div>
        <div className="mt-3 mb-3">{this.renderDealSource()}</div>
        <div className="mt-3 mb-3">{this.renderDealAmendment()}</div>
      </div>
    );
  }

  renderDealSource() {
    const {
      deal,
      filingDate,
      source_comment,
      secWebpage,
      exhibit_names,
      sourceForm
    } = this.state;
    if (deal.dealSource) {
      return (
        <Fragment>
          <p>Information Source</p>
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>Date (Filing)</th>
                <th>Source Specifics</th>
                <th>SEC Link</th>
                <th>Exhibit</th>
                <th>Information Source Comment</th>
                <th>Updated At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {filingDate ? moment(filingDate).format("MM/DD/YYYY") : ""}
                </td>
                <td>{sourceForm}</td>
                <td>{secWebpage}</td>
                <td>{exhibit_names}</td>
                <td>
                  <code>{source_comment}</code>
                  <CopyToClipboard text={source_comment}>
                    <span className="material-icons btn-copy text-muted ml-2">
                      file_copy
                    </span>
                  </CopyToClipboard>
                </td>
                <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>

                <td>
                  <DealSourceEdit
                    dealId={deal.id}
                    dealSource={deal.dealSource}
                    onDealSourceEdit={this.onDealSourceEdit}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      );
    }
  }

  renderDealAmendment() {
    const {
      deal,
      amendEvent,
      ltDealFlag,
      ltDate,
      defaultSpread,
      defaultSpreadComment
    } = this.state;
    if (deal.dealSource) {
      return (
        <Fragment>
          <p>Deal Amendment</p>
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>LT Deal Flag</th>
                <th>LT Date</th>
                <th>Amend Event</th>
                <th>Default Spread</th>
                <th>Default Spread Comment</th>
                <th>Updated At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{ltDealFlag === "Y" ? "Yes" : "No"}</td>
                <td>{ltDate ? moment(ltDate).format("MM/DD/YYYY") : ""}</td>
                <td>{amendEvent ? amendEvent : " - "}</td>
                <td>{defaultSpread}</td>
                <td>
                  <code>{defaultSpreadComment}</code>
                  <CopyToClipboard text={defaultSpreadComment}>
                    <span className="material-icons btn-copy text-muted ml-2">
                      file_copy
                    </span>
                  </CopyToClipboard>
                </td>
                <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
                <td>
                  <DealAmendmentEdit
                    dealId={deal.id}
                    dealAmendment={deal.dealMeta}
                    onDealAmendmentEdit={this.onDealAmendmentEdit}
                  />
                </td>
              </tr>
            </tbody>
          </Table>

          <p>Deal Amendment Status History </p>
          <Table responsive size="sm">
            <thead>
              <tr>
                <th>Deal Status</th>
                <th>Effective Date</th>
                <th>End Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>In Process</td>
                <td>Prepopulated in BLUES</td>
                <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>
              </tr>
              <tr>
                <td>Closed</td>
                <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>
                <td>Intentionally left blank</td>
              </tr>
            </tbody>
          </Table>
        </Fragment>
      );
    }
  }
}

DealMain.propTypes = {};

export default DealMain;
