import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import { Container, Col, Row, Button, Form, Card } from "react-bootstrap";

import "./Login.css";
import { isLoggedIn, login } from "../../../utils/BeeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import {
  isLoggedIn as isLoggedInDE,
  login as loginDE
} from "../../../utils/DeApi";

/**
 * Handles logging in to the application.
 * Handles single sign on.
 */
class Login extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleEmailChange = this._handleEmailChange.bind(this);
    this.handlePasswordChange = this._handlePasswordChange.bind(this);

    const loggedIn = isLoggedIn() && isLoggedInDE();
    let email = loggedIn ? loggedIn.email : "";
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.state = {
      email: email,
      password: "",
      emailValid: pattern.test(email),
      passwordValid: false,
      error: "",
      loggedIn: loggedIn,
      formSubmitted: false
    };
  }

  _handleEmailChange(event) {
    let email = event.target.value;
    let pattern = new RegExp(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);

    this.setState({
      email: email,
      emailValid: pattern.test(email)
    });
  }

  _handlePasswordChange(event) {
    let password = event.target.value;
    this.setState({
      password: password,
      passwordValid: password.length ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        if (this.state.emailValid && this.state.passwordValid) {
          this.setState({
            error: "",
            isLoading: true
          });
          login({
            email: this.state.email,
            password: this.state.password
          })
            .then(data => {
              return loginDE();
            })
            .then(data => {
              this.setState({
                loggedIn: true,
                isLoading: false
              });
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });
        }
      }
    );
  }

  renderLoginForm() {
    const {
      email,
      emailValid,
      password,
      passwordValid,
      error,
      formSubmitted
    } = this.state;
    return (
      <Form onSubmit={this.handleSubmit}>
        <div className="text-center">
          <h2>Login to continue</h2>
        </div>
        <hr />
        <Form.Group>
          <Form.Label>Email address</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={this.handleEmailChange}
            isValid={!emailValid && formSubmitted}
            placeholder="Enter email"
            autoFocus
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            value={password}
            onChange={this.handlePasswordChange}
            isValid={!passwordValid && formSubmitted}
            placeholder="Password"
          />
        </Form.Group>

        {error && <ErrorHandler error={error} />}
        <Button type="submit" color="primary" size="lg" block>
          Login
        </Button>
        <p className="text-muted text-center pt-3 pb-1">
          <small>
            By continuing, you agree to our <a href="/#">Terms of Service</a>{" "}
            and have read and acknowledge our <a href="/#">Privacy Statement</a>
            .
          </small>
        </p>
        <hr />
        <p className="text-center">
          <Link to="/login-help">Forgot Password?</Link>
        </p>
      </Form>
    );
  }

  render() {
    const { loggedIn, isLoading } = this.state;

    if (loggedIn) {
      let { from } = this.props.location.state || {
        from: { pathname: "/" }
      };
      return <Redirect to={from} />;
    }

    if (isLoading) return <Loader />;

    return (
      <Container fluid className="mt-5">
        <Row>
          <Col md={{ span: 4, offset: 4 }} sm={{ span: 4, offset: 4 }}>
            <div className="text-center mt-3 mb-3">
              <span className="lkm-logo">LOAN KNOWLEDGE MILL</span>
            </div>
            <Card className="mt-2">
              <Card.Body>{this.renderLoginForm()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
