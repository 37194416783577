import React, { Component, Fragment } from "react";
import { Button, Modal, Form } from "react-bootstrap";

import { destroy } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ExhibitDelete extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      show: false,
      exhibitName: "",
    };

    this.handleToggleModal = this._handleToggleModal.bind(this);
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleInputChange = this._handleInputChange.bind(this);
  }

  _handleToggleModal() {
    this.setState({ show: !this.state.show });
  }

  _handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { exhibit, onExhibitDeleted } = this.props;
    const { exhibitName } = this.state;

    this.setState({ formSubmitted: true });
    if (exhibitName !== exhibit.exhibitName) return;

    const destroyPromise = destroy("/exhibits/" + exhibit.id);
    destroyPromise.promise
      .then((response) => {
        this.setState(
          {
            exhibitName: "",
            show: false,
            isLoading: false,
            error: "",
          },
          () => onExhibitDeleted(exhibit.id)
        );
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            error: error,
            isLoading: false,
          });
      });
    this.subscribedPromises.push(destroyPromise);
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  render() {
    const { error, isLoading, exhibitName, formSubmitted, show } = this.state;
    const { exhibit } = this.props;

    return (
      <Fragment>
        <Button
          variant="outline-danger"
          size="xs"
          onClick={this.handleToggleModal}
        >
          Delete
        </Button>
        <Modal show={show} backdrop="static">
          <form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="mt-0">
                <h3>Are you sure you want to delete this exhibit?</h3>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Exhibit Name</Form.Label>
                <Form.Control
                  type="text"
                  name="exhibitName"
                  value={exhibitName}
                  placeholder="Enter name of exhibit..."
                  isInvalid={
                    exhibitName !== exhibit.exhibitName && formSubmitted
                  }
                  onChange={this.handleInputChange}
                />
                <Form.Control.Feedback type="invalid">
                  Exhibit name do not match
                </Form.Control.Feedback>
              </Form.Group>
              {error && <ErrorHandler error={error} />}
              {isLoading && <Loader />}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="link" onClick={this.handleToggleModal}>
                Cancel
              </Button>
              <Button variant="danger" onClick={this.handleSubmit}>
                Delete
              </Button>
            </Modal.Footer>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

export default ExhibitDelete;
