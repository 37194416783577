import React, { Component, Fragment } from "react";
import "./Banks.css";
import Dialog from "react-bootstrap-dialog";
import moment from "moment";

import {
  Alert,
  Table,
  Button,
  Col,
  Row,
  Container,
  Form
} from "react-bootstrap";

import { get, destroy } from "../../utils/DeApi";

import Header from "../App/Header/Header";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";

import BankCreate from "./BankCreate/BankCreate";
import BankEdit from "./BankEdit/BankEdit";

class Banks extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { query: "" };

    this.handleBankCreated = this._handleBankCreated.bind(this);
    this.handleBankEdited = this._handleBankEdited.bind(this);
    this.handleBankDeleted = this._handleBankDeleted.bind(this);

    this.handleSearch = this._handleSearch.bind(this);
  }

  componentDidMount() {
    this.fetchBanks();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _handleBankCreated(bank) {
    const { banks } = this.state;
    let mutableList = [...banks];
    mutableList.push(bank);

    this.setState({
      banks: mutableList
    });
  }

  _handleBankEdited(bank) {
    const { banks } = this.state;
    let mutableList = [...banks];
    let index = banks.findIndex((item) => {
      return item.id === bank.id;
    });
    mutableList[index] = bank;

    this.setState({
      banks: mutableList
    });
  }

  _handleBankDeleted(bankId) {
    let { banks } = this.state;
    banks = banks.filter((bank) => bank.id !== bankId);

    this.setState({
      banks
    });
  }

  _handleSearch(event) {
    let query = event.target.value;
    const { banks } = this.state;
    const searchResults = banks.filter((item) => {
      return item.bankName.toLowerCase().search(query.toLowerCase()) === 0
        ? item
        : null;
    });

    this.setState({ searchResults: searchResults, query: query });
  }

  onDelete(bankId) {
    this.dialog.show({
      title: "Delete",
      body: "Are you sure?",
      actions: [
        Dialog.CancelAction(),
        Dialog.OKAction(() => {
          this.deleteBank(bankId);
        })
      ]
    });
  }

  fetchBanks() {
    this.setState({ isLoading: true, error: "", searchResults: null });

    const getBanksPromise = get("/banks");
    getBanksPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          banks: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getBanksPromise);
  }

  deleteBank(bankId) {
    this.setState({ error: "" });
    const deleteBankPromise = destroy(`/banks/${bankId}`);

    deleteBankPromise.promise
      .then((response) => {
        this.handleBankDeleted(bankId);
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(deleteBankPromise);
  }

  render() {
    const { user } = this.props;
    return (
      <div className="Main">
        <Header user={user} title={"Banks"} />
        <div className="MainContent">
          <Container>
            <Row>
              <Col xs="12" className="mb-3 mt-3">
                <span className="float-right">
                  <BankCreate onBankCreated={this.handleBankCreated} />
                </span>
                <h2 className="mb-4">Banks</h2>

                {this.renderBanks()}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderBanks() {
    const { query, banks, searchResults, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!banks) return <span />;
    if (!banks.length)
      return <Alert variant="info">There are currently no banks.</Alert>;

    let filteredBanks = searchResults || banks;
    return (
      <Fragment>
        <Form.Group>
          <Form.Control
            type="text"
            name="query"
            value={query}
            onChange={this.handleSearch}
            placeholder="Filter..."
          />
        </Form.Group>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>ID</th>
              <th>Bank Name</th>

              <th>Created At</th>
              <th>Updated At</th>

              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredBanks.map((bank) => {
              return (
                <tr key={bank.id}>
                  <td>{bank.id}</td>
                  <td>{bank.bankName}</td>
                  <td>{moment(bank.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(bank.updatedAt).format("MM/DD/YYYY")}</td>
                  <td>
                    <span>
                      <BankEdit
                        bank={bank}
                        onBankEdited={this.handleBankEdited}
                      />{" "}
                      <Button
                        size="sm"
                        variant="outline-danger"
                        onClick={() => this.onDelete(bank.id)}
                      >
                        Delete
                      </Button>
                    </span>
                    <Dialog
                      ref={(component) => {
                        this.dialog = component;
                      }}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }
}

export default Banks;
