import React, { Component, Fragment } from "react";
import { Alert, Col, Button, Form, Modal, Spinner } from "react-bootstrap";
import { get, put } from "../../../../utils/DeApi";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";
import { Typeahead } from "react-bootstrap-typeahead";

class TrancheEdit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      amountValid: true,
      trancheTypeValid: true,
    };
    this.subscribedPromises = [];
    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleAmendRule = this._handleAmendRule.bind(this);
    this.handleTypeChange = this._handleTypeChange.bind(this);
    this.handleAmountChange = this._handleAmountChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    const { deal, typeId, trancheAmount, types } = this.props;
    if (deal && typeId && types) {
      this.setState({
        deal,
        trancheAmount,
        typeId,
        types,
        ltDate: deal.dealMeta.LtDate,
        amendEvent: deal.dealMeta.amendEvent,
        defaultSpread: deal.dealMeta.defaultSpread,
        defaultSpreadComment: deal.dealMeta.defaultSpreadComment,
        ltDealFlag: deal.dealMeta.LtDealFlag,
        amendEventFlag: deal.dealMeta.amendExtendFlag
      });
    }
    this.setState({ isLoading: false });
  }

  fetchAmend() {
    this.setState({
      isRunningRule: true,
      error: ""
    });

    const { deal } = this.state;
    const createRulePromise = get(`/deals/${deal.id}/rules/amendEvent`, {});
    createRulePromise.promise
      .then(response => {
        this.setState({
          isRunningRule: false,
          error: "",
          amendEventFlag: response.data.amendFlag
        });
      })
      .catch(error => {
        this.setState({
          error: error,
          isRunningRule: false
        });
      });

    this.subscribedPromises.push(createRulePromise);
  }

  _handleJobChange(event) {
    let exhibitId = event.target.value;
    this.setState({
      exhibitId: exhibitId
    });
  }

  _handleTypeChange(type) {
    type.length
      ? this.setState({
        typeId: type[0].id,
        trancheTypeValid: type[0].id.length >= 2
      })
      : this.setState({
        typeId: null,
        trancheTypeValid: false
      });
  }

  _handleAmountChange(event) {
    let amount = event.target.value;

    this.setState({
      amount: amount,
      trancheAmount: amount,
      amountValid: true
    });
  }

  _handleAmendRule(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { ltDate, amendEvent, defaultSpread, ltDealFlag, deal } = this.state;

        this.setState({
          error: "",
          isLoading: true
        });

        const createPromise = put(`/dealMeta/${deal.id}`, {
          ltDate,
          defaultSpread,
          amendEvent,
          ltDealFlag
        });

        createPromise.promise
          .then(response => {
            this.setState(
              {
                isLoading: false,
                error: "",
                amendEventFlag: response.data.amendExtendFlag
              },
              () => {
                this.fetchAmend();
              }
            );
          })
          .catch(error => {
            this.setState({
              error: error,
              isLoading: false
            });
          });

        this.subscribedPromises.push(createPromise);
      }
    );
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { deal, typeId, trancheAmount, trancheTypeValid } = this.state;

        if (trancheTypeValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = put(`/tranches/${this.props.trancheId}`, {
            dealId: deal.id,
            typeId: typeId,
            amount: trancheAmount
          });

          createPromise.promise
            .then(response => {
              this.setState(
                {
                  isLoading: false,
                  error: "",
                  showModal: false,
                  tranche: response.data
                },
                () => {
                  this.props.onTrancheUpdated(response.data);
                }
              );
            })
            .catch(error => {
              this.setState({
                error: error,
                isLoading: false
              });
            });
          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  render() {
    const { deal, isLoading } = this.state;

    if (!deal) return <span />;


    return (
      <Fragment>
        <Button
          variant="outline-success"
          onClick={this.toggleModal}
          size="sm"
        >
          Update
        </Button>

        <Modal show={this.state.showModal} size="lg" onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Tranche</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderTrancheEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderTrancheEdit() {
    const {
      error,
      success,
      typeId,
      trancheAmount,
      trancheTypeValid,
      formSubmitted,
      amendEvent,
      isRunningRule,
      types
    } = this.state;

    return (
      <div>
        <Form.Row>
          <Form.Group as={Col} xs="4">
            <Form.Label>Tranche Type</Form.Label>
            <Typeahead
              id="987611"
              labelKey={option => option.name}
              onChange={this.handleTypeChange}
              isInvalid={!trancheTypeValid && formSubmitted}
              options={types}
              placeholder="Search Tranche Type"
              selected={types.filter(type => {
                return type.id === typeId;
              })}
            />
            <Form.Control.Feedback type="invalid">
              Tranche Type is required
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} xs="4">
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type="text"
              defaultValue={trancheAmount}
              onChange={this.handleAmountChange}
            />
          </Form.Group>
          <Form.Group as={Col} xs="4">
            <Form.Label>Amend & Extend Flag</Form.Label>
            <span>
              <Button
                disabled={!amendEvent}
                variant="link"
                className="float-right position-absolute"
                onClick={this.handleAmendRule}
              >
                {isRunningRule && (
                  <Spinner animation="border" size="sm" className="mr-1" />
                )}
                Run Rules
              </Button>
            </span>
            <Form.Control
              type="text"
              defaultValue={this.state.amendEventFlag}
              disabled
            />
          </Form.Group>
          <Form.Group as={Col} xs="12">
            <Alert variant="primary" className="md mb-md">
              <div>
                <Form.Label>Extension Option Flag</Form.Label>
                <br />
                Extension Option Flag = Y if the Extension Option Qty >= 1
                <br />
                Otherwise Extension Option Flag = N
              </div>
              <div>
                <br />
                <Form.Label>Term-Out Option Flag</Form.Label>
                <br />
                Term-Out Option Flag = Y if the Term-Out Option Qty =1
                <br />
                Otherwise Term-Out Option Flag = N
              </div>
              <div>
                <br />
                <Form.Label>Multiple Currencies Flag</Form.Label>
                <br />
                Multiple Currencies Flag = Y if tranche can be drawn using more
                than one currency.
                <br />
                Multipe Currencies Flag = N if tranche can be drawn using only one
                currency.
              </div>
              <div>
                <br />
                <Form.Label>Secured Flag</Form.Label>
                <br />
                Secured Flag = Y If Collateral Type field has a value
                <br />
                Otherwise Secured Flag = N
              </div>
            </Alert>
          </Form.Group>
          {error &&
            (error => {
              if (error.status === 409) {
                return (
                  <Alert variant="warning" className="mt-md mb-md">
                    <h3>Conflict</h3>
                    <p>
                      An account associated with this email address already
                      exists. Please use a different email address.
                    </p>
                  </Alert>
                );
              } else {
                return <ErrorHandler error={error} />;
              }
            })(error)}
          {success && (
            <Alert variant="success">
              <h4>Success!</h4>
              <p>Job Updated Successfully</p>
            </Alert>
          )}
        </Form.Row>
      </div>
    );
  }
}

export default TrancheEdit;
