import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Container, Navbar, Nav } from "react-bootstrap";
import { Helmet } from "react-helmet";

class FactCheckingHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let user = this.props.user || "";
    let title = this.props.title || "";

    return (
      <Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{"Loan Knowledge Mill - " + title}</title>
        </Helmet>
        <Navbar className="border-bottom fixed-top MainHeader" bg="light">
          <Container fluid>
            <Navbar.Brand as={Link} to="/">
              <span className="logo">LOAN KNOWLEDGE MILL</span>
              <sup>
                <small className="ml-1 text-uppercase">
                  <strong>Fact Checking</strong>
                </small>
              </sup>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto" />
              <Nav className="ml-auto">
                <Nav.Link>{user.email}</Nav.Link>
                <Nav.Link as={Link} to="/logout">
                  Logout
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Fragment>
    );
  }
}

FactCheckingHeader.propTypes = {
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired
};

export default FactCheckingHeader;
