import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Alert, Row, Col, Card, Button, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { get, put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";
import ExhibitAnnotation from "../../Extraction/ExhibitAnnotation/ExhibitAnnotation";
import ExhibitEntities from "../../Extraction/ExhibitEntities/ExhibitEntities";
import DealAssignment from "../../Deals/DealAssignment/DealAssignment";

class ExhibitDetails extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleExhibitUpdated = this._handleExhibitUpdated.bind(this);
  }

  componentDidMount() {
    this.fetchDeal();
    this.fetchExhibit();
    this.fetchRaw();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  _handleExhibitUpdated() {
    this.fetchExhibit();
    this.fetchRaw();
  }

  fetchExhibit() {
    this.setState({ exhibitIsLoading: true, exhibitError: "" });
    const { exhibitId } = this.props;
    const getExhibitPromise = get(`/exhibits/${exhibitId}`);
    getExhibitPromise.promise
      .then((response) => {
        this.setState({
          exhibitIsLoading: false,
          exhibitError: "",
          exhibit: response.data
        });
      })
      .catch((error) => {
        this.setState({ exhibitError: error, exhibitIsLoading: false });
      });
    this.subscribedPromises.push(getExhibitPromise);
  }

  fetchDeal() {
    this.setState({ isLoading: true, error: "" });
    const { dealId } = this.props;
    const getDealPromise = get(`/deals/${dealId}`);
    getDealPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deal: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getDealPromise);
  }

  fetchRaw() {
    this.setState({ rawIsLoading: true, rawError: "" });
    const { exhibitId } = this.props;

    const fetchRawPromise = get(`/exhibits/${exhibitId}/document-raw`, {});
    fetchRawPromise.promise
      .then((response) => {
        this.setState({
          rawIsLoading: false,
          rawError: "",
          raw: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({ rawError: error, rawIsLoading: false });
      });

    this.subscribedPromises.push(fetchRawPromise);
  }

  extractExhibit(exhibitId) {
    this.setState({
      rawIsLoading: true,
      rawError: ""
    });
    const extractExhibitsPromise = put(`/exhibits/${exhibitId}/document-raw`);
    extractExhibitsPromise.promise
      .then((response) => {
        return new Promise((resolve) =>
          setTimeout(() => resolve(response), 300)
        );
      })
      .then((response) => {
        this.setState(
          {
            rawIsLoading: false,
            rawError: ""
          },
          () => this.fetchRaw()
        );
      })
      .catch((error) => {
        !error.isCanceled &&
          this.setState({
            rawError: error,
            rawIsLoading: false
          });
      });

    this.subscribedPromises.push(extractExhibitsPromise);
  }

  render() {
    const {
      isLoading,
      exhibitIsLoading,
      error,
      exhibitError,
      deal,
      exhibit
    } = this.state;

    if (isLoading) return <Loader />;
    if (exhibitIsLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (exhibitError) return <ErrorHandler error={exhibitError} />;
    if (!deal || !exhibit) return <span />;
    const company = deal.company;

    return (
      <div className="ExhibitDetails WideView">
        <Row className="WideCanvas">
          <Col xs={12} md={2} className="WideLeft">
            <div className="mt-3 mb-3">
              <h4>Company</h4>
              <p>
                <Link to={`/companies/${company.id}`}>
                  {company.companyName}
                </Link>
              </p>

              <h4>Deal Signing Date</h4>
              <p>
                <Link to={`/extraction/${deal.id}`}>
                  {moment(deal.signingDate).format("LL")}
                </Link>
              </p>

              <h4>Deal Amend Id </h4>
              <p>{deal.dealAmendId}</p>

              <h4>Deal Type</h4>
              <p>
                {deal.dealType ? (
                  <span className="badge badge-warning">{deal.dealType}</span>
                ) : (
                  <span className="text-muted">--</span>
                )}
              </p>

              <p className="text-muted">
                {deal.secFilingLink ? (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={deal.secFilingLink}
                  >
                    <small>See Deal in Edgar </small>
                    <span className="material-icons md-16">open_in_new</span>
                  </a>
                ) : (
                  <strong>No Link Found</strong>
                )}
              </p>

              <hr />
              {this.renderExhibits()}
            </div>
          </Col>
          <Col xs={12} sm={8} className="WideCenter">
            <div className="mt-3 mb-3">
              <span className="float-right mr-1">
                <Button
                  size="sm"
                  variant="link"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={exhibit.documentUrl}
                >
                  See exhibit in Edgar
                  <span className="material-icons md-18">open_in_new</span>
                </Button>{" "}
              </span>
              <h2>Exhibit, {exhibit.exhibitName}</h2>
              {this.renderDocument()}
            </div>
          </Col>
          <Col xs={12} sm={2} className="WideRight">
            <div className="mt-3 mb-3">
              <h4>Assignee</h4>
              <DealAssignment deal={deal} highlight={true} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  renderDocument() {
    const { raw, rawIsLoading, rawError } = this.state;

    if (rawIsLoading) return <Loader />;
    if (rawError) return <ErrorHandler error={rawError} />;
    if (!raw || !raw.documentRaw)
      return (
        <Alert variant="info" className="text-center">
          <p>The exhibit content has not been extracted yet.</p>
          <Button
            variant="primary"
            className=""
            size="sm"
            onClick={() => {
              this.extractExhibit(raw.id);
            }}
          >
            Extract Now
          </Button>
        </Alert>
      );
    return (
      <Tabs defaultActiveKey="raw">
        <Tab eventKey="raw" title="Raw Document">
          <div className="mt-3 mb-3">
            <Fragment>
              <p className="text-right">
                <Button
                  variant="outline-primary"
                  size="sm"
                  onClick={() => {
                    this.extractExhibit(raw.id);
                  }}
                >
                  <span className="material-icons mr-1">refresh</span>Rerun
                  extraction of raw document
                </Button>
              </p>
              <Card className="mt-3 mb-3">
                <Card.Body className="document-raw">
                  <div dangerouslySetInnerHTML={{ __html: raw.documentRaw }} />
                </Card.Body>
              </Card>
            </Fragment>
          </div>
        </Tab>

        <Tab eventKey="annotation" title="Document Annotation">
          <div className="mt-3 mb-3">
            <ExhibitAnnotation exhibit={raw} />
          </div>
        </Tab>
        <Tab eventKey="entities" title="Document Entities">
          <div className="mt-3 mb-3">
            <ExhibitEntities exhibit={raw} />
          </div>
        </Tab>
      </Tabs>
    );
  }

  renderExhibits() {
    const { deal, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deal) return <span />;

    const exhibits = deal.exhibit;
    return (
      <Fragment>
        <h3 className="mb-3">Exhibits</h3>
        <ul className="list-unstyled">
          {exhibits.map((exhibit) => (
            <p key={exhibit.id}>
              <Link
                to={`/extraction/${deal.id}/exhibits/${
                  exhibit.id
                }/document-raw`}
              >
                {exhibit.exhibitName}
              </Link>
            </p>
          ))}
        </ul>
      </Fragment>
    );
  }
}

ExhibitDetails.propTypes = {
  dealId: PropTypes.string.isRequired,
  exhibitId: PropTypes.string.isRequired
};

export default ExhibitDetails;
