import React, { Component, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Nav } from "react-bootstrap";

import { get } from "../../../utils/BeeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class ContentList extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchContents();
    this.fetchCategory();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchCategory() {
    const fetchCategoryPromise = get("/categories/" + this.props.categoryId);
    fetchCategoryPromise.promise
      .then((response) => {
        this.setState({ category: response.data });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({});
      });

    this.subscribedPromises.push(fetchCategoryPromise);
  }

  fetchContents() {
    this.setState({ isLoading: true, error: null });
    const { categoryId, onContentsUpdated } = this.props;

    const fetchContentPromise = get("/contents", {
      params: { category: categoryId, type: 1 }
    });

    fetchContentPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: null,
            contents: response.data.reverse()
          },
          () => onContentsUpdated && onContentsUpdated(this.state.contents)
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: null });
      });

    this.subscribedPromises.push(fetchContentPromise);
  }

  render() {
    const { contents, isLoading, error, category } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    if (!contents || !category) return <span />;

    let domainContent = this.props.contentId
      ? contents.find((content) => content.contentId === this.props.contentId)
      : contents[0];

    // contents.splice(
    //   contents.findIndex(
    //     (content) => content.contentId === domainContent.contentId
    //   ),
    //   1
    // );

    return (
      <Fragment>
        <h2>{category.categoryName}</h2>
        <hr />
        <Row>
          <Col xs={9}>
            {domainContent && <Content content={domainContent} />}
          </Col>
          <Col xs={3}>
            <div className="well well-secondary">
              <h4>Sub-Component Items</h4>
              <hr className="mt-1 mb-1" />
              <Nav variant="pills" className="flex-column mb-3">
                {contents.map((content) => (
                  <Nav.Item key={content.contentId}>
                    <Nav.Link
                      as={NavLink}
                      activeClassName="active"
                      to={`/knowledge/${category.categoryId}/contents/${
                        content.contentId
                      }`}
                    >
                      <h5 className="mb-0">{content.title}</h5>
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            </div>
          </Col>
        </Row>
      </Fragment>
    );
  }
}

const Content = (props) => {
  const content = props.content;

  return (
    <div className="Content">
      <h3>{content.title}</h3>
      <p className="text-muted">{content.description}</p>
      <div
        className="body"
        dangerouslySetInnerHTML={{ __html: content.contentBody }}
      />
    </div>
  );
};

export default ContentList;
