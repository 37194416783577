import React, { Component, Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Alert, Modal, Col, Button, Form } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { post } from "../../../../utils/DeApi";
import Loader from "../../../Loader/Loader";
import ErrorHandler from "../../../ErrorHandler/ErrorHandler";

class TrancheCreate extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
      amountValid: false,
      success: false
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleJobChange = this._handleJobChange.bind(this);
    this.handleTypeChange = this._handleTypeChange.bind(this);
    this.handleAmountChange = this._handleAmountChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);
  }

  _toggleModal() {
    this.setState({
      showModal: !this.state.showModal
    });
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  componentDidMount() {
    const { types } = this.props;
    this.setState({
      types
    });
  }

  _handleJobChange(event) {
    let exhibitId = event.target.value;
    this.setState({
      exhibitId: exhibitId
    });
  }

  _handleTypeChange(type) {
    type.length
      ? this.setState({
          typeId: type[0].id,
          trancheTypeValid: type[0].id.length >= 2
        })
      : this.setState({
          typeId: null,
          trancheTypeValid: false
        });
  }

  _handleAmountChange(event) {
    let amount = event.target.value;

    this.setState({
      amount: amount,
      amountValid: true
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      (state, props) => {
        const { exhibitId, typeId, amount, trancheTypeValid } = this.state;
        const { deal } = this.props;

        if (trancheTypeValid) {
          this.setState({
            error: "",
            isLoading: true
          });

          const createPromise = post("/tranches", {
            dealId: deal.id,
            exhibitId: exhibitId,
            typeId: typeId,
            amount: amount
          });

          createPromise.promise
            .then((response) => {
              this.setState(
                {
                  isLoading: false,
                  error: "",
                  showModal: false,
                  tranche: response.data
                },
                () => {
                  this.props.onTranceCreated(this.state.tranche);
                }
              );
            })
            .catch((error) => {
              !error.isCanceled &&
                this.setState({ error: error, isLoading: false });
            });

          this.subscribedPromises.push(createPromise);
        }
      }
    );
  }

  render() {
    const { isLoading, types } = this.state;

    if (!types) return <span />;
    return (
      <Fragment>
        <Button variant="primary" onClick={this.toggleModal} size="sm">
          New Tranche
        </Button>

        <Modal show={this.state.showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Create Tranche</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderTrancheCreate()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Create
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderTrancheCreate() {
    const {
      error,
      success,
      isLoading,
      formSubmitted,
      trancheTypeValid
    } = this.state;

    const { deal, types } = this.props;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;

    return (
      <Form.Row>
        <Form.Group as={Col} xs="12">
          <div>
            Deal signing date as of {moment(deal.signingDate).format("LL")}
          </div>
        </Form.Group>

        <Form.Group as={Col} xs="12">
          <Form.Label>Tranche Type</Form.Label>
          <Typeahead
            id="1234"
            labelKey={(option) => option.name}
            onChange={this.handleTypeChange}
            isInvalid={!trancheTypeValid && formSubmitted}
            options={types}
            placeholder="Search Tranche Type..."
          />
          <Form.Control.Feedback type="invalid">
            Tranche Type is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} xs="12">
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter trancheamount"
            onChange={this.handleAmountChange}
          />
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 409) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this email address already
                    exists. Please use a different email address.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Job Created Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

TrancheCreate.propTypes = {
  onTranceCreated: PropTypes.func.isRequired
};

export default TrancheCreate;
