import React, { Component, Fragment } from "react";
import moment from "moment";
import { Table, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import { get } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

import DealEdit from "../../Deals/DealEdit/DealEdit";
import DealCreate from "../../Deals/DealCreate/DealCreate";
import DealDelete from "../../Deals/DealDelete/DealDelete";
import DealAssignment from "../../Deals/DealAssignment/DealAssignment";

class CompanyDeals extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};

    this.handleDealUpdated = this._handleDealUpdated.bind(this);
    this.handleDealCreated = this._handleDealCreated.bind(this);
    this.handleDealDeleted = this._handleDealDeleted.bind(this);
  }

  _handleDealCreated(deal) {
    const { deals } = this.state;
    let mutableList = [...deals];
    mutableList.push(deal);

    this.setState({
      deals: mutableList
    });
  }

  _handleDealUpdated(deal) {
    const { deals } = this.state;
    let mutableList = [...deals];
    let index = deals.findIndex((item) => {
      return item.id === deal.id;
    });
    mutableList[index] = deal;

    this.setState({
      deals: mutableList
    });
  }

  _handleDealDeleted(dealId) {
    let { deals } = this.state;
    deals = deals.filter((deal) => deal.id !== dealId);

    this.setState({
      deals
    });
  }

  componentDidMount() {
    this.fetchDeals();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchDeals() {
    const { company } = this.props;

    this.setState({ isLoading: true, error: "" });
    const getDealsPromise = get(`/companies/${company.id}/deals`, {
      limit: 50
    });
    getDealsPromise.promise
      .then((response) => {
        this.setState({
          isLoading: false,
          error: "",
          deals: response.data
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(getDealsPromise);
  }

  renderDeals() {
    const { deals, isLoading, error } = this.state;

    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!deals) return <span />;
    if (!deals.length)
      return (
        <Alert variant="info">There are currently no deals to show.</Alert>
      );

    return (
      <Fragment>
        <Table responsive size="sm">
          <thead>
            <tr>
              <th>Amendment ID</th>
              <th>Deal Type</th>
              <th>Signing Date</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Processing</th>
              <th>Assignee</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {deals.map((deal) => {
              return (
                <tr key={deal.id}>
                  <td className="text-muted">{deal.dealAmendId}</td>
                  <td>
                    {deal.dealType ? (
                      <span className="badge badge-warning badge-pill">
                        {deal.dealType}
                      </span>
                    ) : (
                      <span className="text-muted">--</span>
                    )}
                  </td>
                  <td>{moment(deal.signingDate).format("MM/DD/YYYY")}</td>
                  <td>{moment(deal.createdAt).format("MM/DD/YYYY")}</td>
                  <td>{moment(deal.updatedAt).format("MM/DD/YYYY")}</td>
                  <td>
                    {deal.stationStatus.extraction ? (
                      <Link to={`/extraction/${deal.id}`}>
                        <span className="badge badge-success badge-pill">
                          Resume
                        </span>
                      </Link>
                    ) : (
                      <Link to={`/extraction/${deal.id}`}>
                        <span className="badge badge-dark badge-pill">
                          Start
                        </span>
                      </Link>
                    )}
                  </td>
                  <td>
                    <DealAssignment deal={deal} />
                  </td>
                  <td className="text-right">
                    <DealEdit
                      deal={deal}
                      onDealUpdated={this.handleDealUpdated}
                    />{" "}
                    <DealDelete
                      deal={deal}
                      onDealDeleted={this.handleDealDeleted}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Fragment>
    );
  }

  render() {
    const { company } = this.props;

    return (
      <div className="mt-3 mb-3">
        <div className="pb-2">
          <span className="float-right">
            <DealCreate
              company={company}
              onDealCreated={this.handleDealCreated}
            />
          </span>
          <h3>Deals</h3>
        </div>
        {this.renderDeals()}
      </div>
    );
  }
}

CompanyDeals.propTypes = {};

export default CompanyDeals;
