import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Modal, Button, Form } from "react-bootstrap";

import { put } from "../../../utils/DeApi";

import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class EntityEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {
    };

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleNameChange = this._handleNameChange.bind(this);
    this.handleDocumentSectionChange = this._handleDocumentSectionChange.bind(this);
    this.handlebluesSectionChange = this._handlebluesSectionChange.bind(this);
    this.handleBluesCommentChange = this._handleBluesCommentChange.bind(this);

    this.toggleModal = this._toggleModal.bind(this);
  }

  componentDidMount() {
    const { entity } = this.props;
    if (entity) {
      this.setState({
        entity,
        entityName: entity.entityName,
        entityNameValid: true,
        documentSection: entity.documentSection,
        documentSectionValid: true,
        bluesSection: entity.bluesSection,
        bluesSectionValid: true,
        bluesComment: entity.bluesComment
      })
    }
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleNameChange(event) {
    let entityName = event.target.value;
    this.setState({
      entityName,
      entityNameValid: entityName.length > 2 ? true : false
    });
  }

  _handleDocumentSectionChange(event) {
    let documentSection = event.target.value;
    this.setState({
      documentSection,
      documentSectionValid: documentSection.length > 2 ? true : false
    });
  }

  _handlebluesSectionChange(event) {
    let bluesSection = event.target.value;
    this.setState({
      bluesSection,
      bluesSectionValid: bluesSection.length > 2 ? true : false
    });
  }

  _handleBluesCommentChange(event) {
    let bluesComment = event.target.value;
    this.setState({
      bluesComment
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    this.setState(
      {
        formSubmitted: true
      },
      () => {
        this.editEntity();
      }
    );
  }

  editEntity() {
    const {
      entity,
      entityName,
      entityNameValid,
      bluesSection,
      bluesSectionValid,
      documentSection,
      documentSectionValid,
      bluesComment
    } = this.state;

    const { onEntityEdited } = this.props;

    if (entityNameValid && bluesSectionValid && documentSectionValid) {
      this.setState({ error: "", isLoading: true });
      const editEntityPromise = put(`/credit-agreement-entity/${entity.id}`, {
        entityName,
        documentSection,
        bluesSection,
        bluesComment
      });
      editEntityPromise.promise
        .then((response) => {
          this.setState(
            {
              isLoading: false,
              error: "",
              showModal: false
            },
            () => {
              onEntityEdited(response.data);
            }
          );
        })
        .catch((error) => {
          !error.isCanceled && this.setState({ error: error, isLoading: false });
        });

      this.subscribedPromises.push(editEntityPromise);
    }
  }

  render() {
    const { isLoading, showModal } = this.state;
    return (
      <Fragment>
        <Button size="sm" variant="outline-success" onClick={this.toggleModal}>
          Update
        </Button>

        <Modal show={showModal} onHide={this.toggleModal}>
          <Modal.Header closeButton>
            <Modal.Title>Update Entity</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderEntityEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderEntityEdit() {
    const {
      error,
      entityName,
      entityNameValid,
      formSubmitted,
      isLoading,
      documentSection,
      documentSectionValid,
      bluesSection,
      bluesSectionValid,
      bluesComment
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            defaultValue={entityName}
            onChange={this.handleNameChange}
            isInvalid={!entityNameValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Name is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Document Section</Form.Label>
          <Form.Control
            type="text"
            defaultValue={documentSection}
            onChange={this.handleDocumentSectionChange}
            isInvalid={!documentSectionValid && formSubmitted}
            placeholder="Enter document section"
          />
          <Form.Control.Feedback type="invalid">
            Document Section is required
          </Form.Control.Feedback>
        </Form.Group><Form.Group>
          <Form.Label>Blues Section</Form.Label>
          <Form.Control
            type="text"
            defaultValue={bluesSection}
            onChange={this.handlebluesSectionChange}
            isInvalid={!bluesSectionValid && formSubmitted}
            placeholder="Enter Blues section"
          />
          <Form.Control.Feedback type="invalid">
            Blues Section is required
          </Form.Control.Feedback>
        </Form.Group><Form.Group>
          <Form.Label>Blues Commment</Form.Label>
          <Form.Control
            type="text"
            defaultValue={bluesComment}
            onChange={this.handleBluesCommentChange}
            placeholder="Enter Blues comment"
          />
        </Form.Group>
        {error &&
          ((error) => {
            return <ErrorHandler error={error} />;
          })(error)}
      </Form>
    );
  }
}
EntityEdit.propTypes = {
  onEntityEdited: PropTypes.func.isRequired
};

export default EntityEdit;
