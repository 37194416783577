import React, { Component, Fragment } from "react";
import { Route, Switch, NavLink } from "react-router-dom";
import { Container, Row, Col, Collapse } from "react-bootstrap";
import { get } from "../../utils/BeeApi";

import Header from "../App/Header/Header";
import Loader from "../Loader/Loader";
import ErrorHandler from "../ErrorHandler/ErrorHandler";
import ContentList from "./ContentList/ContentList";

const DOMAIN_GROUPS = ["5fd16cfb8e5bd", "5fd16d5cc4b86", "5fde989c78350"];

class Knowledge extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = { opened: [] };
  }

  toggleCollapse(collapsible) {
    const { opened } = this.state;
    if (opened.includes(collapsible)) {
      opened.splice(opened.indexOf(collapsible), 1);
      this.setState({ opened: opened });
    } else {
      opened.push(collapsible);
      this.setState({ opened: opened });
    }
  }

  componentDidMount() {
    this.fetchGroups();
    this.fetchCategories();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  fetchGroups() {
    this.setState({ isLoading: true, error: null });

    const fetchGroupsPromise = get("/groups", {});
    fetchGroupsPromise.promise
      .then((response) => {
        let groups = response.data.filter((group) =>
          DOMAIN_GROUPS.includes(group.groupId)
        );
        this.setState({ isLoading: false, error: null, groups: groups });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(fetchGroupsPromise);
  }

  fetchCategories() {
    this.setState({ isLoading: true, error: null });

    const fetchCategoriesPromise = get("/categories", {});
    fetchCategoriesPromise.promise
      .then((response) => {
        let categories = response.data.filter((category) =>
          category.groups.find((group) => DOMAIN_GROUPS.includes(group.groupId))
        );
        this.setState({
          isLoading: false,
          error: null,
          categories: categories
        });
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(fetchCategoriesPromise);
  }

  render() {
    return (
      <div className="Main">
        <Header user={this.props.user} title={"Knowledge"} />
        <div className="MainContent mb-5">
          <Container>
            <Row className="mt-3 mb-3">
              <Col xs={12} sm={4}>
                <div className="mt-3 mb-3"> {this.renderCategories()}</div>
              </Col>
              <Col xs={12} sm={8}>
                <div className="mt-3 mb-3">
                  <Switch>
                    <Route
                      exact
                      path="/knowledge"
                      component={(props) => <div />}
                    />
                    <Route
                      exact
                      path="/knowledge/:categoryId"
                      component={(props) => (
                        <ContentList
                          categoryId={props.match.params.categoryId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/knowledge/:categoryId/contents"
                      component={(props) => (
                        <ContentList
                          categoryId={props.match.params.categoryId}
                        />
                      )}
                    />
                    <Route
                      exact
                      path="/knowledge/:categoryId/contents/:contentId"
                      component={(props) => (
                        <ContentList
                          categoryId={props.match.params.categoryId}
                          contentId={props.match.params.contentId}
                        />
                      )}
                    />
                  </Switch>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }

  renderCategories() {
    const { categories, opened, groups, isLoading, error } = this.state;
    if (isLoading) return <Loader />;
    if (error) return <ErrorHandler error={error} />;
    if (!categories || !groups) return <span />;

    const groupedCategories = groups.map((group) => {
      return {
        group: group,
        categories: categories.filter((category) =>
          category.groups.find((item) => item.groupId === group.groupId)
        )
      };
    });

    return (
      <div className="well">
        <h3>Components</h3>
        <hr className="mt-3 mb-3" />
        {groupedCategories.map((grouped, index) => (
          <Fragment key={index}>
            <h4 onClick={() => this.toggleCollapse(grouped.group.groupId)}>
              <span className="material-icons float-right">
                {opened.includes(grouped.group.groupId)
                  ? "expand_less"
                  : "expand_more"}
              </span>
              {grouped.group.groupName}{" "}
              <small>{grouped.group.groupDescription}</small>
            </h4>
            <Collapse in={opened.includes(grouped.group.groupId)}>
              <div className="pl-2">
                {grouped.categories.map((category) => (
                  <p key={category.categoryId}>
                    <NavLink
                      activeClassName="active"
                      to={`/knowledge/${category.categoryId}`}
                    >
                      {category.categoryName}
                    </NavLink>
                  </p>
                ))}
              </div>
            </Collapse>
          </Fragment>
        ))}
      </div>
    );
  }
}

export default Knowledge;
