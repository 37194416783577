import React, { Component, Fragment } from "react";
import "./DealAssignment.scss";
import { Button, Spinner } from "react-bootstrap";

import { post, put, get } from "../../../utils/DeApi";
import { isLoggedIn } from "../../../utils/BeeApi";

import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class DealAssignment extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];
    this.state = {};
  }

  componentDidMount() {
    this.fetchActivities();
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function(promise) {
      promise.cancel();
    });
  }

  handleCheckout() {
    const { deal, onAssignmentUpdated } = this.props;
    const { dealActivities } = this.state;

    this.setState({ isLoading: true, error: null });
    const createPromise = post("/deal-activities", {
      dealId: deal.id
    });
    createPromise.promise
      .then((response) => {
        this.setState(
          {
            dealActivities: [response.data].concat(dealActivities),
            isLoading: false,
            error: null
          },
          () =>
            onAssignmentUpdated &&
            onAssignmentUpdated(this.state.dealActivities)
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: null });
      });
    this.subscribedPromises.push(createPromise);
  }

  handleCheckin(dealActivity) {
    const { onAssignmentUpdated } = this.props;
    const { dealActivities } = this.state;
    this.setState({ isLoading: true, error: null });

    const createPromise = put(`/deal-activities/${dealActivity.id}`);
    createPromise.promise
      .then((response) => {
        this.setState(
          {
            dealActivities: dealActivities.map(
              (activity) =>
                (activity.id = dealActivity.id ? response.data : activity)
            ),
            isLoading: false,
            error: null
          },
          () => () =>
            onAssignmentUpdated &&
            onAssignmentUpdated(this.state.dealActivities)
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: null });
      });
    this.subscribedPromises.push(createPromise);
  }

  fetchActivities() {
    const { deal, onAssignmentUpdated } = this.props;
    this.setState({ isLoading: true, error: null });
    const activitiesPromise = get("/deal-activities", {
      params: { deals: [deal.id] }
    });
    activitiesPromise.promise
      .then((response) => {
        this.setState(
          {
            dealActivities: response.data,
            isLoading: false,
            error: null
          },
          () =>
            onAssignmentUpdated &&
            onAssignmentUpdated(this.state.dealActivities)
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });
    this.subscribedPromises.push(activitiesPromise);
  }

  render() {
    const { dealActivities, error, isLoading } = this.state;
    const { highlight } = this.props;
    const subscriber = isLoggedIn();

    if (error) return <ErrorHandler error={error} />;
    if (isLoading) return <Spinner size="xs" />;
    if (!dealActivities) return <span />;

    let recentActivity = dealActivities[0];

    return (
      <div className="DealAssignment">
        {recentActivity && !recentActivity.checkedinAt ? (
          <Fragment>
            <span className="material-icons md-18 text-danger">lock</span>{" "}
            <span
              className={`text-muted mr-2 ${highlight &&
                subscriber.sub !== recentActivity.checkedoutBy &&
                "activity-warning"}`}
            >
              {recentActivity.username || recentActivity.checkedoutBy}
            </span>
            <Button
              variant="outline-secondary"
              size="xs"
              onClick={() => this.handleCheckin(recentActivity)}
            >
              Release
            </Button>
          </Fragment>
        ) : (
          <Fragment>
            <span className="material-icons md-18">lock_open</span>{" "}
            <Button
              variant="outline-primary"
              size="xs"
              onClick={() => this.handleCheckout()}
            >
              Claim
            </Button>
          </Fragment>
        )}
      </div>
    );
  }
}

export default DealAssignment;
