import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Alert, Col, Button, Form, Modal } from "react-bootstrap";
import { put } from "../../../utils/DeApi";
import Loader from "../../Loader/Loader";
import ErrorHandler from "../../ErrorHandler/ErrorHandler";

class CompanyEdit extends Component {
  constructor(props) {
    super(props);
    this.subscribedPromises = [];

    this.handleSubmit = this._handleSubmit.bind(this);
    this.handleCikChange = this._handleCikChange.bind(this);
    this.handleNameChange = this._handleNameChange.bind(this);
    this.toggleModal = this._toggleModal.bind(this);

    let company = props.company;
    let companyName = company.companyName || "";
    let companyCIK = company.cik || "";
    this.state = {
      name: companyName,
      nameValid: companyName.length > 2 ? true : false,
      cik: companyCIK,
      cikValid: companyCIK.length > 2 ? true : false
    };
  }

  componentWillUnmount() {
    this.subscribedPromises.forEach(function (promise) {
      promise.cancel();
    });
  }

  _toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  _handleNameChange(event) {
    let name = event.target.value;
    this.setState({
      name: name,
      nameValid: name.length > 2 ? true : false
    });
  }

  _handleCikChange(event) {
    let cik = event.target.value;
    this.setState({
      cik: cik,
      cikValid: cik.length > 2 ? true : false
    });
  }

  _handleSubmit(event) {
    event.preventDefault();
    const { company, onCompanyUpdated } = this.props;
    const { name, nameValid, cik, cikValid } = this.state;

    if (!nameValid || !cikValid) return;
    this.setState({ isLoading: true, error: false });
    const updateCompanyPromise = put(`/companies/${company.id}`, {
      name: name,
      cik: cik
    });

    updateCompanyPromise.promise
      .then((response) => {
        this.setState(
          {
            isLoading: false,
            error: "",
            showModal: false,
            company: response.data
          },
          () => {
            onCompanyUpdated(response.data);
          }
        );
      })
      .catch((error) => {
        !error.isCanceled && this.setState({ error: error, isLoading: false });
      });

    this.subscribedPromises.push(updateCompanyPromise);
  }

  render() {
    const { isLoading, showModal } = this.state;
    const { label } = this.props;
    return (
      <Fragment>
        <Button variant="outline-success" size="sm" onClick={this.toggleModal}>
          {label || "Update"}
        </Button>

        <Modal show={showModal} onHide={this.toggleModal} backdrop={"static"}>
          <Modal.Header closeButton>
            <Modal.Title>Manage Company</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.renderCompanyEdit()}</Modal.Body>
          <Modal.Footer>
            <Button variant="link" onClick={this.toggleModal}>
              Close
            </Button>
            <Button
              variant="primary"
              disabled={isLoading}
              onClick={this.handleSubmit}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }

  renderCompanyEdit() {
    const {
      error,
      success,
      name,
      cik,
      cikValid,
      nameValid,
      formSubmitted,
      isLoading
    } = this.state;

    if (isLoading) return <Loader />;

    return (
      <Form.Row>
        <Form.Group as={Col} md="12">
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={this.handleNameChange}
            isInvalid={!nameValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            Name is required
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="12">
          <Form.Label>CIK</Form.Label>
          <Form.Control
            type="text"
            value={cik}
            onChange={this.handleCikChange}
            isInvalid={!cikValid && formSubmitted}
            placeholder="Enter name"
            autoFocus
          />
          <Form.Control.Feedback type="invalid">
            CIK is required
          </Form.Control.Feedback>
        </Form.Group>
        {error &&
          ((error) => {
            if (error.status === 400) {
              return (
                <Alert variant="warning" className="mt-md mb-md">
                  <h3>Conflict</h3>
                  <p>
                    An account associated with this CIK already exists. Please
                    use a different CIK.
                  </p>
                </Alert>
              );
            } else {
              return <ErrorHandler error={error} />;
            }
          })(error)}
        {success && (
          <Alert variant="success">
            <h4>Success!</h4>
            <p>Company updated Successfully</p>
          </Alert>
        )}
      </Form.Row>
    );
  }
}

CompanyEdit.propTypes = {
  company: PropTypes.object.isRequired,
  onCompanyUpdated: PropTypes.func.isRequired
};

export default CompanyEdit;
