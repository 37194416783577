const BlockStylesUtils = {
  getBlockStyle(block) {
    let blockAlignment;
    switch (block.getData().get("textAlignment")) {
      case "RIGHT":
        blockAlignment = `text-right`;
        break;
      case "CENTER":
        blockAlignment = `text-center`;
        break;
      case "LEFT":
        blockAlignment = `text-left`;
        break;
      case "JUSTIFY":
        blockAlignment = `text-justify`;
        break;
      default:
        blockAlignment = null;
    }

    let blockTypeStyle;
    switch (block.getType()) {
      case "blockquote":
        blockTypeStyle = "RichEditor-blockquote";
        break;
      case "code-block":
        blockTypeStyle = "RichEditor-code-block";
        break;
      default:
        blockTypeStyle = null;
    }

    return `${blockAlignment} ${blockTypeStyle}`;
  }
};
export default BlockStylesUtils;
